import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";

import config from "../config";
import { Functional } from "../types";

const CookiePolicyScreen: Functional<RouteComponentProps> = () => {
  useEffect(() => {
    window.location.href = config.cookiePolicy;
  }, [])

  return (
    <></>
  );
};

export default CookiePolicyScreen;
