import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import { Link } from "@reach/router";
import React from "react";

import {
  OpportunityCard,
  OpportunityDetail,
} from "../../components/Opportunity";
import { FadeInOut } from "../../components/Transition";
import { OpportunityFragment, OpportunitySummaryFragment } from "../../graphql";

interface OpportunityDetailScreenProps {
  loading: boolean;
  opportunity?: OpportunityFragment;
  related?: OpportunitySummaryFragment[];
  trackingEnabled?: boolean;
}

const OpportunityDetailScreen: React.FC<OpportunityDetailScreenProps> = ({
  loading,
  opportunity,
  related,
  trackingEnabled = true,
}) => {
  const bgImage = opportunity?.industryImage?.url;
  const bg = bgImage ? `url('${bgImage}')` : "brand.gradient";

  return (
    <FadeInOut>
      {loading ? (
        <Center h="100%">
          <Spinner size="xl" color="brand.pink.500" />
        </Center>
      ) : (
        opportunity && (
          <Flex direction="column" mb={16}>
            <Box
              position={{ base: "relative", md: "absolute" }}
              w="100%"
              h={{ base: "270px", md: "530px" }}
              bg={bg}
              backgroundPosition="center"
              backgroundSize="cover"
              backgroundRepeat="no-repeat"
            />
            <Container maxW="900px" mt={{ md: 8 }} px={{ base: 0, md: 4 }}>
              <OpportunityDetail
                opportunity={opportunity}
                trackingEnabled={trackingEnabled}
              />
            </Container>
            {related?.length ? (
              <Container maxW="xl">
                <Divider my={8} border="2px solid" />
                <Heading as="h3" size="xl" mb={8}>
                  Other roles
                </Heading>
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing="50px"
                  position="relative"
                  mb={8}
                >
                  {related.map((item) => (
                    <OpportunityCard key={item.id} opportunity={item} />
                  ))}
                </SimpleGrid>
                <Flex justifyContent="center">
                  <Button
                    as={Link}
                    variant="outline"
                    colorScheme="black"
                    minW="350px"
                    maxW="100%"
                    to="/opportunities"
                  >
                    See more
                  </Button>
                </Flex>
              </Container>
            ) : null}
          </Flex>
        )
      )}
    </FadeInOut>
  );
};

export default OpportunityDetailScreen;
