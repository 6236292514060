import {
  Redirect,
  RouteComponentProps,
  useLocation,
  useNavigate,
} from "@reach/router";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import { FadeInOut } from "../../components/Transition";
import {
  CreateOpportunityInput,
  OpportunityType,
  useCreateOpportunityMutation,
  useOpportunityBoardLazyQuery,
} from "../../graphql";
import { CompanyDetailsFormInput } from "../../presentation/Opportunity/CompanyDetailsForm";
import CreateBase from "../../presentation/Opportunity/CreateBase";
import { OpportunityDetailsFormInput } from "../../presentation/Opportunity/OpportunityDetailsForm";
import {
  CompanyDetailsScreen,
  CreateOpportunitySuccessScreen,
  OpportunityDetailsScreen,
  ReviewOpportunityScreen,
} from "../../screens/Opportunity";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

const defaultState = {
  policy: false,
  applicationUri: "",
  closesAt: new Date(),
  company: "",
  description: "",
  email: "",
  industries: [],
  location: "",
  salary: "",
  title: "",
  type: OpportunityType.FULL_TIME,
};

const CreateOpportunityContainer: Functional<RouteComponentProps> = () => {
  const { pathname, state }: { pathname: string; state: any } = useLocation();
  const navigate = useNavigate();
  const [isCompleted, setCompleted] = useState(false);
  const [fields, { data }] = useOpportunityBoardLazyQuery();
  const [create] = useCreateOpportunityMutation();

  const form = useMemo(() => state?.form || {}, [state]);

  useEffect(() => {
    fields();
  }, [fields]);

  const [activeStep, ValidationInput] = useMemo(() => {
    switch (pathname) {
      default:
        return [0, CompanyDetailsFormInput];
      case "/opportunity/create/details":
        return [1, OpportunityDetailsFormInput];
      case "/opportunity/create/review":
        return [2, null];
      case "/opportunity/create/success":
        return [3, null];
    }
  }, [pathname]);

  const getLink = (step: number) => {
    switch (step) {
      case 0:
      default:
        return "/opportunity/create";
      case 1:
        return "/opportunity/create/details";
      case 2:
        return "/opportunity/create/review";
      case 3:
        return "/opportunity/create/success";
    }
  };

  const formik = useFormik<CreateOpportunityInput>({
    initialValues: Object.keys(form).length ? form : defaultState,
    validate: ValidationInput
      ? validator(ValidationInput, { excludeExtraneousValues: true })
      : () => {},
    onSubmit: () => {
      if (activeStep === 2) {
        create({
          variables: {
            opportunity: formik.values,
          },
        })
          .then(() => {
            setCompleted(true);
            formik.resetForm();
            // Step 3 is an ephemeral route and doesn't actually exist in the router
            goTo(3);
          })
          .catch((error) => {
            if (error.message === "BAD_USER_INPUT") {
              const errorBucket = (error.graphQLErrors[0].extensions
                ?.response || {}) as Partial<
                Record<keyof CreateOpportunityInput, string>
              >;
              formik.setErrors(errorBucket);

              // Attempt to find the page of the wizard that has the first error.
              if (Object.keys(errorBucket).length) {
                const first = (Object.keys(errorBucket) as Array<
                  keyof CreateOpportunityInput
                >)[0];
                if (
                  Object.keys(new CompanyDetailsFormInput()).includes(first)
                ) {
                  return goTo(0);
                }
                if (
                  Object.keys(new OpportunityDetailsFormInput()).includes(first)
                ) {
                  return goTo(1);
                }

                return goTo(0);
              }
            }

            if (error.message === "MISSING_INDUSTRIES") {
              fields();
              formik.setFieldValue("industries", []);
              formik.validateField("industries");
              goTo(1);
            }

            event("createOpportunityFailure", {
              event_category: "engagement",
              event_label: error.message,
            });

            // TODO global error state for INTERNAL SERVER ERROR
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      } else {
        next();
      }
    },
  });

  const goTo = (step: number) => {
    const link = getLink(step);
    if (link) {
      event("createOpportunity", {
        event_category: "engagement",
        event_label: `Step: ${step + 1}`,
      });
      // File object can be stored in location state (not possible with localStorage)
      // This keeps the form values in the latest route so if the user refreshes it will keep working.

      // Only gotcha with this is pushing the back button and then refreshing will lose it.
      // But better than persisting in all routes and trying to clean up after post.
      navigate(pathname, { state: { form: {} }, replace: true });
      setImmediate(() => {
        navigate(link, { state: { form: formik.values } });
      });
    }
  };

  // If there is no form data in state the user shouldn't be there.
  // So redirect to root create
  useEffect(() => {
    if (!Object.keys(form).length) {
      if (activeStep > 0) {
        navigate("/opportunity/create");
      } else if (activeStep === 0) {
        setCompleted(false);
      }
    }
  }, [setCompleted, navigate, activeStep, form]);

  const next = () => {
    goTo(activeStep + 1);
  };

  const prev = () => {
    goTo(activeStep - 1);
  };

  const { errors, touched } = formik;

  // Scroll the field into view that is touched with an error
  useEffect(() => {
    for (let i = 0; i < Object.keys(errors).length; i += 1) {
      const key = Object.keys(errors)[i] as keyof typeof errors;
      if (errors[key] && touched[key]) {
        const inputs = document.getElementsByName(key);
        if (inputs.length) {
          inputs[0].scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
        break;
      }
    }
  }, [errors, touched]);

  return (
    <FadeInOut>
      {!isCompleted ? (
        <CreateBase
          activeStep={activeStep}
          handleSubmit={formik.handleSubmit}
          handleReset={formik.handleReset}
          goTo={goTo}
        >
          <CompanyDetailsScreen path="/" formik={formik} />
          <OpportunityDetailsScreen
            path="/details"
            formik={formik}
            prev={prev}
            industries={data?.allIndustries || []}
            types={data?.allOpportunityTypes || []}
          />
          <ReviewOpportunityScreen
            path="/review"
            formik={formik}
            prev={prev}
            industries={data?.allIndustries || []}
            types={data?.allOpportunityTypes || []}
          />
          <Redirect
            from="/opportunity/create/*"
            to="/opportunity/create"
            noThrow
            default
          />
        </CreateBase>
      ) : (
        <CreateOpportunitySuccessScreen />
      )}
    </FadeInOut>
  );
};

export default CreateOpportunityContainer;
