import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Router, useLocation } from "@reach/router";
import React, { useMemo } from "react";

import alreadyRegisteredImage from "../../assets/images/already-registered.jpg";
import errorImage from "../../assets/images/error.jpg";
import forgotImage from "../../assets/images/forgot.jpg";
import registerSuccessImage from "../../assets/images/register-success.jpg";
import registerImage from "../../assets/images/register.jpg";
import signinImage from "../../assets/images/signin-v2.jpg";
import signupImage from "../../assets/images/signup-v2.jpg";
import { FadeInBgImage } from "../../components/Image";
import FadeInOut from "../../components/Transition/FadeInOut";

// import existsImage from "../../assets/images/exists.jpg";

const AuthBase: React.FC = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  const showImage = useBreakpointValue({ base: false, md: true });

  const image = useMemo(() => {
    switch (pathname.split("/").slice(1, 4).join("/")) {
      case "auth/signup/success":
        return registerSuccessImage;
      case "auth/signup":
        return registerImage;
      case "auth/create-account":
        return signupImage;
      case "auth/ineligible":
        return errorImage;
      case "auth/exists":
        return alreadyRegisteredImage;
      case "auth/forgot":
      case "auth/reset":
        return forgotImage;
      case "auth/signin":
      default:
        return signinImage;
    }
  }, [pathname]);

  return (
    <Flex h="100%">
      {showImage && (
        <FadeInBgImage
          backgroundColor="red"
          src={image}
          backgroundSize="cover"
          w="40%"
          minH="100%"
        />
      )}
      <Box w={{ base: "100%", md: "60%" }} minH="100%" position="relative">
        <Container h="100%">
          <FadeInOut>
            <Box key={pathname} h="100%">
              <Router location={location} primary={false} className="Router">
                {children}
              </Router>
            </Box>
          </FadeInOut>
        </Container>
      </Box>
    </Flex>
  );
};

export default AuthBase;
