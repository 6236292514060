import { Text } from "@chakra-ui/react";
import { EditorState, RichUtils } from "draft-js";
import React, { Dispatch } from "react";

import { Functional } from "../../../types";
import OrderedListIcon from "../../Icons/OrderedList";
import UnorderListIcon from "../../Icons/UnorderedList";

import ControlButton from "./ControlButton";

const BLOCK_TYPES = [
  {
    children: (
      <Text as="span" fontWeight={700}>
        H1
      </Text>
    ),
    "aria-label": "Heading 1",
    style: "header-one",
  },
  {
    children: (
      <Text as="span" fontWeight={700}>
        H2
      </Text>
    ),
    "aria-label": "Heading 2",
    style: "header-two",
  },
  {
    children: (
      <Text as="span" fontWeight={700}>
        H3
      </Text>
    ),
    "aria-label": "Heading 3",
    style: "header-three",
  },
  {
    children: <UnorderListIcon boxSize="24px" />,
    "aria-label": "Unordered list",
    style: "unordered-list-item",
  },
  {
    children: <OrderedListIcon boxSize="24px" />,
    "aria-label": "Ordered list",
    style: "ordered-list-item",
  },
];

interface BlockStyleControlsProps {
  editorState: EditorState;
  setEditorState: Dispatch<EditorState>;
  isFocussed: boolean;
}

const BlockStyleControls: Functional<BlockStyleControlsProps> = ({
  editorState,
  setEditorState,
  isFocussed,
}) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <>
      {BLOCK_TYPES.map(({ style, ...type }) => (
        <ControlButton
          key={style}
          isActive={style === blockType}
          isFocussed={isFocussed}
          onClick={(e) => {
            e.preventDefault();
            setEditorState(RichUtils.toggleBlockType(editorState, style));
          }}
          {...type}
        />
      ))}
    </>
  );
};

export default BlockStyleControls;
