import { RouteComponentProps } from "@reach/router";
import React from "react";

import DashboardBase from "../../presentation/Dashboard/Base";

import Profile from "./Profile";
import Settings from "./Settings";

const Dashboard: React.FC<RouteComponentProps> = () => {
  return (
    <DashboardBase>
      <Settings path="/settings" />
      <Profile path="/" />
    </DashboardBase>
  );
};

export default Dashboard;
