import { Button, ChakraProps, Text } from "@chakra-ui/react";
import { Matches, MinLength } from "class-validator";
import { FormikProps } from "formik";
import React from "react";

import { Field, Form, NoticeField, PasswordInput } from "../../components/Form";
import config from "../../config";
import { ResetPasswordInput } from "../../graphql";
import { Functional } from "../../types";
import { IsEqualTo } from "../../utils/decorators";

export interface ResetUserPasswordInput
  extends Omit<ResetPasswordInput, "token"> {
  confirmPassword: string;
}

interface ResetPasswordFormProps extends ChakraProps {
  formik: FormikProps<ResetUserPasswordInput>;
}

export class ResetPasswordFormInput {
  @MinLength(9, { message: config.errors.password })
  @Matches(/[0-9]/, { message: config.errors.password })
  @Matches(/[a-zA-Z]/, { message: config.errors.password })
  password!: string;

  @IsEqualTo("password", {
    message: "Please make sure your passwords match",
  })
  confirmPassword!: string;
}

const ResetPasswordForm: Functional<ResetPasswordFormProps> = ({
  formik,
  ...props
}) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = formik;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset} {...props}>
      <NoticeField
        error={touched.password && errors.password}
        notice={
          <Text>
            Password must contain a{" "}
            <Text as="span" fontWeight="bold">
              letter
            </Text>{" "}
            and a{" "}
            <Text as="span" fontWeight="bold">
              number
            </Text>{" "}
            and be a minimum of{" "}
            <Text as="span" fontWeight="bold">
              9 characters
            </Text>
          </Text>
        }
        isRequired
        mb={6}
      >
        <PasswordInput
          name="password"
          placeholder="Your password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Password"
        />
      </NoticeField>
      <Field error={touched.confirmPassword && errors.confirmPassword} mb={6}>
        <PasswordInput
          name="confirmPassword"
          placeholder="Confirm your password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Password confirmation"
        />
      </Field>
      <Button type="submit" isLoading={isSubmitting} isFullWidth>
        Reset Password
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
