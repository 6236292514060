import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";

import { OpportunityBoardContext } from "../../contexts/OpportunityBoardContext";
import { SelectButton } from "../Form";
import ChevronLeftIcon from "../Icons/ChevronLeft";

import FilterMenu from "./FilterMenu";

const Tag = chakra("span", {
  baseStyle: {
    display: "inline-block",
    w: "1.6rem",
    h: "1.6rem",
    lineHeight: "1.6rem",
    borderRadius: "50%",
    bg: "black",
    color: "white",
    textAlign: "center",
  },
});

const FilterMobile: React.FC = () => {
  const { isOpen, onOpen, onClose, getButtonProps } = useDisclosure();

  const {
    state: {
      filters: { industries, opportunities },
    },
    dispatch,
  } = useContext(OpportunityBoardContext);

  const inMenu = useMemo(() => {
    return industries.isOpen || opportunities.isOpen;
  }, [industries.isOpen, opportunities.isOpen]);

  const count = industries.selected.length + opportunities.selected.length;

  const clearALL = () => {
    dispatch({
      type: "REMOVE_ALL",
    });
    onClose();
  };

  return (
    <>
      <SelectButton
        colorScheme="black"
        buttonProps={{ ...getButtonProps(), onClick: onOpen }}
      >
        Filters <Tag>{count}</Tag>
      </SelectButton>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay bg="blackAlpha.800">
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px" fontSize="lg">
              <Flex justifyContent="space-between">
                <Box w="2rem" h="2rem">
                  {inMenu ? (
                    <button
                      onClick={() => {
                        dispatch({ type: "FORCE_CLOSE" });
                      }}
                    >
                      <ChevronLeftIcon w="2rem" h="2rem" />
                    </button>
                  ) : (
                    <DrawerCloseButton
                      color="black"
                      borderRadius="0"
                      boxSize="45px"
                      left="10px"
                      onClick={onClose}
                    />
                  )}
                </Box>
                <span>Filters</span>
                <Link as="button" fontSize="lg" onClick={clearALL}>
                  Clear
                </Link>
              </Flex>
            </DrawerHeader>
            <DrawerBody p={0}>
              <FilterMenu />
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px">
              <Button
                variant="outline"
                colorScheme="black"
                w="100%"
                onClick={onClose}
              >
                View results
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default FilterMobile;
