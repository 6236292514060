import { layoutPropNames, propNames } from "@chakra-ui/react";
import { useMemo } from "react";

const styleProps = [...(layoutPropNames || []), ...propNames];

export const useStyledProps = <T extends {}>(props: T) => {
  return useMemo(() => {
    const styledProps: Partial<T> = {};
    const unstyledProps: Partial<T> = {};
    (Object.keys(props) as Array<keyof T>).forEach((key) => {
      if (styleProps?.includes(key as string)) {
        styledProps[key] = props[key];
      } else {
        unstyledProps[key] = props[key];
      }
    });
    return [styledProps, unstyledProps];
  }, [props]);
};
