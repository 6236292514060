import { Box, HStack, useMultiStyleConfig } from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  ContentState,
  Editor,
  EditorProps,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import "draft-js/dist/Draft.css";
import React, {
  Dispatch,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { Functional } from "../../../types";
import { MarkdownBox } from "../../Markdown";

import BlockStyleControls from "./BlockStyleControls";
import InlineStyleControls from "./InlineStyleControls";

const EditorBox = styled(MarkdownBox)`
  .public-DraftEditor-content {
    min-height: 300px;
  }
`;

interface RichTextProps {
  placeholder?: EditorProps["placeholder"];
  onFocus?: EditorProps["onFocus"];
  onBlur?: EditorProps["onBlur"];
  onChange?: Dispatch<string>;
  changeOnBlur?: boolean;
  defaultValue?: string;
}

const setContent = (content: ContentState) => {
  return draftToMarkdown(convertToRaw(content), { preserveNewlines: true });
};

const RichText: Functional<RichTextProps> = ({
  onChange,
  onBlur,
  changeOnBlur = false,
  defaultValue,
  ...props
}) => {
  const container = useRef<HTMLDivElement>(null);
  const style = useMultiStyleConfig("Input", props);
  const [editorState, setEditorState] = useState(() => {
    if (defaultValue) {
      return EditorState.createWithContent(
        convertFromRaw(
          markdownToDraft(defaultValue, { preserveNewlines: true })
        )
      );
    }
    return EditorState.createEmpty();
  });
  const [focussed, setFocus] = useState(false);

  useEffect(() => {
    if (!container.current) {
      return;
    }
    const maybeSetFocus = () => {
      if (!container.current?.contains(document.activeElement)) {
        setFocus(false);
      } else {
        setFocus(true);
      }
    };
    document.addEventListener("focusin", maybeSetFocus);
    return () => document.removeEventListener("focusin", maybeSetFocus);
  }, [setFocus]);

  const onUpdate = (e: SyntheticEvent) => {
    if (onBlur) {
      onBlur(e);
    }
    if (changeOnBlur && onChange) {
      onChange(setContent(editorState.getCurrentContent()));
    }
  };

  useEffect(() => {
    !changeOnBlur &&
      onChange &&
      onChange(setContent(editorState.getCurrentContent()));
  }, [editorState, onChange, changeOnBlur]);

  return (
    <EditorBox ref={container}>
      <HStack
        position="relative"
        spacing="-2px"
        boxShadow={
          focussed ? style.field._focus?.boxShadow || undefined : undefined
        }
        display={{ base: "flex", md: "inline-block" }}
        zIndex={1}
      >
        <BlockStyleControls
          isFocussed={focussed}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        <InlineStyleControls
          isFocussed={focussed}
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </HStack>
      <Box
        sx={{
          ...style.field,
          ...(focussed ? { ...style.field._focus, _hover: {} } : null),
          h: "auto",
          py: 4,
          mt: "-1px",
        }}
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          onBlur={onUpdate}
          {...props}
        />
      </Box>
    </EditorBox>
  );
};

export default RichText;
