import {
  Box,
  ChakraProps,
  SystemStyleObject,
  ThemingProps,
  chakra,
  omitThemingProps,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";

import ChevronDownIcon from "../../Icons/ChevronDown";

const IconWrapper = chakra("div", {
  baseStyle: {
    position: "absolute",
    display: "inline-flex",
    width: "2rem",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    right: "0.5rem",
    pointerEvents: "none",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

const rootStyles: SystemStyleObject = {
  width: "100%",
  height: "fit-content",
  position: "relative",
};

export interface SelectButtonProps extends ChakraProps, ThemingProps {
  isOpen?: boolean;
  buttonProps?: any;
  labelProps?: any;
  isInvalid?: boolean;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  isOpen = false,
  buttonProps,
  labelProps,
  children,
  isInvalid = false,
  ...props
}) => {
  const styles = useMultiStyleConfig("Select", props);
  const { onClick, ...rest } = buttonProps;
  return (
    <Box p={0} sx={rootStyles}>
      <chakra.button
        textAlign="left"
        sx={{
          ...styles.field,
          ...(isInvalid ? { ...styles.field._invalid, _hover: {} } : null),
          ...omitThemingProps(props),
          borderColor: isInvalid
            ? styles.field._invalid?.borderColor || "inherit"
            : isOpen
            ? "black"
            : "inherit",
        }}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        {...rest}
      >
        <span {...labelProps}>{children}</span>
      </chakra.button>
      <IconWrapper zIndex={1}>
        <ChevronDownIcon role="presentation" sx={styles.icon} aria-hidden />
      </IconWrapper>
    </Box>
  );
};

export default SelectButton;
