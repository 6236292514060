import { Expose, Transform } from "class-transformer";

// import { Dispatch } from "react";
import { UserRole } from "../graphql";
export class AuthUser {
  @Transform((value) => value || null, { toClassOnly: true })
  @Expose()
  image!: string | null;
  @Expose() firstName!: string;
  @Expose() lastName!: string;
  @Expose() role!: UserRole;
}

export type AuthUserState = AuthUser | null;

// export interface EmailProps {
//   email: string;
//   setEmail: Dispatch<string>;
// }

// export interface PasswordProps {
//   password: string;
//   setPassword: Dispatch<string>;
// }

// export interface PasswordConfirmationProps {
//   confirmPassword: string;
//   setConfirmPassword: Dispatch<string>;
// }

// export interface UserCredentialsProps extends EmailProps, PasswordProps {}
