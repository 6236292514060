import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const CircleCloseIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 21 21" {...props} fill="none">
    <circle cx="10.5" cy="10.5" r="9.5" stroke="currentColor" strokeWidth="2" />
    <path
      d="M14.26 5.6L10.5 9.565 6.74 5.6 5.6 6.801l3.76 3.965-3.76 3.965 1.14 1.202 3.76-3.965 3.76 3.965 1.14-1.202-3.76-3.965 3.76-3.965L14.26 5.6z"
      fill="currentColor"
    />
  </Icon>
);

export default CircleCloseIcon;
