import { Box, Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";

import NavLink from "./NavLink";

const Navigation: React.FC = () => (
  <Box as="nav">
    <Flex
      as={UnorderedList}
      listStyleType="none"
      minH="60px"
      alignItems="center"
    >
      <Box as={ListItem}>
        <NavLink to="/" mr={8} isExact>
          Opportunity Board
        </NavLink>
      </Box>
      <Box as={ListItem}>
        <NavLink to="/opportunity/create" mr={8}>
          Post an opportunity
        </NavLink>
      </Box>
      <Box as={ListItem}>
        <NavLink to="/auth/signin" mr={8}>
          Log in
        </NavLink>
      </Box>
      <Box as={ListItem}>
        <NavLink to="/auth/signup" mr={4}>
          Register
        </NavLink>
      </Box>
    </Flex>
  </Box>
);

export default Navigation;
