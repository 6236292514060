import { Box } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { Children, ReactElement } from "react";

const FadeInOut: React.FC = ({ children }) => (
  <AnimatePresence exitBeforeEnter>
    {Children.map(children, (child) => {
      if (!child) {
        return null;
      }
      return (
        <Box
          key={(child as ReactElement).key}
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
          h="100%"
        >
          {child}
        </Box>
      );
    })}
  </AnimatePresence>
);

export default FadeInOut;
