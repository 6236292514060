import { Box, Button, HStack, Link, Wrap, WrapItem } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useMemo } from "react";

import {
  FilterTypes,
  OpportunityBoardContext,
} from "../../contexts/OpportunityBoardContext";
import CircleCloseIcon from "../Icons/CircleClose";

const FilterList: React.FC = () => {
  const {
    state: { filters },
    dispatch,
  } = useContext(OpportunityBoardContext);

  const allFilters = useMemo(() => {
    return Object.keys(filters).reduce<JSX.Element[]>((accum, id) => {
      const identifier = id as FilterTypes;
      if (!filters[identifier]) {
        return accum;
      }

      const visibleFilters = filters[identifier].selected.map(
        (item: string) => (
          <WrapItem key={`${id}-${item}`} as="li">
            <Button
              variant="ghost"
              fontWeight={400}
              rightIcon={<CircleCloseIcon w="22px" h="22px" />}
              onClick={() => {
                dispatch({
                  type: "REMOVE_ITEM",
                  key: identifier,
                  payload: item,
                });
              }}
            >
              {item}
            </Button>
          </WrapItem>
        )
      );
      return accum.concat(visibleFilters);
    }, []);
  }, [filters, dispatch]);

  const clearALL = () => {
    dispatch({ type: "REMOVE_ALL" });
  };

  return (
    <AnimatePresence>
      {allFilters.length && (
        <HStack
          as={motion.div}
          initial={{ opacity: 0, height: 0, marginTop: "0rem" }}
          animate={{ opacity: 1, height: "auto", marginTop: "2rem" }}
          exit={{ opacity: 0, height: 0, marginTop: "0rem" }}
          spacing={4}
          overflow="hidden"
          justify="center"
          align="flex-start"
        >
          <Wrap as="ul" spacing={4} maxW="80%" role="listbox">
            {allFilters}
          </Wrap>
          <Box>
            <Link as="button" mt="14px" onClick={clearALL}>
              Clear filters
            </Link>
          </Box>
        </HStack>
      )}
    </AnimatePresence>
  );
};

export default FilterList;
