const global = `
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

html, body, #root, .PrimaryRouter, .Router {
  height: 100%;
}

.Router {
  width: 100%;
}

.PrimaryRouter {
  display: flex;
  flex-direction: column;
}

#main {
  flex: 1;
}

#main > div {
  height: 100%;
}

body {
  color: #000000;
}
`;

export default global;
