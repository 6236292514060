import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { Children, cloneElement, isValidElement, useState } from "react";

import Field, { FieldProps } from "./Field";

interface NoticeField extends FieldProps {
  notice: string | JSX.Element;
}

const InputWithNotice: React.FC<NoticeField> = ({
  children,
  error,
  notice,
  isRequired,
  ...props
}) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const [supressError, setSupressError] = useState(false);
  const styles = useMultiStyleConfig("Input", { isInvalid: !!error });
  const noticeColor = !!error
    ? styles.field._invalid?.borderColor
    : styles.field._focus?.borderColor;
  return (
    <AnimateSharedLayout>
      <Box {...props}>
        <Box
          _before={{
            position: "absolute",
            content: isFocussed ? '""' : undefined,
            w: 0,
            h: 0,
            bottom: 0,
            left: 4,
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: `12px solid ${noticeColor}`,
          }}
          position="relative"
        >
          <Field
            supressError={supressError}
            error={error}
            isRequired={isRequired}
          >
            {Children.map(children, (child) => {
              if (!isValidElement(child)) {
                return null;
              }
              return cloneElement(child, {
                ...child.props,
                onFocus: (e: FocusEvent) => {
                  if (child.props.onFocus) {
                    child.props.onFocus(e);
                  }
                  setIsFocussed(true);
                  setSupressError(true);
                },
                onBlur: (e: FocusEvent) => {
                  if (child.props.onBlur) {
                    child.props.onBlur(e);
                  }
                  setIsFocussed(false);
                },
              });
            })}
          </Field>
        </Box>
        <AnimatePresence
          presenceAffectsLayout
          onExitComplete={() => setSupressError(false)}
        >
          {isFocussed && (
            <Box
              as={motion.div}
              layout
              variants={{
                open: {
                  height: "auto",
                  opacity: 1,
                  transition: { duration: 0.3 },
                },
                close: { height: 0, opacity: 0, transition: { duration: 0.3 } },
              }}
              initial={"close"}
              animate={"open"}
              exit={"close"}
              sx={{
                ...(!!error ? styles.field._invalid : styles.field._focus),
                color: "white",
                bg: !!error
                  ? styles.field._invalid?.borderColor
                  : styles.field._focus?.borderColor || "inherit",
                overflow: "hidden",
              }}
            >
              <Box p={4}>{notice}</Box>
            </Box>
          )}
        </AnimatePresence>
      </Box>
    </AnimateSharedLayout>
  );
};

export default InputWithNotice;
