import { Heading } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React from "react";

import { AlertMessage } from "../../components/Form";
import { RequestVerificationInput } from "../../graphql";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import VerifyAccountForm from "../../presentation/Auth/VerifyAccountForm";
import { Functional } from "../../types";

export interface VerifyScreenProps {
  formik: FormikProps<RequestVerificationInput>;
  globalError?: string;
}

const VerifyScreen: Functional<VerifyScreenProps> = ({
  formik,
  globalError,
}) => {
  return (
    <AuthContainer>
      <Heading as="h1" mb={6}>
        Verify your account
      </Heading>
      {globalError && (
        <AlertMessage status="error" mb={6}>
          {globalError}
        </AlertMessage>
      )}
      <VerifyAccountForm formik={formik} mb={6} />
    </AuthContainer>
  );
};

export default VerifyScreen;
