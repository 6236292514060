import { useLocation } from "@reach/router";
import { useMemo } from "react";

export const useUrlSearchParams = () => {
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  return params;
};
