import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

import { Functional } from "../../types";

const PlusIcon: Functional<IconProps> = (props) => (
  <Icon viewBox="0 0 26 26" {...props} fill="none">
    <path
      d="M24.97 14.895h.5v-3.82H14.989V.5h-4.055v10.575H.5v3.82h10.434V25.47h4.055V14.895h9.981z"
      fill="currentColor"
      stroke="currentColor"
    />
  </Icon>
);

export default PlusIcon;
