import { Box, Center } from "@chakra-ui/react";
import React from "react";

const AuthContainer: React.FC = ({ children }) => (
  <Center h="100%">
    <Box w="100%" maxW={{ md: "410px" }} py={32} position="relative">
      {children}
    </Box>
  </Center>
);

export default AuthContainer;
