import { RouteComponentProps } from "@reach/router";
import { offsetToCursor } from "graphql-relay";
import React, { useContext, useEffect, useMemo } from "react";

import {
  OpportunityBoardContext,
  OpportunityBoardProvider,
} from "../../contexts/OpportunityBoardContext";
import {
  OpportunitySummaryFragment,
  OpportunityType,
  useLatestOpportunitiesLazyQuery,
} from "../../graphql";
import { useFilterQuery } from "../../hooks/useFilterQuery";
import { OpportunityBoardScreen } from "../../screens/Opportunity/";

const ITEMS_PER_PAGE = 12;

interface OpportunityBoardProps extends RouteComponentProps {}

const OpportunityBoardContainer: React.FC<OpportunityBoardProps> = () => {
  const {
    state: { filters },
  } = useContext(OpportunityBoardContext);
  const [{ page = 1 }, setFilters] = useFilterQuery();

  const { industries, types } = useMemo(() => {
    const industries = filters.industries.ids as number[];
    const types = filters.opportunities.ids as OpportunityType[];
    return { industries, types };
  }, [filters]);

  const [fetchLatest, { loading, data }] = useLatestOpportunitiesLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchLatest({
      variables: {
        first: ITEMS_PER_PAGE,
        after: page > 1 ? offsetToCursor((page - 1) * ITEMS_PER_PAGE - 1) : "",
        industries,
        types,
      },
    });
  }, [fetchLatest, page, industries, types]);

  // Extract page meta
  const pageData = useMemo(
    () =>
      data?.latestOpportunities.pageData
        ? {
            page,
            ...data?.latestOpportunities.pageData,
          }
        : null,
    [data?.latestOpportunities.pageData, page]
  );

  // Reset the page param if user navigates out of bounds
  useEffect(() => {
    if (!loading && !data?.latestOpportunities?.edges?.length && page > 1) {
      // We will have this here
      if (pageData) {
        const { count, limit } = pageData;
        setFilters({ page: Math.max(Math.ceil(count / limit), 1) });
      }
    }
  }, [page, loading, data, pageData, setFilters]);

  const visibleOpportunities = useMemo(() => {
    return (
      data?.latestOpportunities?.edges?.reduce<OpportunitySummaryFragment[]>(
        (memo, edge) => {
          if (edge.node) {
            memo.push(edge.node);
          }
          return memo;
        },
        []
      ) || []
    );
  }, [data]);

  return (
    <OpportunityBoardScreen
      loading={loading}
      opportunities={visibleOpportunities}
      pageData={pageData}
    />
  );
};

const OpportunityBoardContainerContext: React.FC<OpportunityBoardProps> = (
  props
) => (
  <OpportunityBoardProvider>
    <OpportunityBoardContainer {...props} />
  </OpportunityBoardProvider>
);

export default OpportunityBoardContainerContext;
