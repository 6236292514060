export const pageView = (options: Gtag.CustomParams) => {
  if (window.GTAG_ID && window.Cookiebot.consent.statistics && window.gtag) {
    window.gtag("config", window.GTAG_ID, options);
  }
};

export const event = (
  eventName: string,
  options?: Gtag.EventParams | Gtag.CustomParams | Gtag.ControlParams
) => {
  if (window.GTAG_ID && window.Cookiebot.consent.statistics && window.gtag) {
    window.gtag("event", eventName, options);
  }
};
