import { RouteComponentProps } from "@reach/router";
import React from "react";

import { FadeInOut } from "../../components/Transition";
import { RegisterScreen } from "../../screens/Auth/RegisterScreen";
import { Functional } from "../../types";

const RegisterContainer: Functional<RouteComponentProps> = () => {
  return (
    <FadeInOut>
      <RegisterScreen />
    </FadeInOut>
  );
};

export default RegisterContainer;
