import { Box, Flex, Skeleton } from "@chakra-ui/react";
import React from "react";

const OpportunityCardSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton minH="310px" />
      <Box px={4} mt={-8} zIndex={1} position="relative">
        <Flex direction="column" alignItems="center" p={4} bg="white">
          <Skeleton w="100%" h={8} mb={4} />
          <Skeleton w="70%" h={4} mb={4} />
          <Skeleton w="80%" h={4} mb={2} />
          <Skeleton w="50%" h={4} />
        </Flex>
      </Box>
    </Box>
  );
};

export default OpportunityCardSkeleton;
