import { RouteComponentProps, useNavigate } from "@reach/router";
import { useFormik } from "formik";
import React, { useState } from "react";

import { UserInput } from "../../graphql";
import { useLogin } from "../../operations/user";
import { SigninFormInput } from "../../presentation/Auth/SigninForm";
import { SigninScreen } from "../../screens/Auth";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

const SigninContainer: React.FC<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const [signin] = useLogin();
  const [globalError, setGlobalError] = useState("");

  const formik = useFormik<UserInput>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: validator(SigninFormInput),
    onSubmit: (values) => {
      signin({
        variables: {
          user: values,
        },
      })
        .then(() => {
          event("login");
          // The opportunities route is only available in the next tick
          setImmediate(() => navigate("/opportunities"));
        })
        .catch((error) => {
          if (error.message === "BAD_USER_INPUT") {
            return formik.setErrors(
              error.graphQLErrors[0].extensions?.response || {}
            );
          }

          if (error.message === "INVALID_YOUNG_PERSON") {
            return setGlobalError(
              "There is a problem with your account. Please contact support."
            );
          }

          if (error.message === "USER_NOT_VERIFIED") {
            return navigate("/auth/verification");
          }

          if (error.message === "INVALID_USER") {
            return setGlobalError(
              "The username or password you entered is incorrect."
            );
          }

          event("loginFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "We are unable to sign into your account at this time. Please try again later."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return <SigninScreen formik={formik} globalError={globalError} />;
};

export default SigninContainer;
