import { RouteComponentProps } from "@reach/router";
import React, { useMemo } from "react";

import { OpportunitySummaryFragment, useUserProfileQuery } from "../../graphql";
import { ProfileScreen } from "../../screens/Dashboard";

const Profile: React.FC<RouteComponentProps> = () => {
  const { data, loading } = useUserProfileQuery();

  const opportunities = useMemo(() => {
    const edges = data?.latestOpportunities?.edges;
    if (!edges) {
      return [];
    }
    return edges.reduce<OpportunitySummaryFragment[]>((memo, edge) => {
      if (edge.node) {
        memo.push(edge.node);
      }
      return memo;
    }, []);
  }, [data]);

  return (
    <ProfileScreen
      profile={data?.profile}
      loading={loading}
      opportunities={opportunities}
    />
  );
};

export default Profile;
