import { Box } from "@chakra-ui/react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import React, { useCallback, useEffect, useLayoutEffect } from "react";

import { pageView } from "../../utils/analytics";
import Footer from "../Footer";
import HeaderNav from "../HeaderNav";

const Base: React.FC<RouteComponentProps> = ({ children }) => {
  const navigate = useNavigate();
  const {
    href,
    pathname,
    state,
  }: { href: string; pathname: string; state: any } = useLocation();
  const updateState = useCallback(() => {
    // Must be a better way to do this
    // Basically need a mechanism to allow other Route components
    // to handle scroll position. Maybe look at how Gatsby does it.
    if (pathname !== "/") {
      navigate(href, {
        state: { ...state, scrolled: true },
        replace: true,
      }).then(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }));
    }
  }, [href, state, pathname, navigate]);

  useLayoutEffect(() => {
    if (!state?.scrolled) {
      updateState();
    }
  }, [state, updateState]);

  useEffect(() => {
    pageView({ page_path: pathname });
  }, [pathname]);

  return (
    <>
      <HeaderNav />
      <Box as="main" id="main">
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default Base;
