import { Heading } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React from "react";

import { AlertMessage } from "../../components/Form";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import ResetPasswordForm, {
  ResetPasswordFormInput,
} from "../../presentation/Auth/ResetPasswordForm";
import { Functional } from "../../types";

export interface ResetPasswordScreenProps {
  formik: FormikProps<ResetPasswordFormInput>;
  globalError?: string;
}

const ResetPasswordScreen: Functional<ResetPasswordScreenProps> = ({
  formik,
  globalError,
}) => {
  return (
    <AuthContainer>
      <Heading as="h1" mb={6}>
        Reset your password
      </Heading>
      {globalError && (
        <AlertMessage status="error" mb={6}>
          {globalError}
        </AlertMessage>
      )}
      <ResetPasswordForm formik={formik} mb={6} />
    </AuthContainer>
  );
};

export default ResetPasswordScreen;
