import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ViewOffIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M5.24 22.51l1.43-1.42A14.06 14.06 0 013.07 16C5.1 10.93 10.7 7 16 7a12.38 12.38 0 014 .72l1.55-1.56A14.72 14.72 0 0016 5 16.69 16.69 0 001.06 15.66a1 1 0 000 .68 16 16 0 004.18 6.17z"
      fill="currentColor"
    />
    <path
      d="M12 15.73a4 4 0 013.7-3.7l1.81-1.82a6 6 0 00-7.33 7.33z"
      fill="currentColor"
    />
    <path
      d="M30.94 15.66a16.4 16.4 0 00-5.74-7.44L30 3.41 28.59 2 2 28.59 3.41 30l5.1-5.1A15.29 15.29 0 0016 27a16.69 16.69 0 0014.94-10.66 1 1 0 000-.68zM20 16a4 4 0 01-6 3.44L19.44 14a4 4 0 01.56 2zm-4 9a13.05 13.05 0 01-6-1.58l2.54-2.54a6 6 0 008.35-8.35l2.87-2.87A14.54 14.54 0 0128.93 16C26.9 21.07 21.3 25 16 25z"
      fill="currentColor"
    />
  </Icon>
);

export default ViewOffIcon;
