import {
  ValidateBy,
  ValidationOptions,
  buildMessage,
  isInstance,
} from "class-validator";

const IS_FILE = "isFile";

/**
 * types: Array of mimetypes e.g. ['image/jpeg']
 * maxFileSize: number in MB
 * minDimensions: [width: number, height?: number]
 */
export function IsFile(
  options: {
    types?: string[];
    maxFileSize?: number;
    minDimensions?: number[];
  },
  validationOptions?: ValidationOptions
) {
  return ValidateBy(
    {
      name: IS_FILE,
      validator: {
        validate: (value: File, args): boolean => {
          try {
            let valid = isInstance(value, File);

            if (valid && options.types) {
              valid = options.types.includes(value.type);
            }

            if (valid && options.maxFileSize) {
              valid = options.maxFileSize * 1000000 > value.size;
            }

            return valid;
          } catch (e) {
            return false;
          }
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be a valid File",
          validationOptions
        ),
      },
    },
    validationOptions
  );
}
