import {
  Box,
  ChakraProps,
  Checkbox,
  Text,
  ThemingProps,
  forwardRef,
} from "@chakra-ui/react";
import React from "react";

export interface CheckboxSelectOptionProps extends ChakraProps, ThemingProps {
  isChecked: boolean;
  isDisabled?: boolean;
  isHighlighted: boolean;
  itemProps?: any;
}

/**
 * A accessible select option for MultiSelect.
 * The override of the role as checkbox indicates to the user
 * that it's a checkbox. We then us the `as` on the `Checkbox`
 * component so focus doesnt break out of the list if it were left
 * as a nestend input.
 *
 * Very complicated stuff.
 */
const CheckboxSelectOption = forwardRef<CheckboxSelectOptionProps, "li">(
  function CheckboxSelectOption(props, ref) {
    const {
      isChecked,
      isDisabled = false,
      isHighlighted,
      children,
      itemProps,
      size,
      ...rest
    } = props;
    return (
      <Box
        ref={ref}
        as="li"
        bg={isHighlighted ? "gray.100" : "white"}
        {...itemProps}
        {...rest}
      >
        <Checkbox
          as="span"
          display="flex"
          p={4}
          colorScheme="brand.pink"
          variant="solid"
          isChecked={isChecked}
          size={size}
          fontSize="lg"
          isDisabled={isDisabled}
        >
          <Text fontSize="lg">{children}</Text>
        </Checkbox>
      </Box>
    );
  }
);

export default CheckboxSelectOption;
