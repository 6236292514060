import { Alert, AlertDescription, AlertTitle, Link } from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import React from "react";

import ErrorIcon from "../../components/Icons/Error";
import config from "../../config";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import { Functional } from "../../types";

const IneligibleScreen: Functional<RouteComponentProps> = () => (
  <AuthContainer>
    <Alert status="error" variant="ghost" mb={6}>
      <ErrorIcon boxSize="67px" color="red.500" />
      <AlertTitle>Oops! We don't have that account registered</AlertTitle>
      <AlertDescription maxWidth="sm">
        If you think you should have access please email{" "}
        <Link href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
      </AlertDescription>
    </Alert>
  </AuthContainer>
);

export default IneligibleScreen;
