import { RouteComponentProps } from "@reach/router";
import { isUUID } from "class-validator";
import { useFormik } from "formik";
import React, { useState } from "react";

import { FadeInOut } from "../../components/Transition";
import config from "../../config";
import { useResetPasswordMutation } from "../../graphql";
import {
  ResetPasswordFormInput,
  ResetUserPasswordInput,
} from "../../presentation/Auth/ResetPasswordForm";
import {
  ResetPasswordScreen,
  ResetPasswordSuccessScreen,
} from "../../screens/Auth";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

interface ResetPasswordProps extends RouteComponentProps {
  token?: string;
}

const ResetPasswordContainer: Functional<ResetPasswordProps> = ({ token }) => {
  const [reset, { data }] = useResetPasswordMutation();
  const [globalError, setGlobalError] = useState(
    !isUUID(token, 4) ? config.errors.invalidUuid : ""
  );

  const formik = useFormik<ResetUserPasswordInput>({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: validator(ResetPasswordFormInput),
    onSubmit: (values) => {
      if (!token || !isUUID(token, 4)) {
        return;
      }

      reset({
        variables: {
          reset: {
            token,
            password: values.password,
          },
        },
      })
        .then(({ data }) => {
          if (!data?.resetPassword) {
            event("resetPasswordFailure", {
              event_category: "engagement",
              event_label: "INVALID_OR_EXPIRED",
            });

            setGlobalError(
              "The password reset token is either invalid or expired. Please request a new reset."
            );
          } else {
            event("resetPassword", { event_category: "engagement" });
          }
        })
        .catch((error) => {
          const collection = error?.graphQLErrors[0].extensions?.response || {};

          if (error.message === "BAD_USER_INPUT") {
            if (collection.token) {
              setGlobalError(collection.token);
              delete collection.token;
            }
            return formik.setErrors(collection);
          }

          event("resetPasswordFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "We are unable to reset you password at this time. Please try again later."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <FadeInOut>
      {!data || !data.resetPassword ? (
        <ResetPasswordScreen formik={formik} globalError={globalError} />
      ) : (
        <ResetPasswordSuccessScreen />
      )}
    </FadeInOut>
  );
};

export default ResetPasswordContainer;
