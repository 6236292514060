import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";

import { Functional } from "../../../types";
import ViewOffIcon from "../../Icons/ViewOff";
import ViewOnIcon from "../../Icons/ViewOn";

const PasswordInput: Functional<InputProps> = (props) => {
  const [type, setType] = useState("password");
  return (
    <InputGroup as={motion.div}>
      <Input {...props} type={type} />
      <InputRightElement>
        <Button
          variant="ghost"
          size="sm"
          color="gray.500"
          onClick={() => setType(type === "password" ? "text" : "password")}
        >
          {type === "password" ? (
            <ViewOnIcon boxSize={6} />
          ) : (
            <ViewOffIcon boxSize={6} />
          )}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};

export default PasswordInput;
