import { ApolloClient, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { navigate } from "@reach/router";
import { createUploadLink } from "apollo-upload-client";

import cache from "./cache";

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message === "UNAUTHENTICATED") {
            navigate("/auth/signout", {
              state: {
                notice: {
                  type: "error",
                  message: "Your session is expired. Please sign in.",
                },
              },
            });
          }

          if (message === "FORBIDDEN") {
            navigate("/auth/signin", {
              state: {
                notice: {
                  type: "error",
                  message:
                    "You do not have the permissions to access the requested resource.",
                },
              },
            });
          }

          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    createUploadLink({
      uri: process.env.REACT_APP_API_URI,
      credentials: "include",
    }),
  ]),
  cache,
  credentials: "include",
  connectToDevTools: true,
});

export default client;
