import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ChevronLeftIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
    />
  </Icon>
);

export default ChevronLeftIcon;
