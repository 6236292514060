import { Redirect, RouteComponentProps } from "@reach/router";
import React from "react";

import { useAuthUser } from "../../operations/user";
import AuthBase from "../../presentation/Auth/Base";
import { IneligibleScreen, UserExistsScreen } from "../../screens/Auth";

import Create from "./CreateContainer";
import ForgotPassword from "./ForgotPasswordContainer";
import NotVerified from "./NotVerifiedContainer";
import Register from "./RegisterContainer";
import RegisterSuccess from "./RegisterSuccessContainer";
import RestPassword from "./ResetPasswordContainer";
import Signin from "./SigninContainer";
import Signout from "./SignoutContainer";
import Verify from "./VerifyContainer";

const Auth: React.FC<RouteComponentProps> = () => {
  const authUser = useAuthUser();
  return (
    <AuthBase>
      <Signin path="/signin" />
      {!authUser ? (
        <>
          <IneligibleScreen path="/ineligible" />
          <UserExistsScreen path="/exists" />
          <ForgotPassword path="/forgot" />
          <RestPassword path="/reset/:token" />
          <Verify path="/verify/:token" />
          <Create path="/create-account" />
          <Register path="/signup" />
          <RegisterSuccess path="/signup/success" />
          <NotVerified path="/verification" />
          <Redirect from="/auth/*" to="/auth/signin" noThrow default />
        </>
      ) : (
        <>
          <Signout path="/signout" />
          <Redirect from="/auth/*" to="/" noThrow default />
        </>
      )}
    </AuthBase>
  );
};

export default Auth;
