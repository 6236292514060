import { Button, ChakraProps, Input } from "@chakra-ui/react";
import { IsEmail, IsNotEmpty } from "class-validator";
import { FormikProps } from "formik";
import React from "react";

import { Field, Form, PasswordInput } from "../../components/Form";
import config from "../../config";
import { UserInput } from "../../graphql";
import { Functional } from "../../types";

interface SigninFormProps extends ChakraProps {
  formik: FormikProps<UserInput>;
}

export class SigninFormInput {
  @IsEmail({}, { message: config.errors.email })
  email!: string;

  @IsNotEmpty({ message: "Please enter your password" })
  password!: string;
}

const SigninForm: Functional<SigninFormProps> = ({ formik, ...props }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = formik;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset} {...props}>
      <Field error={touched.email && errors.email} mb={6} isRequired>
        <Input
          type="email"
          name="email"
          placeholder="Your email address"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Email address"
        />
      </Field>
      <Field error={touched.password && errors.password} mb={6}>
        <PasswordInput
          name="password"
          placeholder="Your password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Password"
        />
      </Field>
      <Button type="submit" isLoading={isSubmitting} isFullWidth>
        Log in
      </Button>
    </Form>
  );
};

export default SigninForm;
