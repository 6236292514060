import {
  Box,
  Flex,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  UnorderedList,
} from "@chakra-ui/react";
import { Link } from "@reach/router";
import React from "react";

import { AuthUser, Functional } from "../../types";
import Avatar from "../Avatar";

import NavLink from "./NavLink";

interface AuthNavigationProps {
  authUser: AuthUser;
}

const AuthNavigation: Functional<AuthNavigationProps> = ({ authUser }) => {
  return (
    <Box as="nav">
      <Flex as={UnorderedList} listStyleType="none" alignItems="center">
        <Box as={ListItem}>
          <NavLink to="/opportunities" mr={8}>
            Opportunity Board
          </NavLink>
        </Box>
        <Box as={ListItem}>
          <NavLink to="/opportunity/create" mr={8}>
            Post an opportunity
          </NavLink>
        </Box>
        <Box
          as={ListItem}
          position="relative"
          sx={{
            pl: 8,
            _before: {
              content: '""',
              position: "absolute",
              h: "100%",
              top: "0",
              left: 0,
              w: "2px",
              bg: "white",
            },
          }}
        >
          <NavLink to="/" isExact mr={8}>
            Dashboard
          </NavLink>
        </Box>
        <Box as={ListItem} position="relative">
          <Menu>
            <MenuButton
              as={Avatar}
              boxSize="60px"
              borderRadius="50%"
              overflow="hidden"
              aria-label="Open Menu"
              {...authUser}
            />
            <MenuList>
              <MenuItem
                as={Link}
                to="/auth/signout"
                textTransform="uppercase"
                fontWeight={700}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Flex>
    </Box>
  );
};

export default AuthNavigation;
