import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Text,
} from "@chakra-ui/react";
import { Expose, Transform, Type, plainToClass } from "class-transformer";
import {
  Equals,
  IsBoolean,
  IsEmail,
  IsHexColor,
  IsNotEmpty,
  IsOptional,
  MaxLength,
  MinLength,
  ValidateNested,
} from "class-validator";
import { FormikProps } from "formik";
import React, { useCallback } from "react";

import { Field, SingleImageInput } from "../../components/Form";
import config from "../../config";
import { CreateOpportunityInput, UploadImageInput } from "../../graphql";
import { Functional } from "../../types";
import { IsFile, MinFileDimensions } from "../../utils/decorators";

export interface CompanyDetailsFormProps {
  formik: FormikProps<CreateOpportunityInput>;
}

class UploadLogoInput {
  @MinFileDimensions([320, 320], {
    message: "The logo needs to be 320px X 320px or larger.",
  })
  @IsFile(
    {
      types: ["image/jpeg", "image/png"],
      maxFileSize: 5,
    },
    {
      message: "Only jpeg/png files are accepted with a max file size of 5MB.",
    }
  )
  @Type(() => Object)
  @Transform((value: any, obj: UploadLogoInput) => {
    return obj.file;
  })
  @Expose()
  file!: File;

  @IsHexColor()
  @IsOptional()
  @Expose()
  color?: string;
}

export class CompanyDetailsFormInput {
  @IsEmail({}, { message: "Please enter a valid email address" })
  @IsNotEmpty({ message: "Please enter a valid email address" })
  @Expose()
  email!: string;

  @MaxLength(40, {
    message: "The maximum length for a company name is 40 characters",
  })
  @MinLength(1, {
    message: "The minimum length for a company name is 1 character",
  })
  @IsNotEmpty({ message: "Please enter your company name" })
  @Expose()
  company!: boolean;

  @ValidateNested()
  @Transform((value) => plainToClass(UploadLogoInput, value))
  @Type(() => UploadLogoInput)
  @IsOptional()
  @Expose()
  logo?: UploadLogoInput;

  @Equals(true, { message: "Please read and accept the above policies" })
  @IsBoolean({ message: "Please read and accept the above policies" })
  @Expose()
  policy!: boolean;
}

/**
 * This form is a step in a multi-form
 */
const CompanyDetailsForm: Functional<CompanyDetailsFormProps> = ({
  formik,
}) => {
  const {
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    values,
  } = formik;
  const setImage = useCallback(
    (image: UploadImageInput) => setFieldValue("logo", image),
    [setFieldValue]
  );
  const setImageTouched = useCallback(() => setFieldTouched("logo", true), [
    setFieldTouched,
  ]);
  return (
    <>
      <Heading as="h1" size="lg" mb={2}>
        A bit about you and your company
      </Heading>
      <Text mb={8}>*Required</Text>
      <FormControl isInvalid={!!errors.logo && !!touched.logo} mb={8}>
        <Grid
          templateColumns="repeat(6, 1fr)"
          w="100%"
          columnGap={6}
          fontSize="lg"
        >
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <SingleImageInput
              id="image"
              name="image"
              iconSize="24px"
              setValue={setImage}
              defaultObject={values.logo || undefined}
              onBlur={setImageTouched}
              onDrop={setImageTouched}
            />
          </GridItem>
          <GridItem
            as={Flex}
            flexDirection="column"
            colSpan={{ base: 4, md: 5 }}
            justifyContent="center"
          >
            <FormLabel fontWeight="bold" htmlFor="image" fontSize="lg">
              Company logo
            </FormLabel>
            <Text fontSize="md">
              Maximum size of 5MB with dimensions of a 320px x 320px or larger
              (JPG or PNG). Twitter or instagram avatars are ideal!
            </Text>
            <FormErrorMessage>{errors.logo}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <Field
        label="Company name"
        error={touched.company ? errors.company : undefined}
        mb={8}
        isRequired
      >
        <Input
          placeholder="Create Jobs"
          name="company"
          onChange={handleChange}
          value={values.company}
        />
      </Field>
      <Field
        label="Contact email"
        description="We will only use this email to get in contact if there are any questions regarding your post"
        error={touched.email ? errors.email : undefined}
        mb={8}
        isRequired
      >
        <Input
          placeholder={config.contactEmail}
          name="email"
          type="email"
          onChange={handleChange}
          value={values.email}
        />
      </Field>
      <FormControl isInvalid={!!errors.policy && touched.policy} mb={20}>
        <Checkbox
          name="policy"
          onChange={handleChange}
          isChecked={!!values.policy}
          isInvalid={!!errors.policy && touched.policy}
          fontWeight={700}
          isRequired
        >
          I have read and accepted the{" "}
          <Link href={config.privacyPolicy} target="_blank">
            Data Policy
          </Link>
          , including the{" "}
          <Link href={config.cookiePolicy} target="_blank">
            Cookie Use Policy
          </Link>
        </Checkbox>
        <FormErrorMessage pt={4}>{errors.policy}</FormErrorMessage>
      </FormControl>
      <Button type="submit" isFullWidth mb={20}>
        Next
      </Button>
    </>
  );
};

export default CompanyDetailsForm;
