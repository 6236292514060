import { Alert, AlertDescription, AlertTitle, Button } from "@chakra-ui/react";
import { Link } from "@reach/router";
import React from "react";

import SuccessIcon from "../../components/Icons/Success";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import { Functional } from "../../types";

const ResetPasswordScreen: Functional = () => (
  <AuthContainer>
    <Alert status="success" variant="ghost" mb={6}>
      <SuccessIcon boxSize="67px" />
      <AlertTitle>Your password reset is completed!</AlertTitle>
      <AlertDescription maxWidth="sm">
        You can now sign in with your new credentials
      </AlertDescription>
    </Alert>
    <Button as={Link} to="/auth/signin" isFullWidth mb={6}>
      Sign in
    </Button>
  </AuthContainer>
);

export default ResetPasswordScreen;
