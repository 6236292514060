import { Redirect, Router } from "@reach/router";
import React, { useEffect } from "react";

import Base from "./components/Base";
import { Auth } from "./containers/Auth";
import { Dashboard } from "./containers/Dashboard";
import {
  CreateOpportunity,
  OpportunityBoard,
  OpportunityDetail,
} from "./containers/Opportunity";
import { useAuthUser } from "./operations/user";
import CookiePolicyScreen from "./screens/CookiePolicyScreen";
import { pageView } from "./utils/analytics";

const Main = () => {
  const authUser = useAuthUser();

  // Listen for changes to consent and disable/enable gtag
  useEffect(() => {
    const tagId = window.GTAG_ID;
    const maybeEnableGA = () => {
      const consent = window.Cookiebot.consent.statistics;
      if (tagId) {
        const disableKey =
          tagId === "G-8F2RG7QQSY"
            ? "ga-disable-G-8F2RG7QQSY"
            : tagId === "G-CVME5JVTM0"
            ? "ga-disable-G-CVME5JVTM0"
            : null;

        
        if (disableKey) {
          // Only track a page view here if ga was previously disabled and the user enabled consent
          if (window[disableKey] && consent) {
            window[disableKey] = !consent;
            pageView({ page_path: window.location.pathname });
          } else {
            window[disableKey] = !consent;
          }
        }

      }
    };
    maybeEnableGA();
    window.addEventListener("CookiebotOnAccept", maybeEnableGA);
    window.addEventListener("CookiebotOnDecline", maybeEnableGA);

    return () => {
      window.removeEventListener("CookiebotOnAccept", maybeEnableGA);
      window.removeEventListener("CookiebotOnDecline", maybeEnableGA);
    };
  }, []);

  return (
    <Router className="PrimaryRouter">
      <Base path="/">
        <Auth path="/auth/*" />
        <CreateOpportunity path="/opportunity/create/*" />
        {authUser ? (
          <>
            <Dashboard path="/*" />
            <OpportunityBoard key="board" path="/opportunities" />
            <OpportunityDetail path="/opportunity/:code" />
          </>
        ) : (
          <>
            <OpportunityBoard key="board" path="/opportunities" />
            <OpportunityDetail path="/opportunity/:code" />
            <Redirect from="/*" to="/opportunities" noThrow default />
          </>
        )}
        <CookiePolicyScreen path="/cookie-policy" />
      </Base>
    </Router>
  );
};

export default Main;
