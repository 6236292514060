import { Heading, Link, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React from "react";

import { AlertMessage } from "../../components/Form";
import config from "../../config";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import SignupForm, {
  RegisterUserInput,
} from "../../presentation/Auth/SignupForm";
import { Functional } from "../../types";

export interface CreateScreenProps {
  formik: FormikProps<RegisterUserInput>;
  globalError?: string;
}

const CreateScreen: Functional<CreateScreenProps> = ({
  formik,
  globalError,
}) => {
  return (
    <AuthContainer>
      <Heading as="h1" mb={4}>
        Set up your Create Jobs account
      </Heading>
      <Text mb={6}>
        Please use the email you used to register
      </Text>
      {globalError && (
        <AlertMessage status="error" mb={6}>
          {globalError}
        </AlertMessage>
      )}
      <SignupForm formik={formik} mb={6} />
      <Text fontSize="sm" color="gray.500">
        By clicking 'Finish Set Up', you agree to our{" "}
        <Link
          href={config.privacyPolicy} target="_blank"
        >
          Privacy Policy
        </Link>, including the{" "}
        <Link href={config.cookiePolicy} target="_blank">
          Cookie Use Policy
        </Link>
        .
      </Text>
    </AuthContainer>
  );
};

export default CreateScreen;
