import { Button, ChakraProps, Input } from "@chakra-ui/react";
import { IsEmail } from "class-validator";
import { FormikProps } from "formik";
import React from "react";

import { Field, Form } from "../../components/Form";
import config from "../../config";
import { ForgotPasswordInput } from "../../graphql";
import { Functional } from "../../types";

interface ForgotPasswordFormProps extends ChakraProps {
  formik: FormikProps<ForgotPasswordInput>;
}

export class ForgotPasswordFormInput {
  @IsEmail({}, { message: config.errors.email })
  email!: string;
}

const ForgotPasswordForm: Functional<ForgotPasswordFormProps> = ({
  formik,
  ...props
}) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = formik;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset} {...props}>
      <Field error={touched.email && errors.email} mb={6} isRequired>
        <Input
          type="email"
          name="email"
          placeholder="Your email address"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Email address"
        />
      </Field>
      <Button type="submit" isLoading={isSubmitting} isFullWidth>
        Send reset instructions
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
