import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ErrorIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M0 12V0h24v24H0V12zm21.7734 0V2.22656H2.22656V21.7734H21.7734V12zm-9.9609 5.9734c-.5795-.095-1.004-.6788-.9137-1.2566.0762-.4872.4558-.8668.943-.943.3285-.0513.704.0737.9449.3145.2408.2409.3658.6164.3145.9449-.0758.4846-.455.8659-.9371.9423-.1711.0271-.1734.0271-.3516-.0021zm-.9375-8.28199V6h2.25v7.3828h-2.25V9.69141z"
      fill="currentColor"
    />
  </Icon>
);

export default ErrorIcon;
