import { extendTheme } from "@chakra-ui/react";

type Dict = Record<string, any>;

const customTheme = extendTheme({
  fonts: {
    body: `'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    heading: `'Poppins', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  colors: {
    brand: {
      gradient:
        "linear-gradient(135deg, rgba(143,72,157,1) 0%, rgba(56,49,176,1) 100%)",
      pink: {
        50: "#fff4ff",
        100: "#ffecff",
        200: "#ffb3ff",
        300: "#ff8cff",
        400: "#ff66ff",
        500: "#ff40ff",
        600: "#cc33cc",
        700: "#992699",
        800: "#661a66",
        900: "#330d33",
      },
    },
    obsidian: {
      100: "#e6e6e6",
      200: "#999999",
      300: "#666666",
      400: "#333333",
      500: "#000000",
      600: "#333333",
      700: "#666666",
      800: "#999999",
      900: "#e6e6e6",
    },
    pearl: {
      100: "#333333",
      200: "#666666",
      300: "#999999",
      400: "#cccccc",
      500: "#ffffff",
      600: "#cccccc",
      700: "#999999",
      800: "#666666",
      900: "#333333",
    },
    red: {
      100: "#f4d9d6",
      200: "#e9b3ad",
      300: "#df8c83",
      400: "#d4665a",
      500: "#c94031",
      600: "#a13327",
      700: "#79261d",
      800: "#501a14",
      900: "#280d0a",
    },
  },
  layerStyles: {
    base: {
      color: "black",
      border: "2px solid",
      borderColor: "gray.400",
    },
    selected: {
      color: "brand.pink.500",
      borderColor: "brand.pink.500",
    },
  },
  components: {
    Link: {
      baseStyle: {
        borderBottom: "2px solid",
        pb: "2px",
        borderColor: "brand.pink.300",
        _hover: {
          borderColor: "brand.pink.500",
          textDecoration: "none",
        },
      },
      variants: {
        unstyled: {
          border: "none",
          pb: 0,
          _hover: {
            textDecoration: "underline",
          },
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "black",
        errorBorderColor: "red.500",
      },
      sizes: {
        lg: {
          field: {
            h: 14,
          },
          addon: {
            h: 14,
          },
        },
        md: {
          field: {
            h: 12,
          },
          addon: {
            h: 12,
          },
        },
      },
      variants: {
        outline: {
          field: {
            border: "2px solid",
            bg: "white",
            borderRadius: "none",
            _hover: {
              bg: "blackAlpha.50",
            },
            _focus: {
              bg: "blackAlpha.50",
            },
          },
        },
      },
    },
    Alert: {
      variants: {
        ghost: {
          container: {
            bg: "none",
            color: "black",
            p: 0,
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          },
          icon: {
            color: "black",
          },
          title: {
            fontSize: "3xl",
            lineHeight: 9,
            mt: 4,
            mb: 4,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: null,
        fontWeight: 700,
      },
      defaultProps: {
        colorScheme: "brand.pink",
      },
      variants: {
        ghost: {
          color: "black",
        },
        outline: {
          border: "2px solid",
          bg: "white",
          _hover: {
            bg: "blackAlpha.50",
          },
        },
        solid: (props: Dict) => {
          const { colorScheme } = props;

          if (colorScheme === "brand.pink") {
            return { color: "black" };
          }
        },
      },
      sizes: {
        lg: {
          h: "4.38rem",
          fontSize: "22px",
        },
        md: {
          h: "3.13rem",
          fontSize: { base: "lg", md: "md" },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: "1px",
          borderRadius: null,
          borderColor: "gray.400",

          _checked: {
            bg: "white",
            borderColor: "black",
            color: "brand.pink.500",

            _hover: {
              bg: "white",
              borderColor: "black",
            },

            // _disabled: {
            //   borderColor: mode("gray.200", "transparent")(props),
            //   bg: mode("gray.200", "whiteAlpha.300")(props),
            //   color: mode("gray.500", "whiteAlpha.500")(props),
            // },
          },
        },
        label: {
          ml: 6,
          lineHeight: 8,
        },
      },
      variants: {
        outline: {},
        solid: {
          control: {
            borderColor: "black",
          },
        },
      },
      sizes: {
        md: {
          control: {
            fontSize: "md",
            w: 6,
            h: 6,
          },
        },
        sm: {
          control: {
            w: 5,
            h: 5,
          },
        },
      },
      defaultProps: {
        variant: "outline",
      },
    },
    Select: {
      baseStyle: {
        field: {
          fontWeight: 700,
        },
        icon: {
          color: "brand.pink.500",
        },
      },
      sizes: {
        lg: {
          field: {
            borderRadius: null,
            h: "4.38rem",
            fontSize: "22px",
          },
          icon: {
            right: 16,
            width: "2em",
            height: "2em",
            transform: "translateX(-4px)",
          },
        },
        md: {
          field: {
            borderRadius: null,
            h: "3.13rem",
          },
          icon: {
            width: "1.4em",
            height: "1.4em",
          },
        },
        sm: {
          field: {
            borderRadius: null,
            h: "2em",
          },
          icon: {
            width: "1.2em",
            height: "1.2em",
          },
        },
      },
      variants: {
        outline: (props: Dict) => {
          const { colorScheme } = props;
          return {
            field: {
              bg: "white",
              border: "2px solid",
              borderColor:
                colorScheme === "black" ? "black" : `${colorScheme}.400`,
              _hover: {
                borderColor: "black",
              },
            },
          };
        },
        menu: {
          icon: {
            color: "black",
            transform: "rotate(-90deg) scale(1.5)",
          },
        },
      },
      defaultProps: { colorScheme: "gray" },
    },
    Popover: {
      baseStyle: {
        content: {
          borderRadius: null,
        },
      },
      variants: {
        full: {
          popper: {
            maxW: "100%",
          },
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: "green",
      },
    },
  },
});

export default customTheme;
