import { Box, Center, HStack, Heading, Link, Text } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import React from "react";

import RegisterForm from "../../presentation/Auth/RegisterForm";
import { Functional } from "../../types";

export interface RegisterScreenProps {
  globalError?: string;
}

export const RegisterScreen: Functional<RegisterScreenProps> = () => {
  return (
    <Center h="100%">
      <Box w="100%" maxW={{ md: "540px" }}  py={32} position="relative">
        <Heading as="h1" mb={6}>
          Register with A New Direction
        </Heading>
        <Text mb={6}>
          Before you can create your Create Jobs account, you first need to register with A New Direction (the charity that runs Create Jobs). This information will help us support you with relevant opportunities, advice and guidance
        </Text>
        <RegisterForm />
        <HStack spacing={4} align="center" justify="center">
          <Text>
            Already have an account?{" "}
            <Link as={ReachLink} to="/auth/signin">
              Log in
            </Link>
          </Text>
        </HStack>
      </Box>
    </Center>
  );
};

export default RegisterScreen;
