import { Container, Divider, Heading, Link, Text } from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import React from "react";

import config from "../../config";
import CompanyDetailsForm, {
  CompanyDetailsFormProps,
} from "../../presentation/Opportunity/CompanyDetailsForm";
import { Functional } from "../../types";

interface CompanyDetailsScreenProps
  extends RouteComponentProps,
    CompanyDetailsFormProps {}

const CompanyDetailsScreen: Functional<CompanyDetailsScreenProps> = ({
  path,
  ...props
}) => (
  <Container maxWidth="635px" my={16}>
    <CompanyDetailsForm {...props} />
    <Divider border="2px solid" mb={8} />
    <Heading as="h4" size="lg" mb={4}>
      Anything else we can do?
    </Heading>
    <Text>
      If you need anything else from us at Create Jobs, or have any suggestions
      as to how we can best help you, please email us at{" "}
      <Link variant="unstyled" href={`mailto:${config.supportEmail}`}>
        {config.supportEmail}
      </Link>
    </Text>
  </Container>
);

export default CompanyDetailsScreen;
