import { Alert, AlertDescription, AlertTitle } from "@chakra-ui/react";
import React from "react";

import SuccessIcon from "../../components/Icons/Success";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import { Functional } from "../../types";

const ForgotPasswordConfirmationScreen: Functional = () => {
  return (
    <AuthContainer>
      <Alert status="success" variant="ghost" mb={6}>
        <SuccessIcon boxSize="67px" />
        <AlertTitle mt={4} mb={1} fontSize="2xl">
          Password reset submitted!
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          If your email exists on the system, we will email you details on how
          to reset your password.
        </AlertDescription>
      </Alert>
    </AuthContainer>
  );
};

export default ForgotPasswordConfirmationScreen;
