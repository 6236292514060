import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const TheDotsIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11.948 0C5.36 0 0 5.36 0 11.948s5.36 11.948 11.948 11.948 11.948-5.36 11.948-11.948C23.897 5.36 18.537 0 11.948 0zm0 22.447c-5.789 0-10.499-4.71-10.499-10.499S6.159 1.45 11.948 1.45s10.499 4.71 10.499 10.498-4.71 10.499-10.499 10.499z"
    />
    <circle fill="currentColor" cx="11.948" cy="11.948" r="1.456" />
    <circle fill="currentColor" cx="11.948" cy="7.581" r="1.456" />
    <circle fill="currentColor" cx="7.581" cy="11.948" r="1.456" />
    <circle fill="currentColor" cx="16.315" cy="11.948" r="1.456" />
    <circle fill="currentColor" cx="11.948" cy="16.315" r="1.456" />
  </Icon>
);

export default TheDotsIcon;
