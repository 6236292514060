import { useNavigate } from "@reach/router";
import { Dispatch, useCallback, useMemo } from "react";

import { useQueryString } from "./useQueryString";

export interface FilterQuery {
  page?: number;
  filters?: {
    industries: string[];
    opportunities: string[];
  };
}

/**
 * Used to control the querystring paramaters for the opportunity board
 *
 * p - Page
 * f - Base64 encode json object of the filters: see OpportunityBoardContext
 *
 * Passing a key will persist the filters only to localstorage on change
 * @param key U
 */
export const useFilterQuery = (
  key?: string
): [FilterQuery, Dispatch<FilterQuery>] => {
  const navigate = useNavigate();
  const p = useQueryString("p");
  // Parse the page into a number and memoize it
  const page = useMemo(() => Number(p || 1), [p]);

  // Fetch the querystring, if none, use the local storage
  const filter =
    useQueryString("f") || (key && localStorage.getItem(key)) || null;
  const filters = useMemo(() => {
    if (!filter) {
      return {};
    }
    try {
      // decode and parse into object
      const decode = atob(filter);
      const parse = JSON.parse(decode);
      return parse;
    } catch (e) {
      return {};
    }
  }, [filter]);

  // Passing either page or filter (or both) will update the state of filters
  // Passed filters with empty arrays will be demeed to be removed
  const setFilters = useCallback(
    (props: Partial<FilterQuery>) => {
      const update = new URLSearchParams(window.location.search);

      // Check for empty array values
      const hasFilters =
        props.filters &&
        (props.filters.industries.length || props.filters.opportunities.length);

      if (hasFilters) {
        // Encode and optionally set to local storage
        const b64 = btoa(JSON.stringify(props.filters || filters));
        update.set("f", b64);
        if (key) {
          localStorage.setItem(key, b64);
        }
      } else if (key) {
        update.delete("f");
        localStorage.removeItem(key);
      }

      const query = update.toString();

      if (query) {
        // If no page is given use the one we currently have
        const pageToUpdate = props.page?.toString() || page.toString();
        if (pageToUpdate) {
          update.set("p", props.page?.toString() || page.toString());
        }

        const path = `${window.location.pathname}?${update.toString()}`;
        navigate(path);
      }
    },
    [navigate, filters, page, key]
  );

  return [{ page, filters }, setFilters];
};
