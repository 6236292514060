import { ApolloProvider } from "@apollo/client";
import { CSSReset, ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import * as React from "react";

import Main from "./Main";
import client from "./store";
import theme from "./theme";
import global from "./theme/global";

export const App = () => (
  <ApolloProvider client={client}>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Global styles={global} />
      <Main />
    </ChakraProvider>
  </ApolloProvider>
);
