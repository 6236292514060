import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import { isToday } from "date-fns";
import React from "react";

import { OpportunitySummaryFragment } from "../../graphql";
import { event } from "../../utils/analytics";
import Card from "../Card";

export interface OpportunityCardProps {
  opportunity: OpportunitySummaryFragment;
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({
  opportunity,
  ...props
}) => {
  const color = opportunity.image?.color || "#f5b640";
  const image = opportunity.image?.url;
  const fallbackImage = opportunity.industryImage?.url;
  const isBgImage = !image;
  return (
    <Box
      as={ReachLink}
      to={`/opportunity/${opportunity.code}`}
      position="relative"
      display="block"
      onClick={() =>
        event("opportunityCard", {
          event_category: "engagement",
          event_label: `${opportunity.title} - ${opportunity.company}`,
        })
      }
    >
      {isToday(opportunity.closesAt) && (
        <Center position="relative" zIndex={1}>
          <Flex
            position="absolute"
            top={-5}
            w="100%"
            h={10}
            justifyContent={{ base: "flex-start", md: "center" }}
            alignItems="center"
          >
            <Text
              bg="#ffff00"
              px={2}
              py={1}
              display="block"
              fontWeight={700}
              letterSpacing={0.5}
              textTransform="uppercase"
            >
              Closes today
            </Text>
          </Flex>
        </Center>
      )}
      <Card
        title={opportunity.title}
        color={color}
        image={image || fallbackImage}
        isImageBg={isBgImage}
        imgBoxProps={{
          border: "3px solid",
          borderColor: "white",
          boxSizing: "content-box",
        }}
        {...props}
      >
        <Box textAlign={{ md: "center" }} mt={{ md: -3 }}>
          <Text mb={2}>{opportunity.company}</Text>
          <Text>
            {opportunity.industries
              .map((industry) => industry.name)
              .join(" · ")}
            {opportunity.type && <>{` · ${opportunity.type}`}</>}
          </Text>
          <Text>
            {opportunity.location} · {opportunity.salary}
          </Text>
        </Box>
      </Card>
    </Box>
  );
};

export default OpportunityCard;
