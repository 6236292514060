import { Editor, EditorState, convertFromRaw } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import React, { useMemo } from "react";

import { Functional } from "../../types";

import { MarkdownBox } from "./MarkdownBox";

interface MarkdownProps {
  children: string;
}

const Markdown: Functional<MarkdownProps> = ({ children }) => {
  const editorState = useMemo(() => {
    return EditorState.createWithContent(
      convertFromRaw(markdownToDraft(children, { preserveNewlines: true }))
    );
  }, [children]);

  return (
    <MarkdownBox>
      <Editor onChange={() => {}} editorState={editorState} readOnly />
    </MarkdownBox>
  );
};

export default Markdown;
