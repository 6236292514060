import {
  ValidateBy,
  ValidationOptions,
  buildMessage,
  isInstance,
} from "class-validator";

const MIN_FILE_DIMENSIONS = "minFileDimensions";

/**
 * @param options [width: number, height?: number]
 */
export function MinFileDimensions(
  options: number[],
  validationOptions?: ValidationOptions
) {
  return ValidateBy(
    {
      name: MIN_FILE_DIMENSIONS,
      validator: {
        validate: async (value: File, args): Promise<boolean> => {
          try {
            let valid = isInstance(value, File);

            if (valid && options) {
              const [width, height] = options;
              const reader = new FileReader();
              const img = new Image();
              const result = new Promise<boolean>((resolve, reject) => {
                img.onload = () => {
                  if (valid && width) {
                    valid = width <= img.width;
                  }
                  if (valid && height) {
                    valid = height <= img.height;
                  }
                  resolve(valid);
                };
                img.onerror = reject;
                reader.onload = (e) => {
                  if (reader.result) {
                    img.src = reader.result as string;
                  }
                };
                reader.onerror = reject;
                reader.readAsDataURL(value);
              });

              return await result;
            }
            return valid;
          } catch (e) {
            return false;
          }
        },
        defaultMessage: buildMessage(
          (eachPrefix) =>
            eachPrefix +
            `$property must be bigger than ${options[0]}px${
              options[1] ? ` X ${options[1]}px` : ""
            }`,
          validationOptions
        ),
      },
    },
    validationOptions
  );
}
