import { AspectRatio, Box, Center, HStack, Heading, Image, Link, Text } from "@chakra-ui/react";
import React from "react";

import GoodGrowthHub from "../../assets/images/good-growth-hub.jpg";
import { Functional } from "../../types";

export interface RegisterSuccessScreenProps {
  globalError?: string;
}

const MailIcon: React.FC = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 25.5V53.125C8.5 53.6886 8.72388 54.2291 9.1224 54.6276C9.52091 55.0261 10.0614 55.25 10.625 55.25H57.375C57.9386 55.25 58.4791 55.0261 58.8776 54.6276C59.2761 54.2291 59.5 53.6886 59.5 53.125V25.5L34 8.5L8.5 25.5Z" stroke="#FF40FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M59.5 25.5L38.6484 40.375H29.3516L8.5 25.5" stroke="#FF40FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const VerifiedIcon: React.FC = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7981 55.2019C10.1442 52.5481 11.9038 46.9808 10.5481 43.7212C9.19231 40.4615 4 37.6058 4 34C4 30.3942 9.13462 27.6538 10.5481 24.2788C11.9615 20.9038 10.1442 15.4519 12.7981 12.7981C15.4519 10.1442 21.0192 11.9038 24.2788 10.5481C27.5385 9.19231 30.3942 4 34 4C37.6058 4 40.3462 9.13462 43.7212 10.5481C47.0962 11.9615 52.5481 10.1442 55.2019 12.7981C57.8558 15.4519 56.0962 21.0192 57.4519 24.2788C58.8077 27.5385 64 30.3942 64 34C64 37.6058 58.8654 40.3462 57.4519 43.7212C56.0385 47.0962 57.8558 52.5481 55.2019 55.2019C52.5481 57.8558 46.9808 56.0962 43.7212 57.4519C40.4615 58.8077 37.6058 64 34 64C30.3942 64 27.6538 58.8654 24.2788 57.4519C20.9038 56.0385 15.4519 57.8558 12.7981 55.2019Z" stroke="#FF40FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M46.6932 27.0762L29.7605 43.23L21.3086 35.1531" stroke="#FF40FF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

const RegisterSuccessMoreLink: React.FC<{ title: string, description: string, url: string, img?: string, color?: string, hoverBg?: string }> = ({ title, description, url, img = null, color = "white", hoverBg = "black" }) => {
  return (
    <AspectRatio ratio={1/1} w="50%" pos="relative" role="group">
      <Link as="a" href={url} variant="unstyled" target="_blank" textDecoration="none !important">
        <Box w="full" h="full" justifyContent="flex-start" alignItems="flex-start" bg={img ? "black" : "white" } _groupHover={{ bg: hoverBg }}>
          <Heading fontSize={20} as="h4" p={6} color={color} pos="relative" zIndex={1} _groupHover={{ display: "none" }}>
            {title}
          </Heading>
          <Heading p={6} fontSize={16} as="p" lineHeight={1.5} color="#fff" pos="relative" zIndex={1} d="none" _groupHover={{ display: "block" }}>
            {description}
          </Heading>
          <Box w={10} h={10} bg={color} pos="absolute" right={-5} bottom={-5} transform="rotate(45deg)" zIndex={2} _groupHover={{ bg: "white" }} />
          {img && (
            <Image
              src={img}
              w={{ base: "80%", md: "100%" }}
              ml="auto"
              pos="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              zIndex={0}
              opacity={0.5}
              _groupHover={{ display: "none" }}
            />
          )}
        </Box>
      </Link>
    </AspectRatio>
  )
}

export const RegisterSuccessScreen: Functional<RegisterSuccessScreenProps> = () => {
  return (
    <Center h="100%">
      <Box w="100%" maxW={{ md: "540px" }}  py={32} position="relative">
        <Heading as="h1" mb={6}>
          Thank you for registering with us!
        </Heading>
        <Heading fontSize={22} as="h3" mb={8}>
          What happens next?
        </Heading>
        <HStack my={6}>
          <Box w={68} mr={3}>
            <MailIcon />
          </Box>
          <Text>You will recieve a welcome email with a link to finish setting up your Create Jobs account. Make sure to use the same email address when setting up your account, and if you can't find the email please <b>check your junk</b>.</Text>
        </HStack>
        <HStack my={6}>
          <Box w={68} mr={3}>
            <VerifiedIcon />
          </Box>
          <Text>Verify your account and start applying for opportunities!</Text>
        </HStack>
        <HStack spacing={4} mt={10}>
          <Text>
            If you have any issues with registration, please{" "}
            <Link as="a" href="mailto:opportunities@anewdirection.org.uk?subject=Issue setting up Create Jobs account">
              contact us
            </Link>
          </Text>
        </HStack>
        <Heading fontSize={22} as="h3" mt={12} mb={4}>
          You may also be interested in...
        </Heading>
        <HStack spacing={4}>
          <RegisterSuccessMoreLink title="Good Growth Hub" description="Connecting east London businesses with young, diverse talent" img={GoodGrowthHub} url="https://goodgrowthhub.org.uk/" />
          <RegisterSuccessMoreLink title="A New Direction" description="Award-winning not-for-profit organisation and operator of the Create Jobs platform" color="#FF34CC" hoverBg="#FF34CC" url="https://www.anewdirection.org.uk/networks/create-jobs" />
        </HStack>
      </Box>
    </Center>
  );
};

export default RegisterSuccessScreen;
