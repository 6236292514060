import { RouteComponentProps } from "@reach/router";
import React from "react";

import { FadeInOut } from "../../components/Transition";
import { RegisterSuccessScreen } from "../../screens/Auth/RegisterSuccessScreen";
import { Functional } from "../../types";

const RegisterSuccessContainer: Functional<RouteComponentProps> = () => {
  return (
    <FadeInOut>
      <RegisterSuccessScreen />
    </FadeInOut>
  );
};

export default RegisterSuccessContainer;
