import { Box } from "@chakra-ui/react";
import { useSelect } from "downshift";
import React, { Dispatch, useMemo } from "react";

import PopoverSelect from "./PopoverSelect";

export interface SelectProps {
  items: Array<{ name: string; value: string | number | boolean | null }>;
  onChange?: Dispatch<any>;
  value?: string | number | boolean | null;
}

const Select: React.FC<SelectProps> = ({ items, onChange, value }) => {
  const selectItems = useMemo(() => items.map((item) => item.name), [items]);
  const selectedItem = useMemo(() => {
    const selected = items.find((item) => item.value === value);
    if (selected) {
      return selected.name;
    }
    return null;
  }, [items, value]);
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: selectItems,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      if (onChange) {
        const selected = items.find((item) => item.name === selectedItem);
        if (selected) {
          onChange(selected.value);
        }
      }
    },
  });

  return (
    <PopoverSelect
      label={selectedItem || "Please select an option"}
      menuProps={getMenuProps()}
      labelProps={getLabelProps()}
      buttonProps={getToggleButtonProps()}
      isOpen={isOpen}
    >
      {selectItems.map((item, index) => (
        <Box
          as="li"
          key={`${item}${index}`}
          {...getItemProps({ item, index })}
          p={4}
          bg={highlightedIndex === index ? "gray.100" : "white"}
        >
          {item}
        </Box>
      ))}
    </PopoverSelect>
  );
};

export default Select;
