import { InputProps } from "@chakra-ui/react";
import { DatePicker } from "@reecelucas/react-datepicker";
import { startOfDay } from "date-fns";
import React, { Dispatch } from "react";

import { Functional } from "../../../types";

import DatePopover from "./DatePopover";

interface DateInputProps extends Omit<InputProps, "onSelect"> {
  onSelect: Dispatch<Date>;
}

const DateInput: Functional<DateInputProps> = ({ onSelect, ...props }) => {
  return (
    <DatePicker minDate={startOfDay(new Date())} onSelect={onSelect}>
      <DatePopover {...props} />
    </DatePicker>
  );
};

export default DateInput;
