import { forwardRef, useBreakpoint } from "@chakra-ui/react";
import React from "react";

import FilterDesktop from "./FilterDesktop";
import FilterMobile from "./FilterMobile";

export const Filters = forwardRef<{}, "div">(function Filters(props, ref) {
  const breakpoint = useBreakpoint();

  if (!breakpoint) {
    return null;
  }

  return (
    <div ref={ref}>
      {["lg", "xl"].includes(breakpoint) ? <FilterDesktop /> : <FilterMobile />}
    </div>
  );
});

export default Filters;
