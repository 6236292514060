import { Box, Center, Flex } from "@chakra-ui/react";
import { Router, useLocation } from "@reach/router";
import React from "react";

import FadeInOut from "../../components/Transition/FadeInOut";

const DashboardBase: React.FC = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Flex position="relative" flexDirection="column">
      <Box
        position={{ base: "relative", md: "absolute" }}
        w="100%"
        h={{ base: 175, md: 500 }}
        top={0}
        bg="brand.gradient"
      />
      <FadeInOut>
        <Center key={pathname} h="100%">
          <Router location={location} primary={false} className="Router">
            {children}
          </Router>
        </Center>
      </FadeInOut>
    </Flex>
  );
};

export default DashboardBase;
