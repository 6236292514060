import { Spinner } from "@chakra-ui/react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { isUUID } from "class-validator";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";

import { FadeInOut } from "../../components/Transition";
import config from "../../config";
import {
  RequestVerificationInput,
  useRequestNewVerificationMutation,
  useVerifyAccountMutation,
} from "../../graphql";
import { VerifyAccountFormInput } from "../../presentation/Auth/VerifyAccountForm";
import { CreateSuccessScreen, VerifyScreen } from "../../screens/Auth";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

export interface VerifyContainerProps extends RouteComponentProps {
  token?: string;
}

const VerifyContainer: Functional<VerifyContainerProps> = ({ token }) => {
  const navigate = useNavigate();
  const [globalError, setGlobalError] = useState(
    !isUUID(token, 4) ? config.errors.invalidUuid : ""
  );
  const [verify, { loading }] = useVerifyAccountMutation();
  const [request, { data }] = useRequestNewVerificationMutation();

  const formik = useFormik<RequestVerificationInput>({
    initialValues: {
      email: "",
    },
    validate: validator(VerifyAccountFormInput),
    onSubmit: (values) => {
      request({
        variables: {
          user: values,
        },
      })
        .then(() => {
          event("requestNewVerification", { event_category: "engagement" });
        })
        .catch((error) => {
          const collection = error?.graphQLErrors[0].extensions?.response || {};

          if (error.message === "BAD_USER_INPUT") {
            if (collection.token) {
              setGlobalError(collection.token);
              delete collection.token;
            }
            return formik.setErrors(collection);
          }

          event("requestNewVerificationFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "An error has occurred. Please request a new verification link."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    // If there is not a token redirect to the signin page
    if (!token) {
      navigate("/auth/signin");
      return;
    }

    if (!token || !isUUID(token, 4)) {
      return;
    }

    verify({
      variables: {
        user: {
          token,
        },
      },
    })
      .then((result) => {
        if (result.data?.verifyAccount) {
          event("verified", { event_category: "engagement" });

          navigate("/auth/signin", {
            state: {
              notice: {
                type: "success",
                message: "Your account has been verified. You can now sign in.",
              },
            },
          });
        } else {
          event("verifiedFailure", {
            event_category: "engagement",
            event_label: "INVALID_OR_EXPIRED",
          });

          setGlobalError(
            "Your verification token is either invalid or expired. Please request a new verification link."
          );
        }
      })
      .catch((e) => {
        if (e.message !== "BAD_USER_INPUT") {
          setGlobalError("An error has occurred. Please try again later.");
        }
        event("verifiedFailure", {
          event_category: "engagement",
          event_label: e.message || "UNKNOWN_ERROR",
        });
      });
  }, [token, verify, navigate]);

  return (
    <FadeInOut>
      {loading ? (
        <Spinner
          key="loading"
          thickness="4px"
          speed="1s"
          emptyColor="gray.200"
          color="brand.pink.500"
          size="xl"
        />
      ) : !data?.requestNewVerification ? (
        <VerifyScreen key="verify" formik={formik} globalError={globalError} />
      ) : (
        <CreateSuccessScreen key="success" />
      )}
    </FadeInOut>
  );
};

export default VerifyContainer;
