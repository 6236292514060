import { forwardRef } from "@chakra-ui/react";
import React from "react";

import MotionImage, { MotionImageProps } from "./MotionImage";

const FadeInImage = forwardRef<MotionImageProps, "img">(function FadeInImage(
  props,
  ref
) {
  return (
    <MotionImage
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      {...props}
    />
  );
});

export default FadeInImage;
