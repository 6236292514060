import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { RemoveScroll } from "react-remove-scroll";

import Logo from "../../assets/images/logo.svg";
import { useAuthUser } from "../../operations/user";
import { Functional } from "../../types";
import MenuIcon from "../Icons/Menu";

const MobileNavLink = chakra(Link, {
  baseStyle: {
    fontSize: { base: "2xl", sm: "3xl" },
    fontWeight: 700,
    mb: 6,
    _hover: {
      color: "brand.pink.500",
    },
  },
});

interface MobileHeaderProps {}

const MobileHeader: Functional<MobileHeaderProps> = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [isFixed, setFixed] = useState(false);
  const authUser = useAuthUser();

  const onClick = () => {
    setFixed(false);
    onToggle();
  };

  useEffect(() => {
    if (!isFixed) {
      setImmediate(() => window.scrollTo({ top: 0, behavior: "smooth" }));
    }
  }, [isFixed]);

  return (
    <Box mt={isFixed ? "56px" : 0}>
      <Flex
        minHeight="56px"
        flexDirection="column"
        position={isFixed ? "fixed" : "relative"}
        w="100%"
        zIndex={100}
        top={0}
        bg="black"
        color="white"
        transform="translateZ(0)"
      >
        <Flex
          py={"19px"}
          px={6}
          position="relative"
          alignItems="flex-start"
          justifyContent="space-between"
          layout
        >
          <Flex
            layout
            justifyContent="flexStart"
            flex={1}
          >
            <Link
              as={ReachLink}
              variant="unstyled"
              display="inline-block"
              to="/"
            >
              <Box
                as="img"
                src={Logo}
                alt="Create Jobs"
                w={77}
              />
            </Link>
          </Flex>
          <Box
            animate={{
              width: !isOpen ? "calc(100% - 77px - 32px)" : 0,
              transition: { duration: 0.3 },
            }}
            layout
          />
          <Button
            alignSelf="flex-start"
            variant="ghost"
            colorScheme="obsidian"
            boxSize="32px"
            p={0}
            minW="auto"
            onClick={onClick}
          >
            <MenuIcon boxSize="22px" color="white" />
          </Button>
        </Flex>
        <RemoveScroll
          enabled={isOpen}
          removeScrollBar
          style={{
            height: isOpen ? "calc(100vh - 76px)" : 0,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            transition: "height 0.3s linear",
          }}
        >
          <AnimatePresence>
            {isOpen && (
              <Center
                as={motion.div}
                onAnimationComplete={() => {
                  !isOpen && setFixed(isOpen);
                }}
                flexDirection="column"
                flex="1 0 auto"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { delay: 0.5, duration: 0.5 },
                }}
                exit={{ opacity: 0 }}
                mb={24}
                mt={6}
              >
                <MobileNavLink
                  as={ReachLink}
                  to={authUser ? "/opportunities" : "/"}
                  onClick={onClick}
                >
                  Opportunity Board
                </MobileNavLink>
                <MobileNavLink
                  as={ReachLink}
                  to="/opportunity/create"
                  onClick={onClick}
                >
                  Post an opportunity
                </MobileNavLink>
                {authUser ? (
                  <>
                    <MobileNavLink as={ReachLink} to="/" onClick={onClick}>
                      Dashboard
                    </MobileNavLink>
                    <MobileNavLink
                      as={ReachLink}
                      to="/auth/signout"
                      onClick={onClick}
                    >
                      Sign out
                    </MobileNavLink>
                  </>
                ) : (
                  <>
                    <MobileNavLink
                      as={ReachLink}
                      to="/auth/signin"
                      onClick={onClick}
                    >
                      Log in
                    </MobileNavLink>
                    <MobileNavLink
                      as={ReachLink}
                      to="/auth/signup"
                      onClick={onClick}
                    >
                      Register
                    </MobileNavLink>
                  </>
                )}
              </Center>
            )}
          </AnimatePresence>
        </RemoveScroll>
      </Flex>
    </Box>
  );
};

export default MobileHeader;
