import { RouteComponentProps } from "@reach/router";
import React from "react";

import { useUserProfileQuery } from "../../graphql";
import { SettingsScreen } from "../../screens/Dashboard";

const Settings: React.FC<RouteComponentProps> = () => {
  const { data, loading } = useUserProfileQuery();
  return <SettingsScreen profile={data?.profile} loading={loading} />;
};

export default Settings;
