import { RouteComponentProps, useNavigate } from "@reach/router";
import { useFormik } from "formik";
import React, { useState } from "react";

import { FadeInOut } from "../../components/Transition";
import { useRegisterMutation } from "../../graphql";
import {
  RegisterUserInput,
  SignupFormInput,
} from "../../presentation/Auth/SignupForm";
import { CreateScreen, CreateSuccessScreen } from "../../screens/Auth";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

const CreateContainer: Functional<RouteComponentProps> = () => {
  const navigate = useNavigate();
  const [create, { data }] = useRegisterMutation();
  const [globalError, setGlobalError] = useState("");

  const formik = useFormik<RegisterUserInput>({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: validator(SignupFormInput),
    onSubmit: (values) => {
      create({
        variables: {
          user: {
            email: values.email,
            password: values.password,
          },
        },
      })
        .then(() => {
          event("signup");
        })
        .catch((error) => {
          if (error.message === "BAD_USER_INPUT") {
            return formik.setErrors(
              error.graphQLErrors[0].extensions?.response || {}
            );
          }

          if (error.message === "USER_ALREADY_EXISTS") {
            return navigate("/auth/exists");
          }

          if (error.message === "USER_NOT_VERIFIED") {
            return navigate("/auth/verification");
          }

          if (error.message === "USER_NOT_ENROLLED") {
            return navigate("/auth/ineligible");
          }

          event("signupFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "We are unable to create your account at this time. Please try again later."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <FadeInOut>
      {!data ? (
        <CreateScreen formik={formik} globalError={globalError} />
      ) : (
        <CreateSuccessScreen />
      )}
    </FadeInOut>
  );
};

export default CreateContainer;
