import { Box, HStack, Progress } from "@chakra-ui/react";
import React, { Children, cloneElement, isValidElement, useMemo } from "react";

interface StepperProps {
  activeStep: number;
  goTo: (step: number) => void;
}

const Stepper: React.FC<StepperProps> = ({ activeStep, goTo, children }) => {
  const steps = useMemo(() => {
    return Children.map(children, (child, index) => {
      return isValidElement(child)
        ? cloneElement(child, {
            index,
            isActive: activeStep === index,
            isComplete: activeStep >= index,
            onClick: () => goTo(index),
            ...child.props,
          })
        : null;
    });
  }, [children, activeStep, goTo]);
  return (
    <Box w="100%">
      <Progress
        size="sm"
        colorScheme="brand.pink"
        value={Math.min(
          ((activeStep + 0.9) / Children.count(children)) * 100,
          100
        )}
        bg="black"
      />
      <HStack w="100%" spacing={0}>
        {steps}
      </HStack>
    </Box>
  );
};

export default Stepper;
