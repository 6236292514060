import { Button, ChakraProps, Input, Text } from "@chakra-ui/react";
import { IsEmail, Matches, MinLength } from "class-validator";
import { FormikProps } from "formik";
import React from "react";

import { Field, Form, NoticeField, PasswordInput } from "../../components/Form";
import config from "../../config";
import { UserInput } from "../../graphql";
import { Functional } from "../../types";
import { IsEqualTo } from "../../utils/decorators";

export interface RegisterUserInput extends UserInput {
  confirmPassword: string;
}

interface SignupFormProps extends ChakraProps {
  formik: FormikProps<RegisterUserInput>;
}

export class SignupFormInput {
  @IsEmail({}, { message: config.errors.email })
  email!: string;

  @MinLength(9, { message: config.errors.password })
  @Matches(/[0-9]/, { message: config.errors.password })
  @Matches(/[a-zA-Z]/, { message: config.errors.password })
  password!: string;

  @IsEqualTo("password", {
    message: "Please make sure your passwords match",
  })
  confirmPassword!: string;
}

const SignupForm: Functional<SignupFormProps> = ({ formik, ...props }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleReset,
    handleSubmit,
  } = formik;

  return (
    <Form onSubmit={handleSubmit} onReset={handleReset} {...props}>
      <Field error={touched.email && errors.email} mb={6} isRequired>
        <Input
          type="email"
          name="email"
          placeholder="Your email address"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Email address"
        />
      </Field>
      <NoticeField
        error={touched.password && errors.password}
        notice={
          <Text>
            Password must contain a{" "}
            <Text as="span" fontWeight="bold">
              letter
            </Text>{" "}
            and a{" "}
            <Text as="span" fontWeight="bold">
              number
            </Text>{" "}
            and be a minimum of{" "}
            <Text as="span" fontWeight="bold">
              9 characters
            </Text>
          </Text>
        }
        isRequired
        mb={6}
      >
        <PasswordInput
          name="password"
          placeholder="Create a password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Password"
        />
      </NoticeField>
      <Field error={touched.confirmPassword && errors.confirmPassword} mb={6}>
        <PasswordInput
          name="confirmPassword"
          placeholder="Confirm password"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          aria-label="Password Confirmation"
        />
      </Field>
      <Button type="submit" isLoading={isSubmitting} isFullWidth>
        Finish Set Up
      </Button>
    </Form>
  );
};

export default SignupForm;
