import { Alert, AlertIcon, AlertProps } from "@chakra-ui/react";
import React from "react";

const AlertMessage: React.FC<AlertProps> = ({ children, ...props }) => (
  <Alert variant="subtle" {...props}>
    <AlertIcon boxSize="40px" />
    {children}
  </Alert>
);

export default AlertMessage;
