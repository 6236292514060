const config = {
  contactEmail: "opportunities@anewdirection.org.uk",
  supportEmail: "opportunities@anewdirection.org.uk",
  privacyPolicy: "https://www.anewdirection.org.uk/privacy-policy",
  cookiePolicy: "https://www.anewdirection.org.uk/cookies",
  newsletter: "https://www.createjobslondon.org/subscribe",
  aNewDirection: "https://www.anewdirection.org.uk",
  errors: {
    email: "Please enter a valid email address",
    password:
      "Please create a valid password containing a letter, a number and a minimum of 9 characters",
    invalidUuid:
      "This url is invalid. Make sure you copied the full url in your instructional email.",
  },
  social: {
    twitter: "https://twitter.com/CREATE_Jobs",
    instagram: "https://www.instagram.com/create_jobs",
    facebook: "https://www.facebook.com/createjobsldn/",
    linkedin: "https://www.linkedin.com/company/create-jobs/",
    youtube: "https://www.youtube.com/channel/UCOAxWmWoswiKQQjb3SGogng",
    theDots: "https://the-dots.com/pages/create-jobs-71945",
  },
};

export default config;
