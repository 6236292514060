import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: Date;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CreateOpportunityInput = {
  applicationUri: Scalars['String'];
  closesAt: Scalars['DateTime'];
  company: Scalars['String'];
  description: Scalars['String'];
  email: Scalars['String'];
  hours?: Maybe<Scalars['String']>;
  industries: Array<Scalars['Float']>;
  location: Scalars['String'];
  logo?: Maybe<UploadImageInput>;
  policy: Scalars['Boolean'];
  salary: Scalars['String'];
  title: Scalars['String'];
  type: OpportunityType;
};


export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ImageDto = {
  __typename?: 'ImageDto';
  color?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};


export type ImageDtoUrlArgs = {
  bg?: Maybe<Scalars['String']>;
  dpr?: Maybe<Scalars['Float']>;
  ext?: Maybe<Scalars['String']>;
  extend?: Maybe<Scalars['Boolean']>;
  gravity?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  resize?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
};

export type IndustryDto = {
  __typename?: 'IndustryDto';
  id: Scalars['Int'];
  image?: Maybe<ImageDto>;
  name: Scalars['String'];
};

export type LoginResult = {
  __typename?: 'LoginResult';
  access: Scalars['String'];
  refresh: Scalars['String'];
  user: UserDto;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOpportunity: OpportunityDto;
  forgotPassword: Scalars['Boolean'];
  login: LoginResult;
  logout: Scalars['Boolean'];
  refresh: LoginResult;
  register: Scalars['Boolean'];
  requestNewVerification: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  updateAdminProfile: UserProfileDto;
  updateYoungPersonProfile: UserProfileDto;
  verifyAccount: Scalars['Boolean'];
};


export type MutationCreateOpportunityArgs = {
  opportunity: CreateOpportunityInput;
};


export type MutationForgotPasswordArgs = {
  user: ForgotPasswordInput;
};


export type MutationLoginArgs = {
  user: UserInput;
};


export type MutationRegisterArgs = {
  user: UserInput;
};


export type MutationRequestNewVerificationArgs = {
  user: RequestVerificationInput;
};


export type MutationResetPasswordArgs = {
  reset: ResetPasswordInput;
};


export type MutationUpdateAdminProfileArgs = {
  profile: UpdateAdminInput;
};


export type MutationUpdateYoungPersonProfileArgs = {
  profile: UpdateYoungPersonInput;
};


export type MutationVerifyAccountArgs = {
  user: VerifyAccountInput;
};

export type OpportunitiesEdge = {
  __typename?: 'OpportunitiesEdge';
  cursor: Scalars['String'];
  node: OpportunityDto;
};

export type OpportunityDto = {
  __typename?: 'OpportunityDto';
  applicationUri: Scalars['String'];
  closesAt: Scalars['DateTime'];
  code: Scalars['String'];
  company: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  email: Scalars['String'];
  hours?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<ImageDto>;
  industries: Array<IndustryDto>;
  industryImage?: Maybe<ImageDto>;
  location: Scalars['String'];
  salary: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export enum OpportunityType {
  APPRENTICESHIP = 'APPRENTICESHIP',
  BURSARY = 'BURSARY',
  COMMISSION = 'COMMISSION',
  FIXED_TERM = 'FIXED_TERM',
  FREELANCE = 'FREELANCE',
  FULL_TIME = 'FULL_TIME',
  GRANT = 'GRANT',
  INTERNSHIP = 'INTERNSHIP',
  MISC = 'MISC',
  PART_TIME = 'PART_TIME',
  TEMPORARY = 'TEMPORARY',
  TRAINEESHIP = 'TRAINEESHIP'
}

export type OpportunityTypeDto = {
  __typename?: 'OpportunityTypeDto';
  id: OpportunityType;
  name: Scalars['String'];
};

export type PageData = {
  __typename?: 'PageData';
  count: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type PaginatedOpportunity = {
  __typename?: 'PaginatedOpportunity';
  edges: Array<OpportunitiesEdge>;
  pageData: PageData;
  pageInfo: PageInfo;
};

export type Profile = YoungPersonDto;

export type Query = {
  __typename?: 'Query';
  allIndustries: Array<IndustryDto>;
  allOpportunityTypes: Array<OpportunityTypeDto>;
  latestOpportunities: PaginatedOpportunity;
  opportunity: OpportunityDto;
  profile: UserProfileDto;
  relatedOpportunites: Array<OpportunityDto>;
};


export type QueryLatestOpportunitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  industries?: Maybe<Array<Scalars['Int']>>;
  last?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<OpportunityType>>;
};


export type QueryOpportunityArgs = {
  id: Scalars['String'];
};


export type QueryRelatedOpportunitesArgs = {
  count: Scalars['Int'];
  id: Scalars['String'];
};

export type RequestVerificationInput = {
  email: Scalars['String'];
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type UpdateAdminInput = {
  image?: Maybe<UploadImageInput>;
};

export type UpdateYoungPersonInput = {
  emailAlerts?: Maybe<Scalars['Boolean']>;
  image?: Maybe<UploadImageInput>;
  openToWork?: Maybe<Scalars['Boolean']>;
};


export type UploadImageInput = {
  color?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type UserDto = {
  __typename?: 'UserDto';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['Int'];
  image: ImageDto;
  lastName: Scalars['String'];
  role: UserRole;
};

export type UserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type UserProfileDto = {
  __typename?: 'UserProfileDto';
  data?: Maybe<Profile>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: Maybe<ImageDto>;
  lastName: Scalars['String'];
};

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  YOUNG_PERSON = 'YOUNG_PERSON'
}

export type VerifyAccountInput = {
  token: Scalars['String'];
};

export type YoungPersonDto = {
  __typename?: 'YoungPersonDto';
  emailAlerts: Scalars['Boolean'];
  openToWork: Scalars['Boolean'];
};

export type LoginResultFragment = { __typename?: 'LoginResult', access: string, refresh: string, user: (
    { __typename?: 'UserDto' }
    & UserFragment
  ) };

export type LoginMutationVariables = Exact<{
  user: UserInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: (
    { __typename?: 'LoginResult' }
    & LoginResultFragment
  ) };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RegisterMutationVariables = Exact<{
  user: UserInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type RefreshMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshMutation = { __typename?: 'Mutation', refresh: (
    { __typename?: 'LoginResult' }
    & LoginResultFragment
  ) };

export type ForgotPasswordMutationVariables = Exact<{
  user: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordMutationVariables = Exact<{
  reset: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type VerifyAccountMutationVariables = Exact<{
  user: VerifyAccountInput;
}>;


export type VerifyAccountMutation = { __typename?: 'Mutation', verifyAccount: boolean };

export type RequestNewVerificationMutationVariables = Exact<{
  user: RequestVerificationInput;
}>;


export type RequestNewVerificationMutation = { __typename?: 'Mutation', requestNewVerification: boolean };

export type ImageFragment = { __typename?: 'ImageDto', id: string, url: string, mime?: Maybe<string>, color?: Maybe<string> };

export type OpportunityImageFragment = { __typename?: 'ImageDto', id: string, url: string, color?: Maybe<string> };

export type ProfileImageFragment = { __typename?: 'ImageDto', id: string, url: string };

export type IndustryFragment = { __typename?: 'IndustryDto', id: number, name: string };

export type OpportunityFragment = { __typename?: 'OpportunityDto', id: number, code: string, company: string, title: string, type: string, location: string, salary: string, hours?: Maybe<string>, closesAt: Date, applicationUri: string, description: string, industries: Array<(
    { __typename?: 'IndustryDto' }
    & IndustryFragment
  )>, industryImage?: Maybe<{ __typename?: 'ImageDto', url: string }>, image?: Maybe<(
    { __typename?: 'ImageDto' }
    & OpportunityImageFragment
  )> };

export type OpportunitySummaryFragment = { __typename?: 'OpportunityDto', id: number, code: string, company: string, title: string, type: string, location: string, salary: string, closesAt: Date, image?: Maybe<(
    { __typename?: 'ImageDto' }
    & OpportunityImageFragment
  )>, industryImage?: Maybe<{ __typename?: 'ImageDto', url: string }>, industries: Array<(
    { __typename?: 'IndustryDto' }
    & IndustryFragment
  )> };

export type OpportunityTypeFragment = { __typename?: 'OpportunityTypeDto', id: OpportunityType, name: string };

export type LatestOpportunitiesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  industries?: Maybe<Array<Scalars['Int']>>;
  types?: Maybe<Array<OpportunityType>>;
}>;


export type LatestOpportunitiesQuery = { __typename?: 'Query', latestOpportunities: { __typename?: 'PaginatedOpportunity', edges: Array<{ __typename?: 'OpportunitiesEdge', cursor: string, node: (
        { __typename?: 'OpportunityDto' }
        & OpportunitySummaryFragment
      ) }>, pageData: { __typename?: 'PageData', count: number, limit: number, offset: number } } };

export type OpportunityQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OpportunityQuery = { __typename?: 'Query', opportunity: (
    { __typename?: 'OpportunityDto' }
    & OpportunityFragment
  ), relatedOpportunites: Array<(
    { __typename?: 'OpportunityDto' }
    & OpportunitySummaryFragment
  )> };

export type OpportunityBoardQueryVariables = Exact<{ [key: string]: never; }>;


export type OpportunityBoardQuery = { __typename?: 'Query', allIndustries: Array<(
    { __typename?: 'IndustryDto' }
    & IndustryFragment
  )>, allOpportunityTypes: Array<(
    { __typename?: 'OpportunityTypeDto' }
    & OpportunityTypeFragment
  )> };

export type CreateOpportunityMutationVariables = Exact<{
  opportunity: CreateOpportunityInput;
}>;


export type CreateOpportunityMutation = { __typename?: 'Mutation', createOpportunity: { __typename?: 'OpportunityDto', id: number } };

export type UserFragment = { __typename?: 'UserDto', id: number, email: string, firstName: string, lastName: string };

export type YoungPersonFragment = { __typename?: 'YoungPersonDto', openToWork: boolean, emailAlerts: boolean };

export type UserProfileFragment = { __typename?: 'UserProfileDto', firstName: string, lastName: string, email: string, image?: Maybe<(
    { __typename?: 'ImageDto' }
    & ProfileImageFragment
  )>, data?: Maybe<(
    { __typename?: 'YoungPersonDto' }
    & YoungPersonFragment
  )> };

export type UserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UserProfileQuery = { __typename?: 'Query', profile: (
    { __typename?: 'UserProfileDto' }
    & UserProfileFragment
  ), latestOpportunities: { __typename?: 'PaginatedOpportunity', edges: Array<{ __typename?: 'OpportunitiesEdge', node: (
        { __typename?: 'OpportunityDto' }
        & OpportunitySummaryFragment
      ) }> } };

export type UpdateYoungPersonProfileMutationVariables = Exact<{
  profile: UpdateYoungPersonInput;
}>;


export type UpdateYoungPersonProfileMutation = { __typename?: 'Mutation', updateYoungPersonProfile: (
    { __typename?: 'UserProfileDto' }
    & UserProfileFragment
  ) };

export type UpdateAdminProfileMutationVariables = Exact<{
  profile: UpdateAdminInput;
}>;


export type UpdateAdminProfileMutation = { __typename?: 'Mutation', updateAdminProfile: (
    { __typename?: 'UserProfileDto' }
    & UserProfileFragment
  ) };

export const UserFragmentDoc = gql`
    fragment User on UserDto {
  id
  email
  firstName
  lastName
}
    `;
export const LoginResultFragmentDoc = gql`
    fragment LoginResult on LoginResult {
  user {
    ...User
  }
  access
  refresh
}
    ${UserFragmentDoc}`;
export const ImageFragmentDoc = gql`
    fragment Image on ImageDto {
  id
  url
  mime
  color
}
    `;
export const IndustryFragmentDoc = gql`
    fragment Industry on IndustryDto {
  id
  name
}
    `;
export const OpportunityImageFragmentDoc = gql`
    fragment OpportunityImage on ImageDto {
  id
  url(width: 400, height: 400, resize: "fit", extend: true, bg: "ffffff")
  color
}
    `;
export const OpportunityFragmentDoc = gql`
    fragment Opportunity on OpportunityDto {
  id
  code
  company
  title
  type
  location
  salary
  hours
  closesAt
  applicationUri
  description
  industries {
    ...Industry
  }
  industryImage {
    url(width: 1440, height: 530, resize: "fill")
  }
  image {
    ...OpportunityImage
  }
}
    ${IndustryFragmentDoc}
${OpportunityImageFragmentDoc}`;
export const OpportunitySummaryFragmentDoc = gql`
    fragment OpportunitySummary on OpportunityDto {
  id
  code
  company
  title
  type
  location
  salary
  closesAt
  image {
    ...OpportunityImage
  }
  industryImage {
    url(width: 400)
  }
  industries {
    ...Industry
  }
}
    ${OpportunityImageFragmentDoc}
${IndustryFragmentDoc}`;
export const OpportunityTypeFragmentDoc = gql`
    fragment OpportunityType on OpportunityTypeDto {
  id
  name
}
    `;
export const ProfileImageFragmentDoc = gql`
    fragment ProfileImage on ImageDto {
  id
  url(width: 400, height: 400, resize: "fill")
}
    `;
export const YoungPersonFragmentDoc = gql`
    fragment YoungPerson on YoungPersonDto {
  openToWork
  emailAlerts
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfileDto {
  firstName
  lastName
  email
  image {
    ...ProfileImage
  }
  data {
    ... on YoungPersonDto {
      ...YoungPerson
    }
  }
}
    ${ProfileImageFragmentDoc}
${YoungPersonFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($user: UserInput!) {
  login(user: $user) {
    ...LoginResult
  }
}
    ${LoginResultFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($user: UserInput!) {
  register(user: $user)
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RefreshDocument = gql`
    mutation Refresh {
  refresh {
    ...LoginResult
  }
}
    ${LoginResultFragmentDoc}`;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, baseOptions);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($user: ForgotPasswordInput!) {
  forgotPassword(user: $user)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($reset: ResetPasswordInput!) {
  resetPassword(reset: $reset)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      reset: // value for 'reset'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const VerifyAccountDocument = gql`
    mutation VerifyAccount($user: VerifyAccountInput!) {
  verifyAccount(user: $user)
}
    `;
export type VerifyAccountMutationFn = Apollo.MutationFunction<VerifyAccountMutation, VerifyAccountMutationVariables>;

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useVerifyAccountMutation(baseOptions?: Apollo.MutationHookOptions<VerifyAccountMutation, VerifyAccountMutationVariables>) {
        return Apollo.useMutation<VerifyAccountMutation, VerifyAccountMutationVariables>(VerifyAccountDocument, baseOptions);
      }
export type VerifyAccountMutationHookResult = ReturnType<typeof useVerifyAccountMutation>;
export type VerifyAccountMutationResult = Apollo.MutationResult<VerifyAccountMutation>;
export type VerifyAccountMutationOptions = Apollo.BaseMutationOptions<VerifyAccountMutation, VerifyAccountMutationVariables>;
export const RequestNewVerificationDocument = gql`
    mutation RequestNewVerification($user: RequestVerificationInput!) {
  requestNewVerification(user: $user)
}
    `;
export type RequestNewVerificationMutationFn = Apollo.MutationFunction<RequestNewVerificationMutation, RequestNewVerificationMutationVariables>;

/**
 * __useRequestNewVerificationMutation__
 *
 * To run a mutation, you first call `useRequestNewVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestNewVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestNewVerificationMutation, { data, loading, error }] = useRequestNewVerificationMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRequestNewVerificationMutation(baseOptions?: Apollo.MutationHookOptions<RequestNewVerificationMutation, RequestNewVerificationMutationVariables>) {
        return Apollo.useMutation<RequestNewVerificationMutation, RequestNewVerificationMutationVariables>(RequestNewVerificationDocument, baseOptions);
      }
export type RequestNewVerificationMutationHookResult = ReturnType<typeof useRequestNewVerificationMutation>;
export type RequestNewVerificationMutationResult = Apollo.MutationResult<RequestNewVerificationMutation>;
export type RequestNewVerificationMutationOptions = Apollo.BaseMutationOptions<RequestNewVerificationMutation, RequestNewVerificationMutationVariables>;
export const LatestOpportunitiesDocument = gql`
    query LatestOpportunities($first: Int, $last: Int, $after: String, $before: String, $industries: [Int!], $types: [OpportunityType!]) {
  latestOpportunities(
    first: $first
    last: $last
    after: $after
    before: $before
    industries: $industries
    types: $types
  ) {
    edges {
      cursor
      node {
        ...OpportunitySummary
      }
    }
    pageData {
      count
      limit
      offset
    }
  }
}
    ${OpportunitySummaryFragmentDoc}`;

/**
 * __useLatestOpportunitiesQuery__
 *
 * To run a query within a React component, call `useLatestOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpportunitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      industries: // value for 'industries'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useLatestOpportunitiesQuery(baseOptions?: Apollo.QueryHookOptions<LatestOpportunitiesQuery, LatestOpportunitiesQueryVariables>) {
        return Apollo.useQuery<LatestOpportunitiesQuery, LatestOpportunitiesQueryVariables>(LatestOpportunitiesDocument, baseOptions);
      }
export function useLatestOpportunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestOpportunitiesQuery, LatestOpportunitiesQueryVariables>) {
          return Apollo.useLazyQuery<LatestOpportunitiesQuery, LatestOpportunitiesQueryVariables>(LatestOpportunitiesDocument, baseOptions);
        }
export type LatestOpportunitiesQueryHookResult = ReturnType<typeof useLatestOpportunitiesQuery>;
export type LatestOpportunitiesLazyQueryHookResult = ReturnType<typeof useLatestOpportunitiesLazyQuery>;
export type LatestOpportunitiesQueryResult = Apollo.QueryResult<LatestOpportunitiesQuery, LatestOpportunitiesQueryVariables>;
export const OpportunityDocument = gql`
    query Opportunity($id: String!) {
  opportunity(id: $id) {
    ...Opportunity
  }
  relatedOpportunites(id: $id, count: 3) {
    ...OpportunitySummary
  }
}
    ${OpportunityFragmentDoc}
${OpportunitySummaryFragmentDoc}`;

/**
 * __useOpportunityQuery__
 *
 * To run a query within a React component, call `useOpportunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpportunityQuery(baseOptions: Apollo.QueryHookOptions<OpportunityQuery, OpportunityQueryVariables>) {
        return Apollo.useQuery<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, baseOptions);
      }
export function useOpportunityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpportunityQuery, OpportunityQueryVariables>) {
          return Apollo.useLazyQuery<OpportunityQuery, OpportunityQueryVariables>(OpportunityDocument, baseOptions);
        }
export type OpportunityQueryHookResult = ReturnType<typeof useOpportunityQuery>;
export type OpportunityLazyQueryHookResult = ReturnType<typeof useOpportunityLazyQuery>;
export type OpportunityQueryResult = Apollo.QueryResult<OpportunityQuery, OpportunityQueryVariables>;
export const OpportunityBoardDocument = gql`
    query OpportunityBoard {
  allIndustries {
    ...Industry
  }
  allOpportunityTypes {
    ...OpportunityType
  }
}
    ${IndustryFragmentDoc}
${OpportunityTypeFragmentDoc}`;

/**
 * __useOpportunityBoardQuery__
 *
 * To run a query within a React component, call `useOpportunityBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpportunityBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpportunityBoardQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpportunityBoardQuery(baseOptions?: Apollo.QueryHookOptions<OpportunityBoardQuery, OpportunityBoardQueryVariables>) {
        return Apollo.useQuery<OpportunityBoardQuery, OpportunityBoardQueryVariables>(OpportunityBoardDocument, baseOptions);
      }
export function useOpportunityBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpportunityBoardQuery, OpportunityBoardQueryVariables>) {
          return Apollo.useLazyQuery<OpportunityBoardQuery, OpportunityBoardQueryVariables>(OpportunityBoardDocument, baseOptions);
        }
export type OpportunityBoardQueryHookResult = ReturnType<typeof useOpportunityBoardQuery>;
export type OpportunityBoardLazyQueryHookResult = ReturnType<typeof useOpportunityBoardLazyQuery>;
export type OpportunityBoardQueryResult = Apollo.QueryResult<OpportunityBoardQuery, OpportunityBoardQueryVariables>;
export const CreateOpportunityDocument = gql`
    mutation CreateOpportunity($opportunity: CreateOpportunityInput!) {
  createOpportunity(opportunity: $opportunity) {
    id
  }
}
    `;
export type CreateOpportunityMutationFn = Apollo.MutationFunction<CreateOpportunityMutation, CreateOpportunityMutationVariables>;

/**
 * __useCreateOpportunityMutation__
 *
 * To run a mutation, you first call `useCreateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpportunityMutation, { data, loading, error }] = useCreateOpportunityMutation({
 *   variables: {
 *      opportunity: // value for 'opportunity'
 *   },
 * });
 */
export function useCreateOpportunityMutation(baseOptions?: Apollo.MutationHookOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>) {
        return Apollo.useMutation<CreateOpportunityMutation, CreateOpportunityMutationVariables>(CreateOpportunityDocument, baseOptions);
      }
export type CreateOpportunityMutationHookResult = ReturnType<typeof useCreateOpportunityMutation>;
export type CreateOpportunityMutationResult = Apollo.MutationResult<CreateOpportunityMutation>;
export type CreateOpportunityMutationOptions = Apollo.BaseMutationOptions<CreateOpportunityMutation, CreateOpportunityMutationVariables>;
export const UserProfileDocument = gql`
    query UserProfile {
  profile {
    ...UserProfile
  }
  latestOpportunities(first: 3) {
    edges {
      node {
        ...OpportunitySummary
      }
    }
  }
}
    ${UserProfileFragmentDoc}
${OpportunitySummaryFragmentDoc}`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, baseOptions);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, baseOptions);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UpdateYoungPersonProfileDocument = gql`
    mutation UpdateYoungPersonProfile($profile: UpdateYoungPersonInput!) {
  updateYoungPersonProfile(profile: $profile) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateYoungPersonProfileMutationFn = Apollo.MutationFunction<UpdateYoungPersonProfileMutation, UpdateYoungPersonProfileMutationVariables>;

/**
 * __useUpdateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonProfileMutation, { data, loading, error }] = useUpdateYoungPersonProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateYoungPersonProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateYoungPersonProfileMutation, UpdateYoungPersonProfileMutationVariables>) {
        return Apollo.useMutation<UpdateYoungPersonProfileMutation, UpdateYoungPersonProfileMutationVariables>(UpdateYoungPersonProfileDocument, baseOptions);
      }
export type UpdateYoungPersonProfileMutationHookResult = ReturnType<typeof useUpdateYoungPersonProfileMutation>;
export type UpdateYoungPersonProfileMutationResult = Apollo.MutationResult<UpdateYoungPersonProfileMutation>;
export type UpdateYoungPersonProfileMutationOptions = Apollo.BaseMutationOptions<UpdateYoungPersonProfileMutation, UpdateYoungPersonProfileMutationVariables>;
export const UpdateAdminProfileDocument = gql`
    mutation UpdateAdminProfile($profile: UpdateAdminInput!) {
  updateAdminProfile(profile: $profile) {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export type UpdateAdminProfileMutationFn = Apollo.MutationFunction<UpdateAdminProfileMutation, UpdateAdminProfileMutationVariables>;

/**
 * __useUpdateAdminProfileMutation__
 *
 * To run a mutation, you first call `useUpdateAdminProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminProfileMutation, { data, loading, error }] = useUpdateAdminProfileMutation({
 *   variables: {
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useUpdateAdminProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminProfileMutation, UpdateAdminProfileMutationVariables>) {
        return Apollo.useMutation<UpdateAdminProfileMutation, UpdateAdminProfileMutationVariables>(UpdateAdminProfileDocument, baseOptions);
      }
export type UpdateAdminProfileMutationHookResult = ReturnType<typeof useUpdateAdminProfileMutation>;
export type UpdateAdminProfileMutationResult = Apollo.MutationResult<UpdateAdminProfileMutation>;
export type UpdateAdminProfileMutationOptions = Apollo.BaseMutationOptions<UpdateAdminProfileMutation, UpdateAdminProfileMutationVariables>;