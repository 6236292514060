import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ViewOnIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M30.94 15.66A16.69 16.69 0 0016 5 16.69 16.69 0 001.06 15.66a1 1 0 000 .68A16.69 16.69 0 0016 27a16.69 16.69 0 0014.94-10.66 1 1 0 000-.68zM16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25z"
      fill="currentColor"
    />
    <path
      d="M16 10a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z"
      fill="currentColor"
    />
  </Icon>
);

export default ViewOnIcon;
