import { Button, HStack } from "@chakra-ui/react";
import React, { useMemo } from "react";

import config from "../../config";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import FacebookIcon from "../Icons/Facebook";
import InstagramIcon from "../Icons/Instagram";
import LinkedinIcon from "../Icons/Linkedin";
import TheDotsIcon from "../Icons/TheDots";
import TwitterIcon from "../Icons/Twitter";
import YoutubeIcon from "../Icons/Youtube";

const SocialIconMap = {
  twitter: {
    name: "Twitter",
    icon: <TwitterIcon />,
  },
  facebook: {
    name: "Facebook",
    icon: <FacebookIcon />,
  },
  instagram: {
    name: "Instagram",
    icon: <InstagramIcon />,
  },
  linkedin: {
    name: "Linkedin",
    icon: <LinkedinIcon />,
  },
  youtube: {
    name: "Youtube",
    icon: <YoutubeIcon />,
  },
  theDots: {
    name: "The Dots",
    icon: <TheDotsIcon />,
  },
};

interface SocialBlock {
  name: string;
  icon: JSX.Element;
  link: string;
}

const Social: Functional = () => {
  const blocks = useMemo(() => {
    return (Object.keys(config.social) as Array<
      keyof typeof config.social
    >).reduce<SocialBlock[]>((memo, key) => {
      if (SocialIconMap[key]) {
        memo.push({
          ...SocialIconMap[key],
          link: config.social[key],
        });
      }
      return memo;
    }, []);
  }, []);

  return (
    <HStack>
      {blocks.map((block) => (
        <Button
          key={block.name}
          as="a"
          href={block.link}
          aria-label={block.name}
          boxSize="30px"
          borderRadius="50%"
          minW="auto"
          colorScheme="pearl"
          color="black"
          px={0}
          target="_blank"
          rel="noopener nofollower"
          onClick={() =>
            event("SocialFooterLink", {
              event_category: "engagement",
              event_label: block.name,
            })
          }
        >
          {block.icon}
        </Button>
      ))}
    </HStack>
  );
};

export default Social;
