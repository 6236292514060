import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const MarkdownBox = styled(Box)`
  h1,
  h2,
  h3 {
    font-weight: 700;
  }
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 25px;
  }
  h3 {
    font-size: 20px;
  }

  ul,
  ol {
    margin-left: 1rem;
  }
`;
