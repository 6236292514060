import { Button, Grid, GridItem, Heading, Input, Text } from "@chakra-ui/react";
import { Expose } from "class-transformer";
import {
  ArrayMaxSize,
  ArrayNotEmpty,
  ArrayUnique,
  IsEnum,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsString,
  Length,
  MinDate,
  ValidateIf,
} from "class-validator";
import { startOfDay } from "date-fns";
import { FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";

import {
  DateInput,
  Field,
  MultiSelect,
  RichText,
  Select,
} from "../../components/Form";
import {
  CreateOpportunityInput,
  IndustryFragment,
  OpportunityType,
  OpportunityTypeFragment,
} from "../../graphql";
import { Functional } from "../../types";
import { IsEmailOrUrl } from "../../utils/decorators";

export interface OpportunityDetailsFormProps {
  formik: FormikProps<CreateOpportunityInput>;
  industries: IndustryFragment[];
  types: OpportunityTypeFragment[];
  prev: () => void;
}

export class OpportunityDetailsFormInput {
  @Length(4, 50, {
    message: "The opportunity title must be between 4 - 50 characters long",
  })
  @IsNotEmpty({ message: "Please enter the opportunity title" })
  @Expose()
  title!: string;

  @IsInt({
    each: true,
    message: "There is a problem with the selection you made",
  })
  @ArrayUnique({ message: "Selected industries must be unique" })
  @ArrayMaxSize(3, { message: "Please select upto 3 industires" })
  @ArrayNotEmpty({ message: "Please select your industry" })
  @Expose()
  industries!: number[];

  @IsEnum(OpportunityType, { message: "Please select an opportunity type" })
  @Expose()
  type?: OpportunityType;

  @Length(3, 30, {
    message: "The opportunity duration must be between 3 - 30 characters long",
  })
  @IsOptional()
  @ValidateIf((obj, value) => !!value)
  @Expose()
  hours?: string;

  @Length(3, 30, {
    message: "The opportunity duration must be between 3 - 30 characters long",
  })
  @IsNotEmpty({ message: "Please enter salary information" })
  @Expose()
  salary!: string;

  @Length(3, 30, {
    message: "The opportunity location must be between 3 - 30 characters long",
  })
  @IsNotEmpty({ message: "Please enter work location" })
  @Expose()
  location!: string;

  @MinDate(startOfDay(new Date()), {
    message: "The deadline must either be today or a future date",
  })
  @IsNotEmpty({ message: "Please set an opportunity deadline" })
  @Expose()
  closesAt!: Date;

  @IsEmailOrUrl(
    {
      url: {
        require_protocol: true,
      },
    },
    {
      message:
        "Please enter a valid link (with protocol) or email address for where to apply",
    }
  )
  @Expose()
  applicationUri!: string;

  @IsString({ message: "The formatting of the description is invalid" })
  @IsOptional()
  @ValidateIf((obj, value) => !!value)
  @Expose()
  description!: string;
}

/**
 * This form is a step in a multi-form
 */
const OpportunityDetailsForm: Functional<OpportunityDetailsFormProps> = ({
  formik,
  prev,
  industries,
  types,
}) => {
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setTouched,
    errors,
    touched,
    values,
  } = formik;

  // Reset touched on initial load so errors aren't presented on mount
  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  const typesToItems = useMemo(() => {
    return types.map((type) => ({ name: type.name, value: type.id }));
  }, [types]);

  const industriesToItems = useMemo(() => {
    return industries.map((industry) => ({
      name: industry.name,
      value: industry.id,
    }));
  }, [industries]);

  const setType = useCallback(
    (type: OpportunityType) => setFieldValue("type", type),
    [setFieldValue]
  );

  const setIndustries = useCallback(
    (industries: number[]) => setFieldValue("industries", industries),
    [setFieldValue]
  );

  const setClosesAt = useCallback(
    (closesAt: Date) => setFieldValue("closesAt", closesAt),
    [setFieldValue]
  );

  const setDescription = useCallback(
    (description: string) => setFieldValue("description", description),
    [setFieldValue]
  );

  return (
    <>
      <Heading as="h1" size="lg" mb={2}>
        Opportunity details
      </Heading>
      <Text mb={8}>*Required</Text>

      <Field
        label="Opportunity title"
        error={touched.title && errors.title}
        mb={8}
        isRequired
      >
        <Input
          placeholder="e.g. Assistant producer / Gallery invigilator"
          name="title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
        />
      </Field>
      <Field
        label="Industry"
        description="You can select up to 3 options"
        error={touched.industries && errors.industries}
        mb={8}
        isRequired
      >
        <MultiSelect
          items={industriesToItems}
          onChange={setIndustries}
          values={values.industries}
          onBlur={() => setFieldTouched("industries")}
          max={3}
        />
      </Field>
      <Field label="Type" mb={8} isRequired>
        <Select items={typesToItems} value={values.type} onChange={setType} />
      </Field>
      <Field label="Hours" error={touched.hours && errors.hours} mb={8}>
        <Input
          placeholder="9am - 5pm / 5 days a week"
          name="hours"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.hours || ""}
        />
      </Field>
      <Field
        label="Salary"
        error={touched.salary && errors.salary}
        mb={8}
        isRequired
      >
        <Input
          placeholder="£15 per hour / £23,500 per annum"
          name="salary"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.salary}
        />
      </Field>
      <Field
        label="Opportunity location"
        error={touched.location && errors.location}
        mb={8}
        isRequired
      >
        <Input
          placeholder="Hackney, London / Remote"
          name="location"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.location}
        />
      </Field>
      <Field
        label="Deadline"
        description="Your post will be advertised until this date"
        error={touched.closesAt && (errors.closesAt as string)}
        mb={8}
        isRequired
      >
        <DateInput
          name="closesAt"
          placeholder="dd/mm/yyyy"
          onChange={handleChange}
          onSelect={setClosesAt}
          onBlur={handleBlur}
        />
      </Field>
      <Field
        label="Application link or email"
        error={touched.applicationUri && errors.applicationUri}
        mb={8}
        isRequired
      >
        <Input
          placeholder="https://createjobslondon.org/apply or apply@createjobs.org.uk"
          name="applicationUri"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.applicationUri}
        />
      </Field>
      <Field
        label="Description"
        error={touched.description ? errors.description : undefined}
        mb={20}
      >
        <RichText
          placeholder={`Here you can give a brief description of the role, key responsibililies, necesssary skills and anything needed to apply i.e. "Please send your CV, a cover letter and link to your portfolio"`}
          onBlur={() => setFieldTouched("description")}
          onChange={setDescription}
          changeOnBlur
          defaultValue={values.description}
        />
      </Field>
      <Grid templateColumns="repeat(3, 1fr)" w="100%" columnGap={6}>
        <GridItem>
          <Button
            variant="outline"
            colorScheme="obsidian"
            onClick={prev}
            isFullWidth
          >
            Back
          </Button>
        </GridItem>
        <GridItem colSpan={2}>
          <Button type="submit" isFullWidth mb={20}>
            Next
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};

export default OpportunityDetailsForm;
