import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Link } from "@reach/router";
import React, { useMemo } from "react";

import CreativeConnect from "../../assets/images/creative-connect.jpg";
import Events from "../../assets/images/events-v2.jpg";
import GoodGrowthHub from "../../assets/images/good-growth-hub.jpg";
// import Feedback from "../../assets/images/feedback.jpg";
// import Notion from "../../assets/images/logos/notion.svg";
// import Slack from "../../assets/images/logos/slack.svg";
// import NeedToTalk from "../../assets/images/need-to-talk.jpg";
import Avatar from "../../components/Avatar";
import Card from "../../components/Card";
import { OpportunityCard } from "../../components/Opportunity";
import { OpportunitySummaryFragment, UserProfileFragment } from "../../graphql";
import { useAuthUser } from "../../operations/user";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";

interface ProfileScreenProps {
  profile?: UserProfileFragment | null;
  opportunities: OpportunitySummaryFragment[];
  loading: boolean;
}

const META_COLUMNS = {
  openToWork: {
    value: (value: boolean) => (value ? "Open to work" : "Unavailable"),
    title: "Availability",
  },
  emailAlerts: {
    value: (value: boolean) => (value ? "Yes" : "No"),
    title: "Email alerts",
  },
};

const ProfileScreen: Functional<ProfileScreenProps> = ({
  profile,
  opportunities,
  loading,
}) => {
  const authUser = useAuthUser();

  const { data, email, firstName, lastName, image } = profile || {};
  const user = useMemo(() => {
    if (authUser) {
      return {
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        image: image?.url || undefined,
      };
    }
    return null;
  }, [authUser, image]);

  const meta = useMemo(() => {
    if (data) {
      const { __typename, ...info } = data;
      return info;
    }
    return null;
  }, [data]);

  return (
    <>
      <Container position="relative" maxW="850px" mt={{ md: 200 }}>
        <Flex
          bg="white"
          p={{ md: 12 }}
          flexDirection="column"
          alignItems={{ base: "flex-start", md: "center" }}
        >
          {user && (
            <Box
              display="inline-block"
              borderRadius="50%"
              overflow="hidden"
              mt={{ base: -50, md: -150 }}
              mb={8}
            >
              <Avatar boxSize={{ base: "100px", md: "200px" }} {...user} />
            </Box>
          )}
          <Heading as="h1" size="2xl" mb={4}>
            {firstName} {lastName}
          </Heading>
          <Text fontSize="lg" mb={{ base: 6, md: 8 }}>
            {email}
          </Text>
          <Divider display={{ md: "none" }} mb={6} />
          {meta && (
            <Box as="table" w="100%" mb={8}>
              <SimpleGrid
                as="tbody"
                columns={{ base: 1, md: 2 }}
                spacing={{ md: 8 }}
              >
                {(Object.keys(meta) as Array<keyof typeof meta>).map((key) => {
                  const column = META_COLUMNS[key] || null;
                  if (!column) {
                    return null;
                  }
                  return (
                    <Box key={key} as="tr" fontSize="lg">
                      <th>{column.title}</th>
                      <Box as="td" pl={8}>
                        {column.value(meta[key])}
                      </Box>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
          )}
          <Button
            as={Link}
            to="/settings"
            w={{ base: "100%", sm: "350px" }}
            mb={8}
          >
            Edit profile
          </Button>
        </Flex>
      </Container>
      <Container maxW="xl" mb={16}>
        <Divider display={{ md: "none" }} mb={8} />
        <Heading as="h2" my={8}>
          Resources
        </Heading>
        <SimpleGrid
          columns={{ sm: 1, md: 2, lg: 3 }}
          spacing="50px"
          position="relative"
          mb={8}
        >
          {/* <a
            href="https://join.slack.com/t/createjobscommunity/shared_invite/zt-r24m68sl-sbsJV2idKQuTAhtD96GCRw"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "Slack",
              })
            }
          >
            <Card image={Slack} title="Join slack" color="#4A154B">
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Connect and share with other young creatives by joining our
                Slack community
              </Text>
            </Card>
          </a> */}
          {/* <a
            href="https://www.notion.so/Create-Jobs-Collection-36023ed1df8342baa4ed3837727a0dde"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "Notion",
              })
            }
          >
            <Card image={Notion} title="Notion">
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Find a hub of recorded sessions from our world-class speakers
              </Text>
            </Card>
          </a> */}
          {/* <a
            href="mailto:opportunities@anewdirection.org.uk?subject=I want to book a one-to-one"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "Email",
              })
            }
          >
            <Card image={NeedToTalk} title="Need to talk?" isImageBg>
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Organise a one on one with your course leaders.
              </Text>
            </Card>
          </a> */}
          {/* <a
            href="https://createjobs.typeform.com/to/XRlJJXTq"
            rel="noopener nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "Feedback",
              })
            }
          >
            <Card image={Feedback} title="Feedback" isImageBg>
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                If you have a spare 5 minutes please give us feedback on how you
                are finding this platform
              </Text>
            </Card>
          </a> */}
          <a
            href="https://goodgrowthhub.org.uk/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "GoodGrowthHub",
              })
            }
          >
            <Card image={GoodGrowthHub} title="Good Growth Hub" isImageBg>
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Connecting east London businesses with young, diverse talent 
              </Text>
            </Card>
          </a>
          <a
            href="https://www.anewdirection.org.uk/training-cpd/creative-connect"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "CreativeConnect",
              })
            }
          >
            <Card image={CreativeConnect} title="Creative Connect" isImageBg>
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Personal & tailored support for young people taking their first steps towards a creative career
              </Text>
            </Card>
          </a>
          <a
            href="https://www.anewdirection.org.uk/events?tag=young-people"
            target="_blank"
            rel="noopener noreferrer nofollow"
            onClick={() =>
              event("resourceLink", {
                event_category: "engagement",
                event_label: "Events",
              })
            }
          >
            <Card image={Events} title="Events" isImageBg>
              <Text
                textAlign="center"
                fontSize={{ md: "lg" }}
                color={{ base: "gray.600", md: "black" }}
              >
                Check out A New Direction's upcoming events for young creatives
              </Text>
            </Card>
          </a>
        </SimpleGrid>
        {opportunities.length ? (
          <>
            <Divider mb={8} />
            <Heading as="h2" mb={8}>
              Opportunities
            </Heading>
            <SimpleGrid
              columns={{ sm: 1, md: 2, lg: 3 }}
              spacing="50px"
              position="relative"
              mb={8}
            >
              {opportunities.map((opportunity) => (
                <OpportunityCard
                  key={opportunity.id}
                  opportunity={opportunity}
                />
              ))}
            </SimpleGrid>
            <Center mb={8}>
              <Button
                as={Link}
                variant="outline"
                colorScheme="black"
                w={{ base: "100%", sm: "350px" }}
                to="/opportunities"
              >
                See more
              </Button>
            </Center>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default ProfileScreen;
