import {
  Alert,
  AlertDescription,
  AlertTitle,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import React from "react";

import EmailIcon from "../../components/Icons/Email";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import { Functional } from "../../types";

interface CreateSuccessScreenProps {
  onRetry?: () => void;
}

const CreateSuccessScreen: Functional<CreateSuccessScreenProps> = ({
  onRetry = () => {},
}) => (
  <AuthContainer>
    <Alert status="success" variant="ghost" mb={6}>
      <EmailIcon boxSize="67px" color="brand.pink.500" />
      <AlertTitle>Confirm your email</AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text mb={12}>
          Please confirm your email address. We've emailed you instructions to
          verify your account. If you're ever locked out of your account, this
          will help us get you back in.
        </Text>
        <Text>
          If you didn't receive an email, you can{" "}
          <Link
            as={ReachLink}
            to="/auth/verification"
            display="inline-block"
            onClick={onRetry}
          >
            request a new one
          </Link>
          .
        </Text>
      </AlertDescription>
    </Alert>
  </AuthContainer>
);

export default CreateSuccessScreen;
