import {
  Box,
  ChakraStyleProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { Children, cloneElement, isValidElement } from "react";

export interface FieldProps extends ChakraStyleProps {
  error?: string | string[] | false;
  supressError?: boolean;
  name?: string | undefined;
  label?: string;
  description?: string;
  isRequired?: boolean;
}

const Field: React.FC<FieldProps> = ({
  error,
  supressError = false,
  label,
  description,
  children,
  isRequired = false,
  ...props
}) => {
  return (
    <FormControl isInvalid={!!error}>
      <Box {...props}>
        {label && (
          <FormLabel fontWeight={600} fontSize="lg">
            {label}
            {isRequired ? <span aria-hidden>*</span> : ""}
          </FormLabel>
        )}
        {description && <Text mb={4}>{description}</Text>}
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return null;
          }
          return cloneElement(child, {
            ...child.props,
            isInvalid: !!error,
            isRequired,
          });
        })}
        <Box as={motion.div} layout>
          {error && !supressError && (
            <FormErrorMessage flexDirection="column" alignItems="flex-start">
              {Array.isArray(error)
                ? error.map((item, index) => (
                    <Text key={item} mt={0} color="inherit">
                      {item}
                    </Text>
                  ))
                : error}
            </FormErrorMessage>
          )}
        </Box>
      </Box>
    </FormControl>
  );
};

export default Field;
