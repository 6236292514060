import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

import { Functional } from "../../types";

const MenuIcon: Functional<IconProps> = (props) => (
  <Icon viewBox="0 0 23 18" {...props} fill="none">
    <path stroke="currentColor" strokeWidth="2.5" d="M.5 1.75H23" />
    <path stroke="currentColor" strokeWidth="2.5" d="M.5 9.25H23" />
    <path stroke="currentColor" strokeWidth="2.5" d="M.5 16.75H23" />
  </Icon>
);

export default MenuIcon;
