import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const UploadIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M10.8232 17.8232L10.6464 18l.1768.1768 1.41 1.41.1765.1764.1767-.1761 2.1636-2.1552V29.25h2.5V17.4319l2.1636 2.1552.1767.1761.1765-.1764 1.41-1.41.1768-.1768-.1768-.1768-5-5L16 12.6464l-.1768.1768-5 5z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".5"
    />
    <path
      d="M22.7499 22.0001v.25h.75v-.25l.0015.25c1.6653-.0106 3.2679-.6364 4.4997-1.757 1.2318-1.1207 2.0059-2.6572 2.1734-4.314.1674-1.6569-.2834-3.3172-1.2661-4.6617-.9507-1.301-2.3344-2.21741-3.8992-2.58671-.4667-2.00615-1.5903-3.80085-3.1945-5.09759-1.646-1.33046-3.6984-2.05624-5.8148-2.05624-2.1165 0-4.1689.72578-5.8149 2.05624-1.60423 1.29674-2.72777 3.09144-3.1945 5.09759-1.56483.3693-2.94846 1.28571-3.89923 2.58671-.9826 1.3445-1.4335 3.0048-1.26601 4.6617.16749 1.6568.94154 3.1933 2.17335 4.314 1.2318 1.1206 2.83439 1.7464 4.49966 1.757h.75159v-2.5h-.75v-.0002l-.00999.0004c-1.12723.045-2.22619-.3595-3.05514-1.1247-.82895-.7652-1.31998-1.8283-1.36507-2.9555-.04509-1.1273.35946-2.2262 1.12464-3.0552.76407-.8277 1.82524-1.3185 2.95064-1.3648h1.09889l.00595-.2439.01968-.8066c.21648-1.61832 1.01224-3.10352 2.24014-4.18019 1.2312-1.07957 2.8128-1.6748 4.4503-1.6748 1.6374 0 3.2191.59523 4.4502 1.6748 1.2309 1.07934 2.0276 2.56922 2.2418 4.19219 0 .0004.0001.0008.0001.0011l.0997.8176.0268.2198h1.0763c1.1254.0463 2.1865.5371 2.9506 1.3648.7652.829 1.1697 1.9279 1.1247 3.0552-.0451 1.1272-.5362 2.1903-1.3651 2.9555-.829.7652-1.9279 1.1697-3.0551 1.1247v-.0002h-.76v2.25z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".5"
    />
  </Icon>
);

export default UploadIcon;
