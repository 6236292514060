import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const AlarmBellIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 39 40" {...props} fill="none">
    <path
      d="M28.407 14.41v8.999L32.06 30.9H6.94l3.653-7.491v-9c0-3.922 2.503-6.583 6.062-7.554V5.048c0-1.28 1.022-2.328 2.27-2.328h1.15c1.248 0 2.27 1.048 2.27 2.328v1.807c3.56.97 6.062 3.633 6.062 7.554zM11.198 6.35l-1.7-3.02 1.664-.98 1.7 3.02-1.664.98zm-3.117 5.612l-2.944-1.744.963-1.706 2.945 1.743-.964 1.707zm.039 6.457h-3.4v-1.976h3.4v1.976zM26.139 5.37l1.7-3.02 1.663.98-1.7 3.02-1.663-.98zm3.817 4.885L32.9 8.512l.964 1.706-2.945 1.744-.963-1.707zm.925 6.188h3.4v1.976h-3.4v-1.976zm-6.323 16.692c-.35 2.587-2.512 4.516-5.058 4.516-2.545 0-4.708-1.929-5.057-4.516l-.152-1.123h10.418l-.151 1.123zm-2.27.853h-5.575c.563 1.04 1.623 1.686 2.787 1.686a3.169 3.169 0 002.787-1.686zM18.581 6.515a10.833 10.833 0 011.837 0V5.048a.347.347 0 00-.343-.351h-1.15a.341.341 0 00-.343.35v1.468zm.918 1.939c-3.369 0-6.98 1.85-6.98 5.955v9.447l-2.47 5.068H28.95l-2.47-5.068v-9.447c0-4.11-3.611-5.955-6.98-5.955z"
      fill="currentColor"
    />
  </Icon>
);

export default AlarmBellIcon;
