import { Container, Flex, Spinner } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { FadeInOut } from "../../components/Transition";
import { UserProfileFragment, UserRole } from "../../graphql";
import { useAuthUser } from "../../operations/user";
import AdminSettingsForm from "../../presentation/Dashboard/AdminSettingsForm";
import SettingsForm from "../../presentation/Dashboard/SettingsForm";
import { Functional } from "../../types";

interface ProfileScreenProps {
  profile?: UserProfileFragment | null;
  loading: boolean;
}

const SettingsScreen: Functional<ProfileScreenProps> = ({
  profile,
  loading,
}) => {
  const authUser = useAuthUser();

  // Conditionally render form based on user profile
  const form = useMemo(() => {
    if (profile?.data?.__typename === "YoungPersonDto") {
      return <SettingsForm profile={profile} />;
    }

    if (profile && authUser?.role === UserRole.ADMIN) {
      return <AdminSettingsForm profile={profile} />;
    }

    return null;
  }, [profile, authUser]);

  return (
    <>
      <Container position="relative" maxW="850px" mt={{ md: 100 }}>
        <Flex
          bg="white"
          py={12}
          px={{ md: 24 }}
          flexDirection="column"
          alignItems={{ base: "flex-start", md: "center" }}
        >
          <FadeInOut>
            {loading ? (
              <Spinner key="spinner" size="xl" my={32} color="brand.pink.500" />
            ) : (
              form
            )}
          </FadeInOut>
        </Flex>
      </Container>
    </>
  );
};

export default SettingsScreen;
