import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";

import { useOpportunityLazyQuery } from "../../graphql";
import { OpportunityDetailScreen } from "../../screens/Opportunity";
import { event } from "../../utils/analytics";

interface OpportunityDetailProps extends RouteComponentProps {
  code?: string;
}

const OpportunityDetailContainer: React.FC<OpportunityDetailProps> = ({
  code,
}) => {
  const [getOpportunity, { loading, data }] = useOpportunityLazyQuery();

  useEffect(() => {
    if (code) {
      getOpportunity({
        variables: { id: code },
      });
    }
  }, [getOpportunity, code]);

  const opportunity = data?.opportunity;
  const related = data?.relatedOpportunites;

  useEffect(() => {
    if (opportunity) {
      event("viewOpportunityDetail", {
        event_category: "engagement",
        event_label: `${opportunity.title} - ${opportunity.company}`,
        non_interaction: true,
      });
    }
  }, [opportunity]);

  return (
    <OpportunityDetailScreen
      loading={loading}
      opportunity={opportunity}
      related={related}
    />
  );
};

export default OpportunityDetailContainer;
