import {
  ValidateBy,
  ValidationOptions,
  buildMessage,
  isEmail,
  isURL,
} from "class-validator";
import ValidatorJS from "validator";

const IS_EMAIL_OR_URL = "isEmailOrUrl";
type EmailOrUrlOptions = {
  email?: ValidatorJS.IsEmailOptions;
  url?: ValidatorJS.IsURLOptions;
};

export function IsEmailOrUrl(
  options?: EmailOrUrlOptions,
  validationOptions?: ValidationOptions
) {
  return ValidateBy(
    {
      name: IS_EMAIL_OR_URL,
      constraints: [options],
      validator: {
        validate: (value, args): boolean => {
          return (
            isEmail(value, args?.constraints[0]?.email || {}) ||
            isURL(value, args?.constraints[0]?.url || {})
          );
        },
        defaultMessage: buildMessage(
          (eachPrefix) =>
            eachPrefix + "$property must be a URL or email address",
          validationOptions
        ),
      },
    },
    validationOptions
  );
}
