import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

interface ControlButtonProps extends ButtonProps {
  isActive: boolean;
  isFocussed: boolean;
}

const ControlButton: React.FC<ControlButtonProps> = ({
  isActive,
  isFocussed,
  ...props
}) => {
  const color = isFocussed ? "obsidian.500" : "gray.200";
  return (
    <Button
      variant={isActive ? "solid" : "outline"}
      borderColor={color}
      border="2px solid"
      boxSizing="border-box"
      color={isActive ? undefined : color}
      zIndex={isActive ? 1 : 0}
      px={0}
      minW={{ md: "3.13rem" }}
      h={{ base: "2.8rem", md: "3.13rem" }}
      flex={1}
      {...props}
    />
  );
};

export default ControlButton;
