import { useBreakpointValue } from "@chakra-ui/react";
import React from "react";

import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";


const HeaderNav: React.FC = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return isDesktop ? (
    <DesktopHeader />
  ) : (
    <MobileHeader />
  );
};

export default HeaderNav;
