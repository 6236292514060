import { ApolloError } from "@apollo/client";
import React, {
  Dispatch,
  Reducer,
  createContext,
  useEffect,
  useReducer,
} from "react";

import { OpportunityType, useOpportunityBoardQuery } from "../graphql";
import { useFilterQuery } from "../hooks/useFilterQuery";

export type FilterTypes = "industries" | "opportunities";

export interface OpportunityFilterState {
  label: string;
  // Original data from source ie. graphql
  data: { id: OpportunityType | number; name: any }[];
  // Available items for downshift
  items: string[];
  // Selected items for downshift
  selected: string[];
  // Selected items for graphql
  ids: any[];
  isOpen: boolean;
}

export interface OpportunityBoardContextState {
  filters: Record<FilterTypes, OpportunityFilterState>;
  page: number;
  loading: boolean;
  forceClose: number;
  error?: ApolloError;
}

/**
 * This entire implmentation was a bit rushed and could do with some love.
 *
 * We currently use downshiftjs to manage accessible multiselects (filters).
 * These filters are used to update the paging graphql query.
 * Unfortunately both these requirements need different transformed values of the same data.
 * This does not scale very well if we added a 3rd filter set. Revisiting should refactor hard here.
 */
export const OpportunityBoardContext = createContext<{
  state: OpportunityBoardContextState;
  dispatch: Dispatch<OpportunityBoardAction>;
}>({
  state: {
    filters: {
      industries: {
        label: "Industry",
        data: [],
        items: [],
        selected: [],
        ids: [],
        isOpen: false,
      },
      opportunities: {
        label: "Opportunity type",
        data: [],
        items: [],
        selected: [],
        ids: [],
        isOpen: false,
      },
    },
    page: 1,
    loading: true,
    forceClose: 0,
  },
  dispatch: () => {},
});

export interface OpportunityBoardContextProps {}

type OpportunityBoardAction =
  | {
      type: "LOAD";
      payload: {
        industries: Partial<OpportunityFilterState>;
        opportunities: Partial<OpportunityFilterState>;
      };
    }
  | { type: "REMOVE_ALL" }
  | { type: "FORCE_CLOSE" }
  | ({
      key: FilterTypes;
    } & (
      | {
          type: "ADD_ITEM";
          payload: string;
        }
      | { type: "REMOVE_ITEM"; payload: string }
      | { type: "SET_MENU"; payload: boolean }
    ));
// [], [string], name
const transformSelectedToId = (
  data: OpportunityFilterState["data"],
  source: string[]
) => {
  return source.reduce<any[]>((memo, key) => {
    const found = data.find((item) => item.name === key);
    if (found) {
      memo.push(found.id);
    }
    return memo;
  }, []);
};

const transformIdsToSelected = (
  data: OpportunityFilterState["data"],
  source: any[]
) => {
  return source.reduce<any[]>((memo, key) => {
    const found = data.find((item) => item.id === key);
    if (found) {
      memo.push(found.name);
    }
    return memo;
  }, []);
};

export const OpportunityBoardProvider: React.FC<OpportunityBoardContextProps> = ({
  children,
}) => {
  const { data, error } = useOpportunityBoardQuery();
  const [{ filters, page }, setFilters] = useFilterQuery(
    "opportunities:filters"
  );

  const initialState: OpportunityBoardContextState = {
    filters: {
      industries: {
        label: "Industry",
        data: [],
        items: [],
        selected: [],
        ids: filters?.industries || [],
        isOpen: false,
      },
      opportunities: {
        label: "Opportunity type",
        data: [],
        items: [],
        selected: [],
        ids: filters?.opportunities || [],
        isOpen: false,
      },
    },
    page: page || 1,
    forceClose: 0,
    loading: true,
    error,
  };

  const reducer: Reducer<
    OpportunityBoardContextState,
    OpportunityBoardAction
  > = (state, action) => {
    switch (action.type) {
      case "LOAD":
        return {
          ...state,
          filters: {
            industries: {
              ...state.filters.industries,
              ...action.payload.industries,
              selected: transformIdsToSelected(
                action.payload.industries?.data || [],
                state.filters.industries.ids
              ),
            },
            opportunities: {
              ...state.filters.opportunities,
              ...action.payload.opportunities,
              selected: transformIdsToSelected(
                action.payload.opportunities?.data || [],
                state.filters.opportunities.ids
              ),
            },
          },
          loading: false,
        };
      case "REMOVE_ALL":
        return {
          ...state,
          filters: {
            industries: {
              ...state.filters.industries,
              selected: [],
              ids: [],
            },
            opportunities: {
              ...state.filters.opportunities,
              selected: [],
              ids: [],
            },
          },
        };
      case "ADD_ITEM":
        const added = [
          ...Array.from(
            new Set([...state.filters[action.key].selected, action.payload])
          ),
        ];
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.key]: {
              ...state.filters[action.key],
              selected: added,
              ids: transformSelectedToId(state.filters[action.key].data, added),
            },
          },
        };
      case "REMOVE_ITEM":
        const removed = state.filters[action.key].selected.filter(
          (item) => item !== action.payload
        );
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.key]: {
              ...state.filters[action.key],
              selected: removed,
              ids: transformSelectedToId(
                state.filters[action.key].data,
                removed
              ),
            },
          },
        };
      case "SET_MENU":
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.key]: {
              ...state.filters[action.key],
              isOpen: action.payload,
            },
          },
        };
      case "FORCE_CLOSE":
        return {
          ...state,
          forceClose: state.forceClose + 1,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: "LOAD",
        payload: {
          industries: {
            data: data.allIndustries,
            items: data.allIndustries.map((industry) => industry.name),
          },
          opportunities: {
            data: data.allOpportunityTypes,
            items: data.allOpportunityTypes.map((type) => type.name),
          },
        },
      });
    }
  }, [data]);

  // When Filters are updated set them to the query string and local storage
  // Reset the page to 1
  useEffect(() => {
    const filters = {
      industries: state.filters.industries.ids,
      opportunities: state.filters.opportunities.ids,
    };
    setFilters({
      page: 1,
      filters,
    });
    // Everytime page state chages the setFilters callback updates
    // eslint-disable-next-line
  }, [state.filters, data]);

  return (
    <OpportunityBoardContext.Provider value={{ state, dispatch }}>
      {children}
    </OpportunityBoardContext.Provider>
  );
};
