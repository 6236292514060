import { InMemoryCache, ReactiveVar, makeVar } from "@apollo/client";
import { plainToClass } from "class-transformer";

import { AuthUser } from "../types";

const getAuthUser = () => {
  const token = localStorage.getItem("session");

  if (token) {
    try {
      const decoded = atob(token);
      const parse = JSON.parse(decoded);
      return plainToClass(AuthUser, parse, { excludeExtraneousValues: true });
    } catch (e) {
      return null;
    }
  }

  return null;
};

export const authUser: ReactiveVar<AuthUser | null> = makeVar(getAuthUser());

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        authUser: {
          read() {
            return authUser();
          },
        },
      },
    },
    UserProfileDto: {
      keyFields: ["email"],
    },
    OpportunityDto: {
      fields: {
        closesAt: {
          read(closesAt) {
            return new Date(closesAt);
          },
        },
      },
    },
  },
});

export default cache;
