import { Link, LinkProps } from "@chakra-ui/react";
import {
  Link as ReachLink,
  LinkProps as ReachLinkProps,
  useMatch,
} from "@reach/router";
import React from "react";

export type NavLinkProps = ReachLinkProps<any> &
  LinkProps & {
    isExact?: boolean;
  };

const NavLink: React.FC<NavLinkProps> = ({ isExact = false, ...props }) => {
  const match = useMatch(`${props.to}${!isExact ? "/*" : ""}`);
  return (
    <Link
      as={ReachLink}
      color="white"
      fontWeight={match ? 700 : 400}
      textTransform="uppercase"
      borderBottomColor={match ? "brand.pink.500" : "transparent"}
      letterSpacing={0.5}
      {...props}
    />
  );
};

export default NavLink;
