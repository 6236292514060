import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Switch,
  Text,
  chakra,
} from "@chakra-ui/react";
import { Link } from "@reach/router";
import { Transform, Type, plainToClass } from "class-transformer";
import {
  IsBoolean,
  IsHexColor,
  IsOptional,
  ValidateNested,
} from "class-validator";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";

import { Select, SingleImageInput } from "../../components/Form";
import config from "../../config";
import {
  UpdateYoungPersonInput,
  UploadImageInput,
  UserProfileFragment,
} from "../../graphql";
import { useUpdateYoungPersonProfile } from "../../operations/user";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { IsFile, MinFileDimensions } from "../../utils/decorators";
import { validator } from "../../utils/validation";

interface SettingsFormProps {
  profile: UserProfileFragment;
}

const Form = chakra("form", {
  baseStyle: {
    w: "100%",
  },
});

class UploadInput {
  @MinFileDimensions([500, 500], {
    message: "The image needs to be 500px X 500px or larger.",
  })
  @IsFile(
    {
      types: ["image/jpeg", "image/png"],
      maxFileSize: 5,
    },
    {
      message: "Only jpeg/png files are accepted with a max file size of 5MB.",
    }
  )
  @Type(() => Object)
  @Transform((value: any, obj: UploadInput) => {
    return obj.file;
  })
  file!: File;

  @IsHexColor()
  @IsOptional()
  color?: string;
}

class SettingsFormInput {
  @IsBoolean({ message: "Received an invalid value for Availability" })
  openToWork!: boolean;

  @IsBoolean({ message: "Received an invalid value for Email alerts" })
  emailAlerts!: boolean;

  @ValidateNested()
  @Transform((value) => plainToClass(UploadInput, value))
  @Type(() => UploadInput)
  @IsOptional()
  image?: UploadInput;
}

const SettingsForm: Functional<SettingsFormProps> = ({ profile }) => {
  const [update, { error }] = useUpdateYoungPersonProfile();
  const { data, email, firstName, lastName, image } = profile || {};

  const { setFieldValue, setErrors, setFieldTouched, ...formik } = useFormik<
    UpdateYoungPersonInput
  >({
    initialValues: {
      openToWork: data?.openToWork,
      emailAlerts: data?.emailAlerts,
    },
    validate: validator(SettingsFormInput),
    onSubmit: (values) => {
      update({
        variables: {
          profile: values,
        },
      })
        .then(() => {
          formik.setSubmitting(false);
          event("updateSettings", {
            event_category: "engagement",
            event_label: "YOUNG_PERSON",
          });
        })
        .catch(() => {
          event("updateSettingsFailure", {
            event_category: "engagement",
            event_label: "YOUNG_PERSON",
          });
        });
    },
  });

  useEffect(() => {
    setErrors(error?.graphQLErrors[0].extensions?.response || {});
  }, [error, setErrors]);

  const setImage = useCallback(
    (image: UploadImageInput) => setFieldValue("image", image),
    [setFieldValue]
  );

  const setAvailability = useCallback(
    (openToWork: boolean) => setFieldValue("openToWork", openToWork),
    [setFieldValue]
  );

  return (
    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Heading as="h1" size="lg" mb={8}>
        Edit details
      </Heading>
      <Grid
        templateColumns="repeat(5, 1fr)"
        w="100%"
        columnGap={6}
        mb={8}
        fontSize="lg"
      >
        <GridItem>
          <Text fontWeight="bold">Name</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text>
            {firstName} {lastName}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Email</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text mb={4}>{email}</Text>
          <Text fontSize="md" color="gray.500">
            If your name and/or email address are wrong please contact Create
            Jobs at{" "}
            <Text
              as="a"
              href={`mailto:${config.supportEmail}`}
              _hover={{ textDecoration: "underline" }}
            >
              {config.supportEmail}
            </Text>
          </Text>
        </GridItem>
      </Grid>
      <FormControl
        isInvalid={!!formik.errors.image && !!formik.touched.image}
        mb={8}
      >
        <Grid
          templateColumns="repeat(5, 1fr)"
          w="100%"
          columnGap={6}
          fontSize="lg"
        >
          <GridItem colSpan={{ base: 2, sm: 1 }}>
            <SingleImageInput
              id="image"
              name="image"
              borderRadius="50%"
              setValue={setImage}
              onChange={() => setFieldTouched("image", true)}
              onDrop={() => setFieldTouched("image", true)}
              initialPreview={image?.url}
            />
          </GridItem>
          <GridItem
            as={Flex}
            flexDirection="column"
            colSpan={3}
            justifyContent="center"
          >
            <FormLabel fontWeight="bold" htmlFor="image" fontSize="lg">
              Profile Photo
            </FormLabel>
            <Text fontSize="md">
              A photo helps personalise your account. Minimum size 500px x 500px
            </Text>
            <FormErrorMessage>{formik.errors.image}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <FormControl
        isInvalid={!!formik.errors.openToWork && !!formik.touched.openToWork}
        mb={8}
      >
        <FormLabel fontWeight="bold" htmlFor="openToWork" fontSize="lg">
          Availability
        </FormLabel>
        <Text fontSize="md" mb={4}>
          Adding your availability helps employers know whether you're open to
          new opportunities
        </Text>
        <FormErrorMessage>{formik.errors.openToWork}</FormErrorMessage>
        <Select
          items={[
            { name: "Open to work", value: true },
            { name: "Unavailable", value: false },
          ]}
          onChange={setAvailability}
          value={formik.values.openToWork}
        />
      </FormControl>
      <FormControl isInvalid={!!formik.errors.emailAlerts} mb={8}>
        <FormLabel fontWeight="bold" fontSize="lg">
          Email Alerts
        </FormLabel>
        <FormErrorMessage>{formik.errors.emailAlerts}</FormErrorMessage>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <Text>
            You{" "}
            <Text as="span" fontWeight="bold" textDecoration="underline">
              {!!formik.values.emailAlerts ? "will" : "won't"}
            </Text>{" "}
            receive email alerts when a new opportunity gets posted
          </Text>
          <Switch
            name="emailAlerts"
            onChange={formik.handleChange}
            isChecked={!!formik.values.emailAlerts}
            isInvalid={!!formik.errors.emailAlerts}
            colorScheme={!!formik.errors.emailAlerts ? "red" : undefined}
          />
        </Flex>
      </FormControl>
      <Flex w="100%">
        <Button
          as={Link}
          to="/"
          variant="outline"
          colorScheme="black"
          w="33.333%"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          w="66.666%"
          ml={4}
          isLoading={formik.isSubmitting}
        >
          Update profile
        </Button>
      </Flex>
    </Form>
  );
};

export default SettingsForm;
