import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ChevronRightIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6-6-6-1.41 1.41z"
    />
  </Icon>
);

export default ChevronRightIcon;
