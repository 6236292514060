import { Box, Button, Container, HStack } from "@chakra-ui/react";
import { Link } from "@reach/router";
import React, { useContext } from "react";

import {
  FilterTypes,
  OpportunityBoardContext,
} from "../../contexts/OpportunityBoardContext";
import { useUserProfileQuery } from "../../graphql";
import { useAuthUser } from "../../operations/user";
import AlarmBellIcon from "../Icons/AlarmBell";

import FilterList from "./FilterList";
import FilterSelect from "./FilterSelect";

const EmailAlerts: React.FC = () => {
  const { data, loading } = useUserProfileQuery();

  const showSetupEmailAlerts = !loading && data?.profile.data?.__typename === "YoungPersonDto";
  const showManageEmailAlerts = showSetupEmailAlerts && data?.profile.data?.emailAlerts;

  if (!showSetupEmailAlerts) return <></>;

  return (
    <Box w="100%">
      <Button
        as={Link}
        to="/settings"
        w="100%"
        size="lg"
        border="2px solid"
        borderColor="black"
        justifyContent="space-between"
        rightIcon={<AlarmBellIcon w="40px" h="40px" />}
      >
        {showManageEmailAlerts ? "Manage Email Alerts" : "Set up email alerts"}
      </Button>
    </Box>
  )
}

const FilterDesktop: React.FC = () => {
  const {
    state: { filters },
  } = useContext(OpportunityBoardContext);
  const authUser = useAuthUser();

  return (
    <Container maxW="1090px" mt={-9}>
      <HStack spacing="-2px" ml="auto" mr="auto">
        {(Object.keys(filters) as FilterTypes[]).map((id, index) => (
          <Box key={id} w="100%">
            <FilterSelect identifier={id} size="lg" colorScheme="black" />
          </Box>
        ))}
        {authUser && <EmailAlerts />}
      </HStack>
      <FilterList />
    </Container>
  );
};

export default FilterDesktop;
