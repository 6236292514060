import { MutationHookOptions, gql, useQuery } from "@apollo/client";
import { plainToClass } from "class-transformer";
import jwtDecode from "jwt-decode";

import {
  Exact,
  LoginMutation,
  LogoutMutation,
  RefreshMutation,
  UpdateAdminInput,
  UpdateAdminProfileMutation,
  UpdateYoungPersonInput,
  UpdateYoungPersonProfileMutation,
  UserInput,
  useLoginMutation,
  useLogoutMutation,
  useRefreshMutation,
  useUpdateAdminProfileMutation,
  useUpdateYoungPersonProfileMutation,
} from "../graphql";
import { authUser } from "../store/cache";
import { AuthUser, AuthUserState } from "../types";

interface AuthUserData {
  authUser: AuthUserState;
}

const GET_AUTH_USER = gql`
  query GetAuthUser {
    authUser @client {
      firstName
      lastName
      image
      role
    }
  }
`;

const storeAuthUser = (access: string) => {
  const decode = jwtDecode(access);
  const user = plainToClass(AuthUser, decode, {
    excludeExtraneousValues: true,
  });
  localStorage.setItem("session", btoa(JSON.stringify(user)));
  setAuthUser(user);
};

export const useAuthUser = (): AuthUserState => {
  const { data } = useQuery<AuthUserData>(GET_AUTH_USER, {
    fetchPolicy: "cache-only",
  });
  return data?.authUser || null;
};

export const setAuthUser = (user: AuthUser | null) => {
  authUser(user);
};

export const useLogin = (
  options?:
    | MutationHookOptions<
        LoginMutation,
        Exact<{
          user: UserInput;
        }>
      >
    | undefined
) => {
  return useLoginMutation({
    ...options,
    onCompleted: ({ login: { access } }) => {
      storeAuthUser(access);
    },
  });
};

export const useRefresh = (
  options?: MutationHookOptions<RefreshMutation, {}>
) => {
  return useRefreshMutation({
    ...options,
    onCompleted: ({ refresh: { access } }) => {
      storeAuthUser(access);
    },
  });
};

export const clearAuthUser = () => {
  localStorage.removeItem("session");
  setAuthUser(null);
};

export const useLogout = (
  options?: MutationHookOptions<LogoutMutation, {}>
) => {
  return useLogoutMutation({
    ...options,
    onCompleted: () => {
      clearAuthUser();
    },
  });
};

export const useUpdateYoungPersonProfile = (
  options?: MutationHookOptions<
    UpdateYoungPersonProfileMutation,
    Exact<{
      profile: UpdateYoungPersonInput;
    }>
  >
) => {
  const [refresh] = useRefresh();
  return useUpdateYoungPersonProfileMutation({
    ...options,
    onCompleted: () => {
      refresh();
    },
  });
};

export const useUpdateAdminProfile = (
  options?: MutationHookOptions<
    UpdateAdminProfileMutation,
    Exact<{
      profile: UpdateAdminInput;
    }>
  >
) => {
  const [refresh] = useRefresh();
  return useUpdateAdminProfileMutation({
    ...options,
    onCompleted: () => {
      refresh();
    },
  });
};
