import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from "@reach/router";
import { Transform, Type, plainToClass } from "class-transformer";
import { IsHexColor, IsOptional, ValidateNested } from "class-validator";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";

import { Form, SingleImageInput } from "../../components/Form";
import {
  UpdateAdminInput,
  UploadImageInput,
  UserProfileFragment,
} from "../../graphql";
import { useUpdateAdminProfile } from "../../operations/user";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { IsFile, MinFileDimensions } from "../../utils/decorators";
import { validator } from "../../utils/validation";

interface SettingsFormProps {
  profile: UserProfileFragment;
}

class UploadInput {
  @MinFileDimensions([500, 500], {
    message: "The image needs to be 500px X 500px or larger.",
  })
  @IsFile(
    {
      types: ["image/jpeg", "image/png"],
      maxFileSize: 5,
    },
    {
      message: "Only jpeg/png files are accepted with a max file size of 5MB.",
    }
  )
  @Type(() => Object)
  @Transform((value: any, obj: UploadInput) => {
    return obj.file;
  })
  file!: File;

  @IsHexColor()
  @IsOptional()
  color?: string;
}

class AdminSettingsFormInput {
  @ValidateNested()
  @Transform((value) => plainToClass(UploadInput, value))
  @Type(() => UploadInput)
  @IsOptional()
  image?: UploadInput;
}

const AdminSettingsForm: Functional<SettingsFormProps> = ({ profile }) => {
  const [update, { error }] = useUpdateAdminProfile();
  const { email, firstName, lastName, image } = profile || {};

  const { setFieldValue, setErrors, setFieldTouched, ...formik } = useFormik<
    UpdateAdminInput
  >({
    initialValues: {},
    validate: validator(AdminSettingsFormInput),
    onSubmit: (values) => {
      update({
        variables: {
          profile: values,
        },
      })
        .then(() => {
          formik.setSubmitting(false);
          event("updateSettings", {
            event_category: "engagement",
            event_label: "ADMIN",
          });
        })
        .catch((error) => {
          event("updateSettingsFailure", {
            event_category: "engagement",
            event_label: "ADMIN",
          });
        });
    },
  });

  useEffect(() => {
    setErrors(error?.graphQLErrors[0].extensions?.response || {});
  }, [error, setErrors]);

  const setImage = useCallback(
    (image: UploadImageInput) => setFieldValue("image", image),
    [setFieldValue]
  );

  return (
    <Form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Heading as="h1" size="lg" mb={8}>
        Edit details
      </Heading>
      <Grid
        templateColumns="repeat(5, 1fr)"
        w="100%"
        columnGap={6}
        mb={8}
        fontSize="lg"
      >
        <GridItem>
          <Text fontWeight="bold">Name</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text>
            {firstName} {lastName}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold">Email</Text>
        </GridItem>
        <GridItem colSpan={4}>
          <Text mb={4}>{email}</Text>
        </GridItem>
      </Grid>
      <FormControl
        isInvalid={!!formik.errors.image && !!formik.touched.image}
        mb={24}
      >
        <Grid
          templateColumns="repeat(5, 1fr)"
          w="100%"
          columnGap={6}
          fontSize="lg"
        >
          <GridItem colSpan={{ base: 2, sm: 1 }}>
            <SingleImageInput
              id="image"
              name="image"
              borderRadius="50%"
              setValue={setImage}
              onChange={() => setFieldTouched("image", true)}
              onDrop={() => setFieldTouched("image", true)}
              initialPreview={image?.url}
            />
          </GridItem>
          <GridItem
            as={Flex}
            flexDirection="column"
            colSpan={3}
            justifyContent="center"
          >
            <FormLabel fontWeight="bold" htmlFor="image" fontSize="lg">
              Profile Photo
            </FormLabel>
            <Text fontSize="md">
              A photo helps personalise your account. Minimum size 500px x 500px
            </Text>
            <FormErrorMessage>{formik.errors.image}</FormErrorMessage>
          </GridItem>
        </Grid>
      </FormControl>
      <Flex w="100%">
        <Button
          as={Link}
          to="/"
          variant="outline"
          colorScheme="black"
          w="33.333%"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          w="66.666%"
          ml={4}
          isLoading={formik.isSubmitting}
        >
          Update profile
        </Button>
      </Flex>
    </Form>
  );
};

export default AdminSettingsForm;
