import { Box, BoxProps, Image, forwardRef } from "@chakra-ui/react";
import React from "react";

import AvatarImage from "./AvatarImage";

export interface AvatarProps extends BoxProps {
  image?: string | null;
  firstName: string;
  lastName: string;
}

const Avatar = forwardRef<AvatarProps, "div">(function Avatar(props, ref) {
  const { image, firstName, lastName, ...rest } = props;

  return (
    <Box ref={ref} {...rest}>
      <Image
        src={image || ""}
        fallback={
          <AvatarImage
            firstName={firstName}
            lastName={lastName}
            w="100%"
            h="100%"
          />
        }
      />
    </Box>
  );
});

export default Avatar;
