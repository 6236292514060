import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const ItalicIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"
      fill="currentColor"
    />
  </Icon>
);

export default ItalicIcon;
