import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useMultiSelect } from "../../../hooks";

import CheckboxSelectOption from "./CheckboxSelectOption";
import PopoverSelect from "./PopoverSelect";

export interface MultiSelectProps {
  items: Array<{ name: string; value: string | number | boolean | null }>;
  onChange: Dispatch<any>;
  onBlur?: () => void;
  onFocus?: () => void;
  values?: Array<string | number | boolean | null>;
  max?: number | null;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  items,
  onChange,
  onBlur,
  onFocus,
  values = [],
  max = null,
  ...props
}) => {
  const hasOpened = useRef(false);
  const mapValueToItem = useCallback(
    (value: string | number | boolean | null) =>
      items.find((item) => item.value === value),
    [items]
  );
  const mapNameToItem = useCallback(
    (name: string) => items.find((item) => item.name === name),
    [items]
  );

  // All possible options
  const selectItems = useMemo(() => items.map((item) => item.name), [items]);

  // Selected items mapped form values to name
  const selectedItems = useMemo(() => {
    return (
      values.reduce<string[]>((memo, value) => {
        const selected = value ? mapValueToItem(value) || null : null;
        if (selected) {
          memo.push(selected.name);
        }
        return memo;
      }, []) || []
    );
  }, [values, mapValueToItem]);

  const onUpdate = (names: string[]) => {
    const updated = names.reduce<typeof values>((memo, name) => {
      const item = mapNameToItem(name);
      if (item) {
        memo.push(item.value);
      }
      return memo;
    }, []);
    onChange(updated);
  };

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useMultiSelect({
    items: selectItems,
    selected: selectedItems,
    onAdd: (name) => {
      if (max !== null && max <= values.length) {
        return;
      }
      onUpdate([...selectedItems, name]);
    },
    onRemove: (name) => {
      onUpdate(selectedItems.filter((item) => item !== name));
    },
  });

  useEffect(() => {
    if (isOpen) {
      onFocus && onFocus();
      hasOpened.current = true;
    } else if (hasOpened.current && onBlur) {
      onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <PopoverSelect
      label={selectedItems.join(", ") || "Please select an option"}
      menuProps={getMenuProps()}
      labelProps={getLabelProps()}
      buttonProps={getToggleButtonProps()}
      isOpen={isOpen}
      {...props}
    >
      {selectItems.map((item, index) => (
        <CheckboxSelectOption
          key={`${item}${index}`}
          {...getItemProps({ item, index })}
          isChecked={selectedItems.includes(item)}
          isHighlighted={highlightedIndex === index}
          isDisabled={
            !selectedItems.includes(item) &&
            max !== null &&
            max <= values.length
          }
        >
          {item}
        </CheckboxSelectOption>
      ))}
    </PopoverSelect>
  );
};

export default MultiSelect;
