import { Box, Button, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

export interface StepProps {
  index?: number;
  isActive?: boolean;
  isComplete?: boolean;
  onClick?: () => void;
}

const Step: React.FC<StepProps> = ({
  children,
  index = 0,
  isActive = false,
  isComplete = false,
  onClick,
}) => {
  const size = useBreakpointValue({ base: "md", md: "lg" });
  const isFill = isActive || isComplete;
  const isDisabled = isActive || !isComplete;
  return (
    <Box
      borderRight={isComplete && !isActive ? "2px solid white" : ""}
      w={{ base: isActive ? "100%" : "auto", lg: "100%" }}
    >
      <Button
        onClick={onClick}
        variant={isFill ? "solid" : "outline"}
        border="2px solid black"
        colorScheme="obsidian"
        size={size}
        fontSize={{ base: "sm", md: "22px" }}
        isFullWidth
        isDisabled={isDisabled}
        aria-label={`${index + 1}. ${String(children)}`}
        _disabled={{
          opacity: 1,
          cursor: "not-allowed",
        }}
      >
        {index + 1}
        <Box
          as="span"
          display={{
            base: isActive ? "inline-block" : "none",
            lg: "inline-block",
          }}
        >
          . {children}
        </Box>
      </Button>
    </Box>
  );
};

export default Step;
