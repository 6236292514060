import { useMemo } from "react";

import { useUrlSearchParams } from "./useUrlSearchParams";

export const useQueryString = (key: string) => {
  const params = useUrlSearchParams();
  const query = useMemo(() => {
    return params.get(key);
  }, [params, key]);

  return query;
};
