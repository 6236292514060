import { Box, Button, UnorderedList, chakra } from "@chakra-ui/react";
import { Link } from "@reach/router";
import { motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { OpportunityBoardContext } from "../../contexts/OpportunityBoardContext";
import { useMultiSelect } from "../../hooks";
import { CheckboxSelectOption, SelectButton } from "../Form";
import AlarmBellIcon from "../Icons/AlarmBell";

const MotionMenu = motion.custom(
  chakra("ul", {
    baseStyle: {
      m: 0,
      position: "absolute",
      top: 0,
      left: 0,
      w: "100%",
      pointerEvents: "none",
    },
  }) as typeof UnorderedList
);

const FilterMenu: React.FC = () => {
  const {
    state: {
      filters: { industries, opportunities },
      forceClose,
    },
    dispatch,
  } = useContext(OpportunityBoardContext);

  const industrySelect = useMultiSelect({
    items: industries.items,
    selected: industries.selected,
    onAdd: (item) => {
      dispatch({
        type: "ADD_ITEM",
        key: "industries",
        payload: item,
      });
    },
    onRemove: (item) => {
      dispatch({
        type: "REMOVE_ITEM",
        key: "industries",
        payload: item,
      });
    },
  });

  const opportunitySelect = useMultiSelect({
    items: opportunities.items,
    selected: opportunities.selected,
    onAdd: (item) => {
      dispatch({
        type: "ADD_ITEM",
        key: "opportunities",
        payload: item,
      });
    },
    onRemove: (item) => {
      dispatch({
        type: "REMOVE_ITEM",
        key: "opportunities",
        payload: item,
      });
    },
  });

  const inMenu = useMemo(() => {
    if (opportunitySelect.isOpen) {
      return "opportunities";
    }

    if (industrySelect.isOpen) {
      return "industries";
    }

    return null;
  }, [opportunitySelect.isOpen, industrySelect.isOpen]);

  useEffect(() => {
    dispatch({
      type: "SET_MENU",
      key: "opportunities",
      payload: opportunitySelect.isOpen,
    });
    dispatch({
      type: "SET_MENU",
      key: "industries",
      payload: industrySelect.isOpen,
    });
  }, [opportunitySelect.isOpen, industrySelect.isOpen, dispatch]);

  // Destructure for memoization
  const { closeMenu: closeIndustryMenu } = industrySelect;
  const { closeMenu: closeOpportunityMenu } = opportunitySelect;

  const closeAll = useCallback(() => {
    closeIndustryMenu();
    closeOpportunityMenu();
  }, [closeIndustryMenu, closeOpportunityMenu]);

  useEffect(() => {
    closeAll();
  }, [forceClose, closeAll]);

  const state = {
    industries: {
      ...industries,
      props: industrySelect,
    },
    opportunities: {
      ...opportunities,
      props: opportunitySelect,
    },
  };

  return (
    <Box position="relative">
      <MotionMenu
        key="navigation"
        initial={{ opacity: 0 }}
        animate={{ opacity: inMenu ? 0 : 1 }}
        m={0}
        role="listbox"
        pointerEvents={inMenu ? "none" : "auto"}
      >
        {(Object.keys(state) as Array<keyof typeof state>).map((id) => {
          const filter = state[id];
          return (
            <Box
              key={id}
              as="li"
              listStyleType="none"
              px={2}
              py={1}
              borderBottomWidth="1px"
              role="option"
              _focus={{
                outline: "auto",
              }}
            >
              <SelectButton
                variant="menu"
                size="md"
                fontSize="lg"
                fontWeight={400}
                px={4}
                buttonProps={filter.props.getToggleButtonProps()}
                labelProps={filter.props.getLabelProps()}
              >
                {filter.label}
              </SelectButton>
            </Box>
          );
        })}
        <Box as="li">
          <Button
            as={Link}
            to="/settings"
            w="100%"
            px={6}
            py={8}
            justifyContent="space-between"
            rightIcon={<AlarmBellIcon boxSize="30px" />}
          >
            Sign up for alerts
          </Button>
        </Box>
      </MotionMenu>
      {(Object.keys(state) as Array<keyof typeof state>).map((id) => {
        const filter = state[id];
        return (
          <MotionMenu
            key={id}
            initial={{ opacity: 0 }}
            animate={{ opacity: inMenu === id ? 1 : 0 }}
            sx={{
              m: 0,
              outline: "none",
              listStyleType: "none",
              overflow: "auto",
              pointerEvents: inMenu === id ? "auto" : "none",
            }}
            {...filter.props.getMenuProps()}
          >
            {filter.items.map((item, index) => (
              <CheckboxSelectOption
                key={item}
                itemProps={filter.props.getItemProps({ item, index })}
                isChecked={filter.props.selectedItems.includes(item)}
                isHighlighted={filter.props.highlightedIndex === index}
                px={2}
                size="sm"
              >
                {item}
              </CheckboxSelectOption>
            ))}
          </MotionMenu>
        );
      })}
    </Box>
  );
};

export default FilterMenu;
