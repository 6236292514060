import { RouteComponentProps } from "@reach/router";
import { useFormik } from "formik";
import React, { useState } from "react";

import { FadeInOut } from "../../components/Transition";
import { ForgotPasswordInput, useForgotPasswordMutation } from "../../graphql";
import { ForgotPasswordFormInput } from "../../presentation/Auth/ForgotPasswordForm";
import {
  ForgotPasswordConfirmationScreen,
  ForgotPasswordScreen,
} from "../../screens/Auth";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

const ForgotPasswordContainer: React.FC<RouteComponentProps> = () => {
  const [reset, { data }] = useForgotPasswordMutation();
  const [globalError, setGlobalError] = useState("");

  const formik = useFormik<ForgotPasswordInput>({
    initialValues: {
      email: "",
    },
    validate: validator(ForgotPasswordFormInput),
    onSubmit: (values) => {
      reset({
        variables: {
          user: values,
        },
      })
        .then(() => {
          event("forgotPassword", { event_category: "engagement" });
        })
        .catch((error) => {
          if (error.message === "BAD_USER_INPUT") {
            return formik.setErrors(
              error.graphQLErrors[0].extensions?.response || {}
            );
          }

          event("forgotPasswordFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "We are unable to reset you password at this time. Please try again later."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  return (
    <FadeInOut>
      {!data ? (
        <ForgotPasswordScreen formik={formik} globalError={globalError} />
      ) : (
        <ForgotPasswordConfirmationScreen />
      )}
    </FadeInOut>
  );
};

export default ForgotPasswordContainer;
