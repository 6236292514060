import { Box, Button, Container, Grid, GridItem } from "@chakra-ui/react";
import { RouteComponentProps } from "@reach/router";
import { FormikProps } from "formik";
import React, { useEffect, useMemo, useState } from "react";

import {
  CreateOpportunityInput,
  IndustryFragment,
  OpportunityTypeFragment,
} from "../../graphql";
import { Functional } from "../../types";

import OpportunityDetailScreen from "./OpportunityDetailScreen";

interface ReviewOpportunityScreenProps extends RouteComponentProps {
  formik: FormikProps<CreateOpportunityInput>;
  industries: IndustryFragment[];
  types: OpportunityTypeFragment[];
  prev: () => void;
}

const ReviewOpportunityScreen: Functional<ReviewOpportunityScreenProps> = ({
  formik,
  industries,
  types,
  prev,
}) => {
  const { values } = formik;
  const [logo, setLogo] = useState("");
  const selectedIndustries = useMemo(() => {
    return values.industries.reduce<IndustryFragment[]>((memo, id) => {
      const found = industries.find((industry) => industry.id === id);
      if (found) {
        memo.push(found);
      }
      return memo;
    }, []);
  }, [values, industries]);

  const type = useMemo(() => {
    const found = types.find((item) => item.id === values.type);
    return found?.name || "";
  }, [values, types]);

  useEffect(() => {
    if (values.logo) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target?.result as string);
      };
      reader.readAsDataURL(values.logo.file);
    }
  }, [values, setLogo]);

  return (
    <>
      <OpportunityDetailScreen
        loading={false}
        trackingEnabled={false}
        opportunity={{
          id: 1,
          code: "",
          ...formik.values,
          industries: selectedIndustries,
          type,
          ...(logo ? { image: { id: "", url: logo } } : null),
        }}
      />
      <Box bg="black" w="100%" p={4} position="sticky" bottom={0} zIndex={10}>
        <Container maxW="900px">
          <Grid templateColumns="repeat(3, 1fr)" w="100%" columnGap={6}>
            <GridItem>
              <Button
                variant="outline"
                colorScheme="obsidian"
                onClick={prev}
                isFullWidth
              >
                Back
              </Button>
            </GridItem>
            <GridItem colSpan={2}>
              <Button type="submit" isFullWidth>
                Publish my post
              </Button>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ReviewOpportunityScreen;
