import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const SuccessIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 0C9.62663 0 7.30655.703788 5.33316 2.02236 3.35977 3.34094 1.8217 5.21508.913451 7.4078.00519936 9.60051-.232441 12.0133.230582 14.3411c.463023 2.3278 1.605908 4.4659 3.284138 6.1442 1.67823 1.6782 3.81643 2.8211 6.1442 3.2841 2.32778.463 4.74058.2254 6.93328-.6829 2.1927-.9082 4.0669-2.4463 5.3854-4.4197C23.2962 16.6934 24 14.3734 24 12c-.0076-3.18026-1.2743-6.22808-3.5231-8.47687C18.2281 1.27435 15.1803.00761741 12 0zm0 21.6923c-1.917 0-3.79086-.5684-5.38475-1.6334-1.5939-1.065-2.83618-2.5788-3.56977-4.3498-.73359-1.7711-.92553-3.7199-.55155-5.6.37398-1.8801 1.29708-3.6071 2.65258-4.9626C6.502 3.79101 8.229 2.86791 10.1091 2.49393c1.8802-.37398 3.8289-.18204 5.6.55154 1.771.73359 3.2848 1.97588 4.3498 3.56977S21.6923 10.083 21.6923 12c-.0076 2.5682-1.0312 5.0291-2.8472 6.8451-1.816 1.816-4.2769 2.8396-6.8451 2.8472z"
      fill="currentColor"
    />
    <path
      d="M10.6155 13.1827l-3.0865-3.173-1.64423 1.6153 4.67303 4.7885 7.702-7.26923-1.5577-1.67308-6.0866 5.71151z"
      fill="currentColor"
    />
  </Icon>
);

export default SuccessIcon;
