import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const OrderdListIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z"
      fill="currentColor"
    />
  </Icon>
);

export default OrderdListIcon;
