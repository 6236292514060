import { useMultipleSelection, useSelect } from "downshift";
import { Dispatch } from "react";

type MultiSelectProps = {
  items?: string[];
  selected: string[];
  onAdd: Dispatch<string>;
  onRemove: Dispatch<string>;
};

/**
 * A controlled implmentation of an accessible multi-select
 * This assumes that selected items are managed by the implementing
 * component.
 */
export const useMultiSelect = ({
  items = [],
  selected = [],
  onAdd,
  onRemove,
}: MultiSelectProps) => {
  const { getDropdownProps, selectedItems } = useMultipleSelection<string>({
    selectedItems: selected,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
  } = useSelect({
    items,
    initialHighlightedIndex: 0,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          // Not happy with this in the reducer but limitations
          // on state changes dont allow us to toggle actions reliably.
          // Happy for new investigations
          if (changes.selectedItem) {
            if (selectedItems.includes(changes.selectedItem)) {
              onRemove(changes.selectedItem);
            } else {
              onAdd(changes.selectedItem);
            }
          }
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
          };

        default:
          return changes;
      }
    },
  });

  return {
    getToggleButtonProps: () => {
      return getToggleButtonProps(getDropdownProps());
    },
    getMenuProps: () => {
      return {
        ...getMenuProps(),
        "aria-multiselectable": true,
      };
    },
    getItemProps,
    getLabelProps,
    closeMenu,
    isOpen,
    selectedItems,
    highlightedIndex,
  };
};
