import { Box, Container, Flex, Link, Spacer } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import React from "react";

import Logo from "../../assets/images/logo.svg";
import { useAuthUser } from "../../operations/user";
import { Functional } from "../../types";

import AuthNavigation from "./AuthNavigation";
import Navigation from "./Navigation";

interface DesktopHeaderProps {}

const DesktopHeader: Functional<DesktopHeaderProps> = () => {
  const authUser = useAuthUser();

  return (
    <Box as="header" bg="black">
      <Box
        height="100px"
        py="20px"
        px={{ md: "20px", lg: "20px" }}
      >
        <Container maxW="xl">
          <Flex position="relative" alignItems="center" justifyContent="center">
            <Box>
              <Link
                as={ReachLink}
                variant="unstyled"
                display="inline-block"
                to="/"
              >
                <Box
                  as="img"
                  src={Logo}
                  alt="Create Jobs"
                  w={123}
                />
              </Link>
            </Box>
            <Spacer />
            <Box>
              {authUser ? (
                <AuthNavigation authUser={authUser} />
              ) : (
                <Navigation />
              )}
            </Box>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
