import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const EmailIcon: React.FC<IconProps> = (props) => (
  <Icon viewBox="0 0 24 17" {...props} fill="none">
    <path
      d="M23.9856 2.94908c0-.93229 0-1.83366.0144-2.694603L23.928 0H.035982v3.16312C.035982 7.9197.035982 13.0663 0 17h24c-.0288-4.2881-.0336-9.48461-.0144-14.05092zm-1.5064-1.16536c-.024.4043-.2663.68019-.806 1.00363-3.1641 1.90739-6.3089 3.93131-9.3626 5.8934l-.1295.08324c-.0678.05097-.1441.08962-.2255.11416-.0868-.023-.1683-.06259-.2399-.11654l-.3118-.19977C8.42219 6.64018 5.33493 4.65193 2.23088 2.79211c-.53973-.32583-.77961-.60171-.7988-1.00602H22.4768l.0024-.00237zm-.2231 13.43018H1.71754V4.78274l.23988.14745.98351.6136c2.57391 1.60059 5.22699 3.2535 7.81527 4.91591.866.5542 1.6384.5565 2.5044 0 2.5259-1.60771 5.0375-3.16549 7.6761-4.81602l1.3218-.82527V15.2139h-.0024z"
      fill="currentColor"
    />
  </Icon>
);

export default EmailIcon;
