import {
  Box,
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Heading,
} from "@chakra-ui/react";
import React from "react";

import { FadeInBgImage, FadeInImage } from "../Image";

export interface CardProps extends BoxProps {
  color?: string;
  image?: string;
  isImageBg?: boolean;
  title: string;
  headingProps?: FlexProps;
  mediaProps?: BoxProps;
  imgBoxProps?: BoxProps;
}

const Card: React.FC<CardProps> = ({
  color = "black",
  image,
  isImageBg = false,
  title,
  children,
  headingProps,
  mediaProps,
  imgBoxProps,
  ...props
}) => {
  return (
    <Box {...props}>
      <Box w="100%" bg={color} position="relative">
        <Center minH="310px" {...mediaProps}>
          {image && (
            <>
              {isImageBg ? (
                <FadeInBgImage
                  src={image}
                  w="100%"
                  minH={mediaProps?.minH || mediaProps?.h || "310px"}
                />
              ) : (
                <Box display="flex" w="120px" h="120px" {...imgBoxProps}>
                  <FadeInImage
                    boxSize="120px"
                    objectFit="contain"
                    src={image}
                    alt={title}
                    // fallback={<OpportunityLogoSkeleton />}
                  />
                </Box>
              )}
            </>
          )}
        </Center>
      </Box>
      <Flex
        position="relative"
        justifyContent={{ md: "center" }}
        mt={{ md: -12 }}
        mx={{ md: 4 }}
        {...headingProps}
      >
        <Heading
          bg="white"
          as="h4"
          display="inline-block"
          size="lg"
          textAlign="center"
          px={{ md: 4 }}
          pt={{ base: 4, md: 3 }}
          pb={2}
        >
          {title}
        </Heading>
      </Flex>
      <Flex
        flexDirection="column"
        px={{ md: 4 }}
        py={{ md: 4 }}
        bg="white"
        position="relative"
        alignItems={{ md: "center" }}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default Card;
