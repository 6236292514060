import { Icon, IconProps } from "@chakra-ui/react";
import React, { useMemo } from "react";

import { Functional } from "../../types";

export interface AvatarSvgProps extends IconProps {
  firstName?: string;
  lastName?: string;
}

const AvatarImage: Functional<AvatarSvgProps> = ({
  firstName = "",
  lastName = "",
  ...props
}) => {
  const initials = useMemo(() => {
    let initial = "";
    if (firstName) {
      initial += firstName[0].toUpperCase();
    }
    if (lastName) {
      initial += lastName[0].toUpperCase();
    }
    return initial;
  }, [firstName, lastName]);
  return (
    <Icon viewBox="0 0 100 100" {...props} color="brand.pink.500">
      <rect fill="currentColor" x={0} y={0} height={100} width={100} />
      <text
        fill="#000000"
        fontSize={30}
        fontWeight={700}
        textAnchor="middle"
        x={50}
        y={62}
      >
        {initials}
      </text>
    </Icon>
  );
};

export default AvatarImage;
