import { Heading, Text } from "@chakra-ui/react";
import { FormikProps } from "formik";
import React from "react";

import { AlertMessage } from "../../components/Form";
import { ForgotPasswordInput } from "../../graphql";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import ForgotPasswordForm from "../../presentation/Auth/ForgotPasswordForm";
import { Functional } from "../../types";

export interface ForgotPasswordScreenProps {
  formik: FormikProps<ForgotPasswordInput>;
  globalError?: string;
}

const ForgotPasswordScreen: Functional<ForgotPasswordScreenProps> = ({
  formik,
  globalError,
}) => {
  return (
    <AuthContainer>
      <Heading as="h1" mb={4}>
        Forgot Password?
      </Heading>
      <Text mb={6}>
        Enter the email address you used when you joined and we’ll send you
        instructions to reset your password
      </Text>
      {globalError && (
        <AlertMessage status="error" mb={6}>
          {globalError}
        </AlertMessage>
      )}
      <ForgotPasswordForm formik={formik} mb={6} />
    </AuthContainer>
  );
};

export default ForgotPasswordScreen;
