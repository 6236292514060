import React from "react";

import { Functional } from "../../types";

import MotionBackgroundImage, {
  MotionBackgroundImageProps,
} from "./MotionBackgroundImage";

const FadeInBgImage: Functional<MotionBackgroundImageProps> = (props) => (
  <MotionBackgroundImage
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  />
);

export default FadeInBgImage;
