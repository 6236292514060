import { Box, BoxProps, SystemCSSProperties, useImage } from "@chakra-ui/react";
import { AnimatePresence, MotionProps, motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import { useStyledProps } from "../../hooks";
import { Functional } from "../../types";

export type MotionBackgroundImageProps = BoxProps &
  MotionProps & {
    src: string;
  };

/**
 * Could probably look into making this populate the background image
 * with a responsive srcSet
 */
const MotionBackgroundImage: Functional<MotionBackgroundImageProps> = (
  props
) => {
  const { src, ...rest } = props;
  const [boxProps, imageProps] = useStyledProps(rest);
  const [firstLoad, setFirstLoad] = useState(false);
  const [source, setSource] = useState("");
  const status = useImage({ src, ignoreFallback: true });

  useEffect(() => {
    if (status === "loaded") {
      setFirstLoad(true);
      setSource(src);
    }
  }, [status, src, setSource, setFirstLoad]);

  const style: SystemCSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    w: "100%",
    h: "100%",
  };

  return (
    <Box position="relative" {...boxProps}>
      <AnimatePresence>
        {!firstLoad && (
          <Box key="load" as={motion.div} sx={style} {...imageProps} />
        )}
        <Box
          key={source}
          as={motion.div}
          backgroundImage={`url('${source}')`}
          backgroundSize="cover"
          backgroundPosition="center"
          sx={style}
          {...imageProps}
        />
      </AnimatePresence>
    </Box>
  );
};

export default MotionBackgroundImage;
