import {
  Box,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  chakra,
} from "@chakra-ui/react";
import React, { HTMLProps, Ref, useRef } from "react";

import SelectButton, { SelectButtonProps } from "./SelectButton";

const Menu = chakra<"ul", HTMLProps<HTMLUListElement>>("ul", {
  baseStyle: {
    outline: "none",
    listStyleType: "none",
    overflow: "auto",
  },
});

export interface PopoverSelectProps extends PopoverProps, SelectButtonProps {
  label: string;
  menuProps?: { ref: Ref<HTMLUListElement> } & Omit<
    HTMLProps<HTMLUListElement>,
    "as"
  >;
  isInvalid?: boolean;
}

const PopoverSelect: React.FC<PopoverSelectProps> = ({
  label,
  buttonProps,
  labelProps,
  menuProps,
  isOpen,
  children,
  size,
  variant,
  colorScheme,
  isInvalid,
  ...rest
}) => {
  const initRef = useRef<HTMLUListElement | null>(null);

  return (
    <Box position="relative" w="100%">
      <Popover
        placement="bottom-start"
        isOpen={isOpen}
        initialFocusRef={initRef}
        variant="full"
        gutter={-2}
        closeOnBlur={false}
        {...rest}
      >
        <PopoverTrigger>
          <Box>
            <SelectButton
              isOpen={isOpen}
              buttonProps={buttonProps}
              labelProps={labelProps}
              size={size}
              variant={variant}
              colorScheme={colorScheme}
              isInvalid={isInvalid}
            >
              {label}
            </SelectButton>
          </Box>
        </PopoverTrigger>
        <PopoverContent
          zIndex={4}
          border="2px solid"
          borderColor="black"
          h="50vh"
          minH="415px"
          maxH="500px"
          overflow="auto"
        >
          <Menu
            {...menuProps}
            ref={(ref: HTMLUListElement) => {
              initRef.current = ref;
              if (menuProps?.ref && typeof menuProps.ref === "function") {
                menuProps.ref(ref);
              }
            }}
          >
            {children}
          </Menu>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default PopoverSelect;
