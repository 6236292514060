import { Image, ImageProps, forwardRef } from "@chakra-ui/react";
import { MotionProps, motion } from "framer-motion";
import React from "react";

export type MotionImageProps = ImageProps & MotionProps;

const MotionImage = forwardRef<MotionImageProps, "img">(function MotionImage(
  props,
  ref
) {
  return <Image ref={ref} as={motion.img} {...props} />;
});

export default MotionImage;
