import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useEffect } from "react";

import { clearAuthUser, useLogout } from "../../operations/user";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";

const SignoutContainer: Functional<RouteComponentProps> = () => {
  const {
    state: { notice },
  }: any = useLocation();
  const [logout] = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    logout()
      .then(() => {
        event("logout", {
          event_category: "engagement",
          event_label: notice?.type || "success",
        });
        navigate("/auth/signin", {
          state: {
            notice: notice || {
              type: "success",
              message: "You have successfully signed out.",
            },
          },
        });
      })
      .catch(() => {
        // User's cookies are already cleared if they cannot logout successfully
        // as it is a protected route with auth guard
        clearAuthUser();
        navigate("/auth/signin", {
          state: {
            notice: {
              type: "error",
              message: "Your session is expired. Please sign in.",
            },
          },
        });
      });
    // We don't want to rerun the logout mutation everytime state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout, navigate]);

  return null;
};

export default SignoutContainer;
