import { Skeleton, SkeletonProps } from "@chakra-ui/react";
import React from "react";

const OpportunityLogoSkeleton: React.FC<SkeletonProps> = (props) => (
  <Skeleton
    w="100%"
    h="100%"
    startColor="white"
    endColor="transparent"
    opacity={0.3}
    {...props}
  />
);

export default OpportunityLogoSkeleton;
