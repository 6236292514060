import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Text,
} from "@chakra-ui/react";
import { Link, RouteComponentProps } from "@reach/router";
import React from "react";

import ErrorIcon from "../../components/Icons/Error";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import { Functional } from "../../types";

const UserExistsScreen: Functional<RouteComponentProps> = () => (
  <AuthContainer>
    <Alert status="error" variant="ghost" mb={6}>
      <ErrorIcon boxSize="67px" color="red.500" />
      <AlertTitle>You already have an account with us</AlertTitle>
      <AlertDescription maxWidth="sm">
        <Text mb={6}>
          We couldn’t create a new account because you already have an account
          with your email address.
        </Text>
        <Button as={Link} to="/auth/signin" isFullWidth>
          Log in
        </Button>
      </AlertDescription>
    </Alert>
  </AuthContainer>
);

export default UserExistsScreen;
