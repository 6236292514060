import { EditorState, RichUtils } from "draft-js";
import React, { Dispatch } from "react";

import { Functional } from "../../../types";
import BoldIcon from "../../Icons/Bold";
import ItalicIcon from "../../Icons/Italic";

import ControlButton from "./ControlButton";

const BLOCK_TYPES = [
  {
    children: <BoldIcon boxSize="24px" />,
    "aria-label": "Bold",
    style: "BOLD",
  },
  {
    children: <ItalicIcon boxSize="24px" />,
    "aria-label": "Italic",
    style: "ITALIC",
  },
];

interface InlineStyleControlsProps {
  editorState: EditorState;
  setEditorState: Dispatch<EditorState>;
  isFocussed: boolean;
}

const InlineStyleControls: Functional<InlineStyleControlsProps> = ({
  editorState,
  setEditorState,
  isFocussed,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    <>
      {BLOCK_TYPES.map(({ style, ...type }) => (
        <ControlButton
          key={style}
          isActive={currentStyle.has(style)}
          isFocussed={isFocussed}
          onClick={(e) => {
            e.preventDefault();
            setEditorState(RichUtils.toggleInlineStyle(editorState, style));
          }}
          {...type}
        />
      ))}
    </>
  );
};

export default InlineStyleControls;
