import {
  Alert,
  AlertDescription,
  AlertTitle,
  AspectRatio,
  Box,
  Center,
  Container,
  HStack,
  Heading,
  Image,
  SimpleGrid,
  Text
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useLayoutEffect, useRef } from "react";

import HeroX1 from "../../assets/images/hero-x1.jpg"
import HeroX2 from "../../assets/images/hero-x2.jpg";
import HeroX3 from "../../assets/images/hero-x3.jpg";
import Filter from "../../components/Filters";
import {
  OpportunityCard,
  OpportunityCardSkeleton,
} from "../../components/Opportunity";
import Paginator from "../../components/Paginator";
import { FadeInOut } from "../../components/Transition";
import { OpportunitySummaryFragment, PageData } from "../../graphql";

interface OpportunityBoardScreenProps {
  loading: boolean;
  opportunities?: OpportunitySummaryFragment[];
  pageData: (PageData & { page: number }) | null;
}

const OpportunityBoardScreen: React.FC<OpportunityBoardScreenProps> = ({
  loading = true,
  opportunities,
  pageData,
}) => {
  const filterRef = useRef<HTMLDivElement>(null);
  const prevPageState = useRef<Number>(null);
  const { page, count } = pageData || {};
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (filterRef.current && page) {
        if (prevPageState?.current) {
          filterRef.current.scrollIntoView({
            block: "start",
            inline: "nearest",
            behavior: "smooth",
          });
        }
        (prevPageState as { current: any }).current = page;
      }
    }, 400);
    return () => clearTimeout(timer);
  }, [page]);

  return (
    <>
      <Box bg="black">
        <Box
          py={4}
          px={{ base: 0, lg: "20px" }}
        >
          <Container maxW="xl" bg="black">
            <HStack align="center">
              <Box w={{ base: "100%", md: "50%", lg: "35%" }}>
                <Heading as="h1" fontSize={{ base: 24, md: 32 }} color="white" py={{ base: 6, md: 12}}>Connecting young people to the <Box as="span" color="brand.pink.500">best employers</Box> and <Box as="span" color="brand.pink.500">opportunities</Box> in the cultural, creative, and digital industries.</Heading>
              </Box>
              <AspectRatio d={{base: "none", md: "block"}}  w={{base: "50%", lg: "65%"}} ratio={13 / 6}>
                <Image
                  pl={6}
                  w="full"
                  src={HeroX2}
                  srcSet={`${HeroX1} 292w, ${HeroX2} 583w, ${HeroX3} 1166w`}
                  size="(min-width: 48em) 624px, 100vw"
                />
              </AspectRatio>
            </HStack>
          </Container>
        </Box>
      </Box>
      <Filter ref={filterRef} />
      <Container maxW="xl" mb={16}>
        <Box p={8} minH="88px">
          <Center>
            {count ? (
              <Text>
                Showing{" "}
                <Text as="span" fontWeight={700}>
                  {count} results
                </Text>
              </Text>
            ) : null}
          </Center>
        </Box>
        <FadeInOut>
          {loading ? (
            <SimpleGrid
              key="loading"
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing="50px"
              position="relative"
            >
              {[...Array(6)].map((i, index) => (
                <OpportunityCardSkeleton key={index} />
              ))}
            </SimpleGrid>
          ) : opportunities?.length ? (
            <SimpleGrid
              key="list"
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing="50px"
              position="relative"
            >
              {opportunities.map((opportunity, index) => (
                <OpportunityCard
                  key={opportunity.id}
                  opportunity={opportunity}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Alert
              key="none"
              status="info"
              variant="ghost"
              my={12}
              alignItems="center"
              color="gray.500"
            >
              <AlertTitle>No Results</AlertTitle>
              <AlertDescription maxWidth="sm">
                Please refine your search, or check back later.
              </AlertDescription>
            </Alert>
          )}
        </FadeInOut>

        {pageData && (
          <motion.div layout>
            <Paginator pageData={pageData} />
          </motion.div>
        )}
      </Container>
    </>
  );
};

export default OpportunityBoardScreen;
