import { ThemingProps } from "@chakra-ui/react";
import React, { useContext } from "react";

import {
  FilterTypes,
  OpportunityBoardContext,
} from "../../contexts/OpportunityBoardContext";
import { useMultiSelect } from "../../hooks/";
import { CheckboxSelectOption, PopoverSelect } from "../Form";

interface FilterSelectProps extends ThemingProps {
  identifier: FilterTypes;
}

const FilterSelect: React.FC<FilterSelectProps> = ({
  identifier,
  ...props
}) => {
  const {
    state: { filters },
    dispatch,
  } = useContext(OpportunityBoardContext);
  const { label, items, selected } = filters[identifier];

  const onAdd = (item: string) => {
    dispatch({
      type: "ADD_ITEM",
      key: identifier,
      payload: item,
    });
  };

  const onRemove = (item: string) => {
    dispatch({
      type: "REMOVE_ITEM",
      key: identifier,
      payload: item,
    });
  };

  const {
    getToggleButtonProps,
    getMenuProps,
    getLabelProps,
    getItemProps,
    selectedItems,
    highlightedIndex,
    isOpen,
  } = useMultiSelect({ items, selected, onAdd, onRemove });
  return (
    <PopoverSelect
      isOpen={isOpen}
      label={label}
      buttonProps={{
        ...getToggleButtonProps(),
        borderColor: "black",
      }}
      menuProps={getMenuProps()}
      labelProps={getLabelProps()}
      {...props}
    >
      {items.map((item, index) => (
        <CheckboxSelectOption
          key={item}
          {...getItemProps({ item, index })}
          isChecked={selectedItems.includes(item)}
          isHighlighted={highlightedIndex === index}
        >
          {item}
        </CheckboxSelectOption>
      ))}
    </PopoverSelect>
  );
};

export default FilterSelect;
