import { Link, LinkProps } from "@chakra-ui/react";
import { Link as ReachLink, LinkProps as ReachLinkProps } from "@reach/router";
import React from "react";

export type PaginatorLinkProps = ReachLinkProps<any> & LinkProps;

const PaginatorLink: React.FC<PaginatorLinkProps> = ({ ...props }) => {
  return (
    <Link
      as={ReachLink}
      bg="white"
      display="inline-flex"
      w={12}
      h={12}
      justifyContent="center"
      alignItems="center"
      fontSize="3xl"
      borderColor="currentColor"
      m="0 3px"
      {...props}
    />
  );
};

export default PaginatorLink;
