import { ClassTransformOptions, plainToClass } from "class-transformer";
import { validate } from "class-validator";
import { FormikErrors } from "formik";

export const validator = <T>(input: any, options?: ClassTransformOptions) => {
  return async (values: T) => {
    const instance = plainToClass<typeof input, T>(input, values, options);
    const errors = await validate(instance);
    if (errors.length) {
      return errors.reduce<FormikErrors<Record<string, string | string[]>>>(
        (memo, error) => {
          memo[error.property] = Object.keys(error.constraints || {}).length
            ? Object.values(error.constraints || {})[0]
            : error.children.map(
                (validationError) =>
                  Object.values(validationError.constraints || {})[0]
              )[0];
          return memo;
        },
        {}
      );
    }
  };
};
