import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { isEmail } from "class-validator";
import { format } from "date-fns";
import React, { useMemo } from "react";

import { OpportunityFragment } from "../../graphql";
import { useAuthUser } from "../../operations/user";
import { event } from "../../utils/analytics";
import { FadeInImage } from "../Image";
import { Markdown } from "../Markdown";

import OpportunityLogoSkeleton from "./OpportunityLogoSkeleton";

export interface OpportunityDetailProps {
  opportunity: OpportunityFragment;
  trackingEnabled?: boolean;
}

const INFO_COLUMNS = {
  industries: "Industry",
  salary: "Salary",
  type: "Type",
  location: "Location",
  hours: "Duration",
  closesAt: "Closing date",
};

const OpportunityDetail: React.FC<OpportunityDetailProps> = ({
  opportunity,
  trackingEnabled = true,
}) => {
  const image = opportunity.image?.url;
  // An Opportunity detail page isn't publicly available,
  // so we don't need to worry about masking from crawlers
  const applicationUri = useMemo(
    () =>
      isEmail(opportunity.applicationUri)
        ? `mailto:${opportunity.applicationUri}`
        : opportunity.applicationUri,
    [opportunity]
  );
  const authUser = useAuthUser();

  const info: Partial<
    Record<string, string | JSX.Element[]>
  >[] = useMemo(() => {
    const { salary, type, location } = opportunity;

    const hours = opportunity.hours || "-";
    const closesAt = format(new Date(opportunity.closesAt), `do MMM ''yy`);

    const industries = opportunity.industries.map((industry) => (
      <p key={industry.id}>{industry.name}</p>
    ));

    if (opportunity.industries.length > 1) {
      return [
        { industries, salary, type },
        { location, hours, closesAt },
      ];
    }

    return [
      { industries, salary, type },
      { location, hours, closesAt },
    ];
  }, [opportunity]);

  return (
    <Box bg="white" p={{ base: 4, md: 12 }} mt={{ md: 28 }} position="relative">
      <Flex direction="column" alignItems={{ md: "center" }} mb={8}>
        {image && (
          <Box mt={{ base: -20, md: -28 }} mb={8}>
            <Box
              display="flex"
              w="120px"
              h="120px"
              bg="white"
              border="3px solid"
              borderColor="white"
              boxSizing="content-box"
            >
              <FadeInImage
                boxSize="120px"
                objectFit="contain"
                src={image}
                alt={opportunity.company}
                fallback={<OpportunityLogoSkeleton />}
              />
            </Box>
          </Box>
        )}
        <Box textAlign={{ md: "center" }} mb={{ md: 8 }}>
          <Heading as="h1" size="2xl" mt={4} mb={4}>
            {opportunity.title}
          </Heading>
          <Text fontSize="xl">{opportunity.company}</Text>
        </Box>
        <Divider my={6} border="2px solid" display={{ md: "none" }} />
        <SimpleGrid
          w="100%"
          columns={{ sm: 1, md: 2 }}
          spacing={{ md: 4 }}
          mb={{ base: 6, md: 16 }}
        >
          {info.map((column: any, i: number) => (
            <table key={i}>
              <tbody>
                {(Object.keys(column) as Array<keyof typeof INFO_COLUMNS>).map(
                  (key) => (
                    <tr key={key}>
                      <Text
                        as="th"
                        textAlign="left"
                        verticalAlign="top"
                        fontSize="xl"
                        fontWeight={600}
                        pr={4}
                        py={{ base: 2, md: 0 }}
                        w={{ base: "40%", md: "auto" }}
                      >
                        {INFO_COLUMNS[key]}
                      </Text>
                      <Text as="td" fontSize="xl" py={{ base: 2, md: 0 }}>
                        {column[key]}
                      </Text>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ))}
        </SimpleGrid>
        {authUser ? (
          <Button
            as="a"
            href={applicationUri}
            maxW={{ md: "350px" }}
            w="100%"
            fontSize="20px"
            target="_blank"
            rel="noopener"
            h="54px"
            mb={10}
            onClick={() =>
              trackingEnabled &&
              event("opportunityApplyButton", {
                event_category: "engagement",
                event_label: `${opportunity.title} - ${opportunity.company}`,
              })
            }
          >
            Apply
          </Button>
        ) : (
          <Button
            as="a"
            href="/auth/signup"
            maxW={{ md: "350px" }}
            w="100%"
            fontSize="20px"
            rel="noopener"
            h="54px"
            mb={10}
            onClick={() =>
              trackingEnabled &&
              event("opportunityRegisterToApplyButton", {
                event_category: "engagement",
                event_label: `${opportunity.title} - ${opportunity.company}`,
              })
            }
          >
            Apply
          </Button>
        )}
      </Flex>
      <Markdown>{opportunity.description}</Markdown>
    </Box>
  );
};

export default OpportunityDetail;
