import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  Container,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";
import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import React from "react";

import Image from "../../assets/images/post-opportunity.jpg";
import SuccessIcon from "../../components/Icons/Success";
import { FadeInBgImage } from "../../components/Image";
import config from "../../config";
import { Functional } from "../../types";

const CreateOpportunitySuccessScreen: Functional<RouteComponentProps> = () => {
  return (
    <Flex minH="80vh">
      <FadeInBgImage
        backgroundColor="red"
        src={Image}
        backgroundSize="cover"
        w="40%"
        minH="100%"
      />
      <Box w={{ sm: "100%", md: "60%" }} minH="100%">
        <Container h="100%">
          <Center h="100%" w="100%" position="relative">
            <Box w="100%" maxW="410px" py={32}>
              <Alert status="success" variant="ghost" mb={6}>
                <SuccessIcon boxSize="67px" />
                <AlertTitle>Post published</AlertTitle>
                <AlertDescription maxWidth="sm">
                  <Text>
                    Thanks, your opportunity has just been shared with our
                    Create Jobs network.
                  </Text>
                  <Text>
                    We have sent a copy of your post to your email. If you need
                    to make any changes, please email us at{" "}
                    <Link
                      variant="unstyled"
                      href={`mailto:${config.supportEmail}`}
                    >
                      {config.supportEmail}
                    </Link>
                    .
                  </Text>
                </AlertDescription>
              </Alert>
              <Button as={ReachLink} to="/opportunity/create" isFullWidth>
                Post new opportunity
              </Button>
            </Box>
          </Center>
        </Container>
      </Box>
    </Flex>
  );
};

export default CreateOpportunitySuccessScreen;
