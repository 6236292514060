import { RouteComponentProps } from "@reach/router";
import { useFormik } from "formik";
import React, { useState } from "react";

import { FadeInOut } from "../../components/Transition";
import {
  RequestVerificationInput,
  useRequestNewVerificationMutation,
} from "../../graphql";
import { VerifyAccountFormInput } from "../../presentation/Auth/VerifyAccountForm";
import { CreateSuccessScreen, VerifyScreen } from "../../screens/Auth";
import { Functional } from "../../types";
import { event } from "../../utils/analytics";
import { validator } from "../../utils/validation";

const VerifyContainer: Functional<RouteComponentProps> = () => {
  const [processed, setProcessed] = useState(false);
  const [globalError, setGlobalError] = useState(
    "We found your account, but it is not verified. Request a new link below."
  );
  const [request] = useRequestNewVerificationMutation();

  const formik = useFormik<RequestVerificationInput>({
    initialValues: {
      email: "",
    },
    validate: validator(VerifyAccountFormInput),
    onSubmit: (values) => {
      request({
        variables: {
          user: values,
        },
      })
        .then(() => {
          setProcessed(true);
          event("requestNewVerification", { event_category: "engagement" });
        })
        .catch((error) => {
          const collection = error?.graphQLErrors[0].extensions?.response || {};

          if (error.message === "BAD_USER_INPUT") {
            if (collection.token) {
              setGlobalError(collection.token);
              delete collection.token;
            }
            return formik.setErrors(collection);
          }

          event("requestNewVerificationFailure", {
            event_category: "engagement",
            event_label: error.message || "UNKNOWN_ERROR",
          });

          setGlobalError(
            "An error has occurred. Please request a new verification link."
          );
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });

  const reset = () => {
    formik.resetForm();
    setProcessed(false);
  };

  return (
    <FadeInOut>
      {!processed ? (
        <VerifyScreen key="verify" formik={formik} globalError={globalError} />
      ) : (
        <CreateSuccessScreen key="success" onRetry={reset} />
      )}
    </FadeInOut>
  );
};

export default VerifyContainer;
