import {
  Box,
  Container,
  Image,
  Link,
  SimpleGrid,
  Text,
  chakra,
} from "@chakra-ui/react";
import React from "react";

import ANDLogo from "../../assets/images/anewdirection.svg";
import config from "../../config";
import { event } from "../../utils/analytics";

import Social from "./Social";

const Section = chakra("section", {
  baseStyle: {
    mb: 8,
    _last: {
      mb: 0,
    },
  },
});

const SectionTitle = chakra(Text, {
  baseStyle: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "lg",
    mb: 2,
  },
});

const FooterLink = chakra(Link, {
  baseStyle: {
    fontSize: { base: "md", md: "lg" },
    borderColor: "transparent",
    borderBottomWidth: 2,
    color: "gray.500",
    _hover: {
      borderColor: "gray.500",
    },
  },
});

const FooterTitleLink = chakra(FooterLink, {
  baseStyle: {
    fontWeight: 700,
    color: "white",
    _hover: {
      borderColor: "brand.pink.500",
    },
  },
});

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <Box w="100%" bg="black" py={16} color="white">
      <Container maxWidth={{ base: "md", lg: "xl" }}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
          <Box>
            <Section>
              <SectionTitle>Follow Us</SectionTitle>
              <Social />
            </Section>
            <Section>
              <Box mb={4}>
                <FooterTitleLink href={config.privacyPolicy}>
                  Privacy Policy
                </FooterTitleLink>
              </Box>
              <Box>
                <FooterTitleLink href={config.cookiePolicy}>
                  Cookie Declaration
                </FooterTitleLink>
              </Box>
            </Section>
          </Box>
          <Box>
            <Section>
              <SectionTitle>Contact Us</SectionTitle>
              <Box mb={2}>
                <FooterLink
                  color="gray.500"
                  href={`mailto:${config.contactEmail}`}
                  onClick={() =>
                    event("FooterLink", {
                      event_category: "engagement",
                      event_label: "Email",
                    })
                  }
                >
                  {config.contactEmail}
                </FooterLink>
              </Box>
              <Box>
                <FooterLink
                  color="gray.500"
                  href={`tel:+442076082132`}
                  onClick={() =>
                    event("FooterLink", {
                      event_category: "engagement",
                      event_label: "Telephone",
                    })
                  }
                >
                  020 7608 2132
                </FooterLink>
              </Box>
            </Section>
            <Section>
              <SectionTitle>Sign up</SectionTitle>
              <Text color="gray.500" mb={4}>
                Sign up to our newsletter to get updates on the latest jobs,
                opportunities and events from London's creative industry
              </Text>
              <FooterLink
                color="gray.500"
                href={config.newsletter}
                onClick={() =>
                  event("FooterLink", {
                    event_category: "engagement",
                    event_label: "Newsletter",
                  })
                }
              >
                Subscribe
              </FooterLink>
            </Section>
          </Box>
          <Box>
            <Section>
              <SectionTitle>Powered by</SectionTitle>
              <Link
                variant="unstyled"
                display="inline-block"
                href={config.aNewDirection}
                onClick={() =>
                  event("FooterLink", {
                    event_category: "engagement",
                    event_label: "AND",
                  })
                }
              >
                <Image maxW="200px" src={ANDLogo} alt="A new direction" />
              </Link>
            </Section>
          </Box>
        </SimpleGrid>
        <Text mt={8}>© {year} A New Direction</Text>
      </Container>
    </Box>
  );
};

export default Footer;
