import { HStack, Heading, Link } from "@chakra-ui/react";
import { Link as ReachLink, useLocation } from "@reach/router";
import { FormikProps } from "formik";
import React from "react";

import { AlertMessage } from "../../components/Form";
import { UserInput } from "../../graphql";
import AuthContainer from "../../presentation/Auth/AuthContainer";
import SigninForm from "../../presentation/Auth/SigninForm";
import { Functional } from "../../types";

export interface SigninScreenProps {
  formik: FormikProps<UserInput>;
  globalError?: string;
}

const SigninScreen: Functional<SigninScreenProps> = ({
  formik,
  globalError,
}) => {
  const { state = {} }: { state: any } = useLocation();
  return (
    <AuthContainer>
      <Heading as="h1" mb={6}>
        Log in to Create jobs
      </Heading>
      {state?.notice?.message && (
        <AlertMessage status={state.notice.type || "info"} mb={6}>
          {state.notice.message}
        </AlertMessage>
      )}
      {globalError && (
        <AlertMessage status="error" mb={6}>
          {globalError}
        </AlertMessage>
      )}
      <SigninForm formik={formik} mb={6} />
      <HStack spacing={4} align="center" justify="center">
        <Link as={ReachLink} to="/auth/forgot">
          Forgot password?
        </Link>
        <Link as={ReachLink} to="/auth/signup">
          Register
        </Link>
      </HStack>
    </AuthContainer>
  );
};

export default SigninScreen;
