import { Box, Center, Input, InputProps, useToken } from "@chakra-ui/react";
import styled from "@emotion/styled";
import ColorThief from "colorthief";
import React, {
  ChangeEvent,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { UploadImageInput } from "../../../graphql";
import { useStyledProps } from "../../../hooks";
import { Functional } from "../../../types";
import { rgbToHex } from "../../../utils";
import PlusIcon from "../../Icons/Plus";
import UploadIcon from "../../Icons/Upload";

interface SingleImageInputProps extends InputProps {
  setValue?: (value: UploadImageInput) => void;
  initialPreview?: string | null;
  iconSize?: number | string;
  defaultObject?: UploadImageInput;
}

const SingleImageInput: Functional<SingleImageInputProps> = ({
  setValue,
  onChange,
  onDrop,
  initialPreview,
  iconSize = "40px",
  defaultObject,
  ...props
}) => {
  const [styleProps, inputProps] = useStyledProps(props);
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState<File>();
  const [color, setColor] = useState("");
  const [source, setSource] = useState(initialPreview || "");
  const imageRef = useRef<HTMLImageElement>(null);
  const [black, blackAlpha] = useToken("colors", ["black", "blackAlpha.50"]);

  const CenterInput = styled(Center)`
    border: 1px solid transparent;
    transition: border 0.2s ease-in, background 0.2s ease-in;
    input:focus + & {
      border-color: ${styleProps.borderColor || black};
      background: ${blackAlpha};
    }
  `;

  useEffect(() => {
    if (file && setValue) {
      const update: UploadImageInput = {
        file,
      };

      if (color) {
        update.color = color;
      }
      setValue(update);
    }
  }, [file, color, setValue]);

  const processImage = (file: File) => {
    setFile(file);
    setColor("");
    setIsDragging(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      setSource(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      processImage(e.target.files[0]);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const onFileDrop = (e: DragEvent<HTMLInputElement>) => {
    if (e.dataTransfer?.files.length) {
      processImage(e.dataTransfer.files[0]);
    }
    if (onDrop) {
      onDrop(e);
    }
  };

  useEffect(() => {
    if (imageRef.current && source !== initialPreview) {
      const color = new ColorThief();
      const hex = rgbToHex(...color.getColor(imageRef.current, 1));
      setColor(hex || "");
    }
  }, [source, initialPreview]);

  useEffect(() => {
    if (!source && defaultObject) {
      if (defaultObject.file) {
        processImage(defaultObject.file);
      }
      if (defaultObject.color) {
        setColor(defaultObject.color);
      }
    }
  }, [defaultObject, source, setColor]);

  return (
    <Box
      position="relative"
      overflow="hidden"
      border={isDragging ? "2px dashed" : "2px solid"}
      {...styleProps}
    >
      <Box paddingTop="100%">
        <Input
          type="file"
          accept=".jpg,.jpeg,.png, image/jpeg, image/png"
          onChange={onFileChange}
          onDrop={onFileDrop}
          onDragOver={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          opacity={0}
          cursor="pointer"
          position="absolute"
          w="100%"
          h="100%"
          top={0}
          left={0}
          {...inputProps}
        />
        <CenterInput
          position="absolute"
          w="100%"
          h="100%"
          top={0}
          left={0}
          pointerEvents="none"
        >
          {isDragging ? (
            <UploadIcon boxSize={iconSize} />
          ) : source ? (
            <Box
              as="img"
              ref={imageRef}
              objectFit="cover"
              src={source}
              w="100%"
              h="100%"
            />
          ) : (
            <PlusIcon boxSize={iconSize} />
          )}
        </CenterInput>
      </Box>
    </Box>
  );
};

export default SingleImageInput;
