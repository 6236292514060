import { Box, Flex } from "@chakra-ui/react";
import { Router, useLocation } from "@reach/router";
import React from "react";

import { Form } from "../../components/Form";
import { Step, Stepper } from "../../components/Stepper";
import FadeInOut from "../../components/Transition/FadeInOut";

interface CreateOpportunityBaseProps {
  activeStep: number;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleReset: (e: any) => void;
  goTo: (step: number) => void;
}

const CreateOpportunityBase: React.FC<CreateOpportunityBaseProps> = ({
  activeStep,
  handleReset,
  handleSubmit,
  goTo,
  children,
}) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Flex flexDirection="column">
      <Stepper activeStep={activeStep} goTo={goTo}>
        <Step>You and your company</Step>
        <Step>Opportunity details</Step>
        <Step>Review and post</Step>
      </Stepper>

      <Form onReset={handleReset} onSubmit={handleSubmit}>
        <FadeInOut>
          <Box key={pathname}>
            <Router location={location} primary={false}>
              {children}
            </Router>
          </Box>
        </FadeInOut>
      </Form>
    </Flex>
  );
};

export default CreateOpportunityBase;
