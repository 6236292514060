import {
  Box,
  HStack,
  InputProps,
  Popover,
  PopoverContent,
  PopoverTrigger,
  chakra,
  omitThemingProps,
  useDisclosure,
  useMultiStyleConfig,
  useStyleConfig,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  DatePickerButton,
  DatePickerCalendar,
  DatePickerInput,
  DatePickerMonth,
  DatePickerTable,
} from "@reecelucas/react-datepicker";
import {
  DispatchContext,
  StateContext,
} from "@reecelucas/react-datepicker/esm/components/DatePickerContext";
import * as actionTypes from "@reecelucas/react-datepicker/esm/reducer/actionTypes";
import React, { useContext, useEffect, useRef } from "react";

import { Functional } from "../../../types";
import ChevronLeftIcon from "../../Icons/ChevronLeft";
import ChevronRightIcon from "../../Icons/ChevronRight";

const StyledDatePickerButton = chakra(DatePickerButton);
const StyledDatePickerInput = chakra(DatePickerInput);
const StyledDatePickerCalendar = chakra(DatePickerCalendar);
const StyledDatePickerMonth = chakra(DatePickerMonth);
const StyledDatePickerTable = styled(DatePickerTable)`
  width: 100%;

  & th {
    height: 3rem;
  }

  & td {
    width: 14.28%;
    height: 3rem;
    text-align: center;
    transition: background 0.2s ease-in;
    background: #fff;

    &:not(:empty) {
      &[aria-disabled="true"] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:not([aria-disabled="true"]) {
        border: 2px solid white;
        background: black;
        color: white;
        font-weight: 700;
        /* border-radius: 50%; */
        &:hover {
          background: rgba(0, 0, 0, 0.05);
          color: black;
        }
        &[aria-selected="true"] {
          background: #ff40ff;
          color: #000;
        }
      }
    }
  }
`;

/**
 * The Datpicker library was the best accessible component I could find that
 * didn't have a dependency on moment. However it's not that great at giving
 * the dev control over internal state. So we need to do some document listening
 * and hook into it's reducer
 */
const DatePopover: Functional<Omit<InputProps, "as">> = (props) => {
  const container = useRef<HTMLDivElement>(null);
  const { showCalendar } = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const inputStyle = useMultiStyleConfig("Input", props);
  const buttonStyle = useStyleConfig("Button", {
    variant: "outline",
    colorScheme: "obsidian",
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isInvalid, isRequired, ...rest } = props;

  // Recreate behaviour from isInvalid & isRequired
  const mergeProps = {
    required: isRequired,
    "aria-required": isRequired,
    "aria-invalid": isInvalid,
    ...omitThemingProps(rest),
  };

  // DatePicker doesn't allow an initial value if using a placeholder
  // So set it manually on mount.
  useEffect(() => {
    dispatch({ type: actionTypes.SET_SELECTED_DATE, payload: new Date() });
  }, [dispatch]);

  // Because popover and datepicker fight for control
  // We use the calendar to control it's open state.
  useEffect(() => {
    if (showCalendar) {
      onOpen();
    } else {
      onClose();
    }
  }, [showCalendar, onOpen, onClose, dispatch]);

  // When focus leaves the root component close the popover and
  // the datepicker.
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const maybeClose = () => {
      if (container.current) {
        if (!container.current.contains(document.activeElement) && isOpen) {
          onClose();
          dispatch({ type: actionTypes.HIDE_CALENDAR });
        }
      }
    };
    document.addEventListener("focusin", maybeClose);

    return () => document.removeEventListener("focusin", maybeClose);
  }, [isOpen, dispatch, onClose]);

  return (
    <Box ref={container} position="relative" w="100%">
      <Popover
        isOpen={isOpen}
        variant="full"
        placement="bottom-start"
        gutter={-2}
        autoFocus={false}
        returnFocusOnClose={false}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Box>
            <StyledDatePickerInput
              sx={{
                ...inputStyle.field,
                ...(isOpen ? { ...inputStyle.field._focus, _hover: {} } : null),
              }}
              dateFormat="dd/MM/yyyy"
              {...mergeProps}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          sx={{ boxShadow: inputStyle.field._focus?.boxShadow }}
          border="2px solid"
          borderColor="black"
          p={4}
        >
          <StyledDatePickerCalendar w="100%">
            <HStack>
              <StyledDatePickerButton
                sx={{
                  ...buttonStyle,
                  px: 0,
                  boxSize: "50px",
                }}
                updateMonth={({ prev }) => prev()}
                aria-label="Previous month"
              >
                <ChevronLeftIcon boxSize="40px" />
              </StyledDatePickerButton>
              <StyledDatePickerMonth
                flexGrow={1}
                textAlign="center"
                fontWeight={700}
              />
              <StyledDatePickerButton
                sx={{
                  ...buttonStyle,
                  px: 0,
                  boxSize: "50px",
                }}
                updateMonth={({ next }) => next()}
                aria-label="Next month"
              >
                <ChevronRightIcon boxSize="40px" />
              </StyledDatePickerButton>
            </HStack>
            <StyledDatePickerTable />
          </StyledDatePickerCalendar>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
export default DatePopover;
