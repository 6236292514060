import { Flex, ListItem, UnorderedList } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import React from "react";

import { PageData } from "../../graphql";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import ChevronLeftIcon from "../Icons/ChevronLeft";
import ChevronRightIcon from "../Icons/ChevronRight";

import PaginatorLink from "./PaginatorLink";

const MAX_PAGES = 3;

export interface PaginatorProps {
  pageData: PageData & { page: number };
}

const Paginator: React.FC<PaginatorProps> = ({
  pageData: { count, limit, page },
}) => {
  const urlSearch = useUrlSearchParams();
  const pageCount = Math.ceil(count / limit);
  const allPages = [...Array(pageCount)].map((item, index) => index + 1);
  // Keep active page in middle of results if possible
  const index = page > 1 ? page - 2 : page - 1;
  const first = allPages.slice(index, index + MAX_PAGES);

  let result: number[] = [];
  if (first.length !== MAX_PAGES) {
    if (index + MAX_PAGES > allPages.length) {
      const diff = MAX_PAGES - first.length;
      const second = allPages.slice(index - diff, index);
      result = result.concat(second, first);
    }
  } else {
    result = first;
  }

  const pagePath = (page: number) => {
    urlSearch.set("p", page.toString());
    return urlSearch.toString();
  };

  if (pageCount <= 1) {
    return null;
  }

  return (
    <Flex
      as="nav"
      justifyContent="center"
      pt={8}
      role="navigation"
      aria-label="Opportunity board pagination"
    >
      <Flex as={UnorderedList} listStyleType="none">
        {page > 1 && (
          <ListItem>
            <PaginatorLink
              as={ReachLink}
              to={`/opportunities?${pagePath(page - 1)}`}
              aria-label={`Go back to page ${page - 1}`}
            >
              <ChevronLeftIcon />
            </PaginatorLink>
          </ListItem>
        )}
        {result.map((i) => (
          <ListItem key={i}>
            <PaginatorLink
              as={ReachLink}
              color={i === page ? "brand.pink.500" : "black"}
              to={`/opportunities?${pagePath(i)}`}
              aria-label={
                i === page ? `Current page, page ${i}` : `Go to page ${i}`
              }
              aria-current={i === page}
            >
              {i}
            </PaginatorLink>
          </ListItem>
        ))}
        {page < pageCount && (
          <ListItem>
            <PaginatorLink
              as={ReachLink}
              to={`/opportunities?${pagePath(page + 1)}`}
              aria-label={`Go forward to page ${page + 1}`}
            >
              <ChevronRightIcon />
            </PaginatorLink>
          </ListItem>
        )}
      </Flex>
    </Flex>
  );
};

export default Paginator;
